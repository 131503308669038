/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Text, { TextProps } from './components/Text';
import { _CrudObject } from './types/_BaseTypes';
import theme from './utils/theme';

export const VersionedText = ({
  crudObject,
  className,
  ...rest
}: { crudObject: _CrudObject | undefined } & TextProps) => {
  return (
    <Text
      {...rest}
      css={[
        crudObject?.metaAttributes.deleted &&
          css`
            background-color: ${theme.colors.red};
            text-decoration: line-through;
          `,
        crudObject?.metaAttributes.pointInTime &&
          css`
            background-color: ${theme.colors.yellow};
          `,
      ]}
      className={className}
    />
  );
};
