/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { AxiosError } from 'axios';
import React, { FC, useEffect, useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useQuery } from 'react-query';
import Block from '../../components/Block';
import { Column, Row } from '../../components/Flex';
import Loader from '../../components/loader/Loader';
import { Modal } from '../../components/Modal';
import Text from '../../components/Text';
import { Language } from '../../i18n/languageStorage';
import { useT } from '../../i18n/useT';
import { felleskatalogenLookupApi } from '../../lib/api';
import PilFormattingClient from '../../network/PilFormattingClient';
import theme from '../../utils/theme';
import useSessionStorageState from '../../utils/useSessionStorageState';
import { getMedicationInfoLink } from './medicationInfo';
import { getPackageLeafletTranslationData } from './packageLeafletTranslationData';

export const PackageLeafletModal: FC<{
  itemNumber: string;
  patientId?: string;
  medicationName?: string;
  closeModal: () => void;
}> = ({ itemNumber, patientId, medicationName, closeModal }) => {
  const t = useT();
  const { i18n } = useTranslation();
  const [gLensActive, setGLensActive] = useSessionStorageState('gLensActive', false);

  const ref = useRef<HTMLDivElement>(null);
  const { data, isLoading, isSuccess } = useFetchPackageLeaflet(itemNumber);

  const possiblyTranslatedData = getPackageLeafletTranslationData(itemNumber, i18n.language as Language) ?? data;

  const pilFormattingAsync = useQuery(
    ['fetchPilFormatting', patientId, itemNumber],
    () => PilFormattingClient.fetchPilFormatting(patientId ?? '', itemNumber),
    { enabled: Boolean(patientId) && Boolean(itemNumber) }
  );
  const highlighted = pilFormattingAsync.data?.data?.pilFormatting?.highlighted ?? [];
  const suppressed = pilFormattingAsync.data?.data?.pilFormatting?.suppressed ?? [];

  useEffect(() => {
    if (ref.current && isSuccess) {
      Array.from(ref.current?.getElementsByTagName('img')).forEach((img) => {
        if (!img.src.includes('https://www.felleskatalogen.no/')) {
          img.src = `https://www.felleskatalogen.no${img.src.replace(window.location.origin, '')}`;
        }
        const parentAnchor = img.parentElement as HTMLAnchorElement;
        if (parentAnchor?.href && !parentAnchor.href.includes('https://www.felleskatalogen.no/')) {
          parentAnchor.href = `https://www.felleskatalogen.no${parentAnchor.href.replace(window.location.origin, '')}`;
          parentAnchor.target = '_blank';
        }
      });
    }
  }, [isSuccess]);

  return (
    <Modal title={medicationName || itemNumber} closeModal={closeModal} focusTrapActive={false} width={800}>
      <Block>
        <div style={{ height: 4 }} />
        <Row horizontal="space-between">
          <label>
            <input type="checkbox" checked={gLensActive} onChange={() => setGLensActive(!gLensActive)} />
            <Text size="large" marginLeft={8}>
              C-lens
            </Text>
          </label>
          <a
            css={css`
              text-decoration: none;
              color: black;

              &:hover {
                text-decoration: underline;
              }
            `}
            href={getMedicationInfoLink(itemNumber)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Row vertical="center">
              <Text marginRight={6}>{t('Open external link')}</Text>
              <FaExternalLinkAlt size={12} color="black" />
            </Row>
          </a>
        </Row>

        <AnimateHeight height={gLensActive ? 'auto' : 0}>
          <Row
            horizontal="space-between"
            css={css`
              margin-top: 20px;
            `}
          >
            <Column
              css={css`
                flex: 1;
                background-color: ${theme.colors.yellow};
                padding: 8px;
                border-radius: 8px;
                margin-right: 8px;
              `}
            >
              <Text size="large" marginBottom={4}>
                Highlighted
              </Text>
              {highlighted.map(({ comment }) => (
                <Text key={comment}>• {comment}</Text>
              ))}
            </Column>

            <Column
              css={css`
                margin-left: 4px;
                flex: 1;
                background-color: ${theme.colors.gray};
                padding: 8px;
                border-radius: 8px;
              `}
            >
              <Text size="large" marginBottom={4}>
                Suppressed
              </Text>
              {suppressed.map(({ comment }) => (
                <Text key={comment}>• {comment}</Text>
              ))}
            </Column>
          </Row>
        </AnimateHeight>
        {['575458', '489662'].includes(itemNumber) && (
          <>
            <div style={{ height: 20 }} />
            <a
              href="https://www.felleskatalogen.no/media/stream/648003_1/FILM/mp4/mp4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text>Instruksjonsfilm</Text>
            </a>
          </>
        )}
      </Block>

      <div style={{ height: 20 }} />

      {isLoading ? (
        <Loader delayMs={0} />
      ) : possiblyTranslatedData ? (
        <div
          ref={ref}
          dangerouslySetInnerHTML={{ __html: possiblyTranslatedData }}
          css={[
            felleskatalogenCss,
            gLensActive &&
              highlighted.map(
                ({ sectionTag }) => css`
                  .${sectionTag} {
                    background-color: ${theme.colors.yellow};
                    padding: 8px;
                    border-radius: 8px;
                    margin-bottom: 16px;
                  }
                `
              ),
            gLensActive &&
              suppressed.map(
                ({ sectionTag }) => css`
                  .${sectionTag} {
                    background-color: ${theme.colors.gray};
                    padding: 8px;
                    border-radius: 8px;
                    margin-bottom: 16px;
                  }
                `
              ),
          ]}
        />
      ) : (
        <Text color="red">Finner ikke data for {medicationName || itemNumber}</Text>
      )}
    </Modal>
  );
};

const felleskatalogenCss = css`
  button {
    display: none;
  }

  a {
    color: ${theme.colors.blue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 16px;
  }

  b {
    font-weight: bold;
  }

  ol,
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }

    .avsnitt {
      margin-bottom: 0px;
    }
  }

  p {
    margin: 16px 0;
  }

  img {
    max-width: 100%;
  }

  .tittel {
    font-weight: bold;
    margin: 8px 0;
  }

  .kapittel-tittel {
    font-weight: bold;
    font-size: 24px;
    margin: 12px 0;
  }

  .avsnitt {
    margin-bottom: 16px;
  }
`;

const useFetchPackageLeaflet = (itemNumber: string) => {
  return useQuery<string, AxiosError>(['packageLeaflet', itemNumber], () =>
    felleskatalogenLookupApi.get<string>(`varenr/${itemNumber}`).then((res) => res.data)
  );
};
