import settings from '../settings';
import { Fhir_AllergyIntolerance } from '../types/Fhir_AllergyIntolerance';
import { Fhir_Condition } from '../types/Fhir_Condition';
import { Fhir_Coding } from '../types/Fhir_Element';
import { RecursivePartial } from '../types/utils';
import { Cpl_DictionaryContents } from './../types/Cpl_DictionaryContents';
import Fetch from './fetch';

function fetchAvailableAllergyIntolerances() {
  return Fetch.get<Cpl_DictionaryContents>({
    url: `${settings.apiUrl}/AllergyIntolerance/AvailableAllergyIntolerances`,
  });
}

export type Cpl_DictEntry = Pick<Fhir_Coding, 'system' | 'code' | 'display'>;

type CreateAllergyIntoleranceParams = {
  patientId: string;
  type: Cpl_DictEntry;
};
function _buildCreateAllergyIntoleranceBody(
  params: CreateAllergyIntoleranceParams
): RecursivePartial<Fhir_AllergyIntolerance> {
  return {
    patient: {
      reference: `Patient/${params.patientId}`,
    },
    clinicalStatus: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
          code: 'active',
        },
      ],
    },
    code: {
      coding: [params.type],
      text: params.type.display,
    },
  };
}
async function createAllergyIntolerance(params: CreateAllergyIntoleranceParams) {
  const response = await Fetch.post<Fhir_Condition>({
    url: `${settings.apiUrl}/AllergyIntolerance`,
    body: _buildCreateAllergyIntoleranceBody(params),
  });

  return response;
}
async function deleteAllergyIntolerance(conditionId: string) {
  const response = await Fetch.delete({
    url: `${settings.apiUrl}/AllergyIntolerance/${conditionId}`,
  });

  return response;
}

const AllergyIntoleranceClient = {
  fetchAvailableAllergyIntolerances,
  createAllergyIntolerance,
  deleteAllergyIntolerance,
};

export default AllergyIntoleranceClient;
