/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import Block from '../../components/Block';
import { OutlineButton } from '../../components/Button2';
import { Column, Row } from '../../components/Flex';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import { LogHistory } from '../../network/useQueryWithLogHistory';
import formatDate from '../../utils/formatDate';
import { fromServerDate } from '../../utils/serverDateConverter';
import { buttonReset } from '../../utils/styles';
import theme from '../../utils/theme';

export const HistorySelector = ({ logHistory }: { logHistory: LogHistory }) => {
  const t = useT();

  if (!logHistory.entries || logHistory.entries.length < 1) {
    return null;
  }

  if (!logHistory.pointInTime) {
    return (
      <Row horizontal="flex-end">
        <OutlineButton
          onClick={() =>
            logHistory.setPointInTime(logHistory.entries[logHistory.entries.length - 1].metaAttributes.logTime)
          }
        >
          {t('See versions')}
        </OutlineButton>
      </Row>
    );
  }

  const selectedEntry = logHistory.entries.find((entry) => logHistory.pointInTime === entry.metaAttributes.logTime);
  if (!selectedEntry) {
    return null;
  }
  const selectedIndex = logHistory.entries.findIndex((entry) => entry === selectedEntry);

  return (
    <Block>
      <Row horizontal="space-between">
        <Text size="large">{t('See versions')}</Text>
        <OutlineButton onClick={() => logHistory.reset()}>{t('Close')}</OutlineButton>
      </Row>

      <Row horizontal="center">
        <Column key={selectedEntry.metaAttributes.logTime}>
          <Column style={{ padding: 12 }}>
            <Text>
              <Text strong>{t('Date')}:</Text>{' '}
              {formatDate(fromServerDate(selectedEntry.metaAttributes.logTime), 'dd-MM-yyyy, HH:mm:ss')}
            </Text>
            <Text>
              <Text strong>{t('Name')}:</Text> {selectedEntry.logSignDisplayName}
            </Text>
            <Text>
              <Text strong>{t('Role')}:</Text> {selectedEntry.logSignDisplayUserRole}
            </Text>
          </Column>
        </Column>
      </Row>

      <Row horizontal="space-between" vertical="center">
        <Row>
          <OutlineButton
            onClick={() => logHistory.setPointInTime(logHistory.entries[0].metaAttributes.logTime)}
            disabled={selectedIndex === 0}
          >
            |&lt;
          </OutlineButton>
          <OutlineButton
            onClick={() => logHistory.setPointInTime(logHistory.entries[selectedIndex - 1].metaAttributes.logTime)}
            disabled={!logHistory.entries[selectedIndex - 1]}
            marginLeft={8}
          >
            &lt;
          </OutlineButton>
        </Row>

        <Row>
          <OutlineButton
            onClick={() => logHistory.setPointInTime(logHistory.entries[selectedIndex + 1].metaAttributes.logTime)}
            disabled={!logHistory.entries[selectedIndex + 1]}
          >
            &gt;
          </OutlineButton>
          <OutlineButton
            onClick={() =>
              logHistory.setPointInTime(logHistory.entries[logHistory.entries.length - 1].metaAttributes.logTime)
            }
            disabled={selectedIndex === logHistory.entries.length - 1}
            marginLeft={8}
          >
            &gt;|
          </OutlineButton>
        </Row>
      </Row>

      <Row
        horizontal="center"
        wrap
        css={css`
          margin-top: 20px;
        `}
      >
        {logHistory.entries.map((entry) => {
          return (
            <button
              onClick={() => logHistory.setPointInTime(entry.metaAttributes.logTime)}
              css={buttonReset}
              key={entry.metaAttributes.logTime}
            >
              <div
                css={css`
                  width: 12px;
                  height: 12px;
                  border-radius: 12px;
                  margin: 6px;
                  border: 1px solid ${theme.colors.blue};
                  background-color: ${selectedEntry === entry ? theme.colors.blue : 'white'};
                `}
              />
            </button>
          );
        })}
      </Row>
    </Block>
  );
};
