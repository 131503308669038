/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from '@reach/router';
import React from 'react';
import Block from '../../components/Block';
import Button from '../../components/Button2';
import { Column, Row } from '../../components/Flex';
import Grid from '../../components/Grid';
import { MessageIcon } from '../../components/icons/MessageIcon';
import { Modal, ModalTrigger } from '../../components/Modal';
import Text from '../../components/Text';
import Textarea from '../../components/Textarea';
import TextInput from '../../components/TextInput';
import { useT } from '../../i18n/useT';
import { useAddNotification } from '../../lib/notifications/NotificationContext';
import { useSelectedPatientContext } from '../../lib/SelectedPatientContext';
import { useUserContext } from '../../lib/UserContext';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import MessagesClient from '../../network/MessagesClient';
import UserClient from '../../network/UserClient';
import { MailMessage } from '../../types/Messages';
import { AttachmentInput, MailMessageAddressUser } from '../../types/misc';
import { buttonReset } from '../../utils/styles';
import theme from '../../utils/theme';

const SelectInput: React.FC<React.ComponentPropsWithoutRef<'select'> & { className?: string }> = ({
  className,
  ...rest
}) => {
  return (
    <select
      css={[
        css`
          border: 1px solid ${theme.colors.gray};
          padding: 8px;
          border-radius: 4px;
          font-size: 16px;
        `,
      ]}
      className={className}
      {...rest}
    />
  );
};

export const Label: React.FC<{ label: string; className?: string }> = ({ label, className, children }) => {
  return (
    <label className={className}>
      <Column>
        <Text marginBottom={4}>{label}</Text>
        {children}
      </Column>
    </label>
  );
};

const useAddressUsers = (attachment?: AttachmentInput) => {
  const { addError } = useAddNotification();
  const [addressUsers, setAddressUsers] = React.useState<MailMessageAddressUser[]>([]);

  React.useEffect(() => {
    (async () => {
      let result;
      if (attachment) {
        result = await UserClient.fetchUsersForAttachment(attachment);
      } else {
        result = await UserClient.fetchTestUsers();
      }

      if (result.success) {
        const sortedUsers = (result.data ?? []).sort((a, b) => a.displayName.localeCompare(b.displayName));
        setAddressUsers(sortedUsers);
      } else {
        addError({ message: (result.error as any)?.resultMessage ?? 'Ukjent feil' });
      }
    })().catch(() => {
      addError({ message: 'Ukjent feil' });
    });
  }, [attachment, addError]);

  return addressUsers;
};

export const CreateNewThread = ({ attachment }: { attachment?: AttachmentInput }) => {
  const user = useUserContext();
  const { addError, addWarn } = useAddNotification();
  const t = useT();
  const addressUsers = useAddressUsers(attachment);
  const selectedPatientId = useSelectedPatientId();
  const selectedPatientContext = useSelectedPatientContext();
  const senderMailMessageAddress_Id =
    selectedPatientContext.selectedPatient?.patient?.mailMessageAddress.mailMessageAddress_Id ?? undefined;
  const [title, setTitle] = React.useState('');
  const [contents, setContents] = React.useState('');
  const [receiverIds, setReceiverIds] = React.useState<string[]>([]);

  const [submitting, setSubmitting] = React.useState(false);
  const [successfullyCreatedThreadId, setSuccessfullyCreatedThreadId] = React.useState<string | undefined>(undefined);

  const submit = React.useCallback(async () => {
    if (!title || !contents || (!attachment && receiverIds.length === 0)) {
      addWarn({ message: t('Fill in all the fields') });
      return;
    }

    setSubmitting(true);
    try {
      const result = await MessagesClient.createMessageWithAttachment({
        title,
        contents,
        senderMailMessageAddress_Id,
        mailMessageAttachments: attachment ? [attachment] : [],
        mailMessageReceivers: receiverIds.map((receiverId) => {
          return {
            mailMessageAddress_Id: parseInt(receiverId, 10),
          };
        }),
      });

      if (result.success) {
        const threadId = result.data?.mailMessages?.[0]?.mailMessageThreadId;
        if (threadId) {
          setSuccessfullyCreatedThreadId(threadId);
        }
      } else {
        addError({ message: (result.error as any)?.resultMessage ?? t('Something went wrong') });
      }
    } catch (error) {
      addError({ message: t('Unknown error') });
      throw error;
    } finally {
      setSubmitting(false);
    }
  }, [title, contents, receiverIds, attachment, senderMailMessageAddress_Id, addError, addWarn, t]);

  return (
    <>
      {successfullyCreatedThreadId ? (
        <Column
          css={css`
            margin-bottom: 16px;
          `}
        >
          <Text size="large" marginBottom={16}>
            {t('New conversation created')}
          </Text>
          <Link
            to={`/${
              // TODO: this will only work when refLogicalId is patientId
              attachment?.resourceReference.refLogicalId ?? selectedPatientId ?? user.loggedInUser!.user.patientId
            }/meldinger/${successfullyCreatedThreadId}`}
          >
            <Text size="large" color={theme.colors.blue}>
              {t('Go to conversation')}
            </Text>
          </Link>
        </Column>
      ) : (
        <Column>
          <Label
            label={t('Title')}
            css={css`
              margin-bottom: 16px;
            `}
          >
            <TextInput
              value={title}
              onValue={(value) => setTitle(value)}
              placeholder={t('Write a title...')}
              autoFocus
              disabled={submitting}
            />
          </Label>

          <Label
            label={t('Message')}
            css={css`
              margin-bottom: 16px;
            `}
          >
            <Textarea
              value={contents}
              onValue={(value) => setContents(value)}
              placeholder={t('Write a message...')}
              disabled={submitting}
              rows={5}
            />
          </Label>

          <Label label={t('Recipients')} />

          <Column
            css={css`
              margin-bottom: ${receiverIds.length === 0 ? 0 : 4}px;
              max-height: 114px;
              overflow-y: auto;
            `}
          >
            {receiverIds.map((receiverId) => {
              const receiver = addressUsers.find(
                (user) => String(user.mailMessageAddress.mailMessageAddress_Id) === receiverId
              );
              return (
                <Row
                  key={receiverId}
                  horizontal="space-between"
                  vertical="center"
                  css={css`
                    margin-bottom: 4px;
                  `}
                >
                  <Row vertical="center">
                    {receiver?.photoUri && (
                      <img
                        src={receiver.photoUri}
                        css={css`
                          width: 20px;
                          height: 20px;
                          border-radius: 20px;
                          margin-right: 8px;
                        `}
                        alt={receiver?.displayName}
                      />
                    )}
                    <Text color="#111">{receiver?.displayName}</Text>
                  </Row>

                  <button
                    onClick={() => {
                      setReceiverIds(receiverIds.filter((id) => id !== receiverId));
                    }}
                    disabled={submitting}
                    css={[
                      buttonReset,
                      css`
                        color: ${theme.colors.errorRed};
                      `,
                    ]}
                  >
                    {t('Remove')}
                  </button>
                </Row>
              );
            })}
          </Column>

          <SelectInput
            value={undefined}
            onChange={(event) => {
              if (event.target.value) {
                setReceiverIds([...receiverIds, event.target.value]);
              }
            }}
            disabled={submitting}
          >
            {addressUsers.length === 0 ? (
              <option value={''}>Laster brukere...</option>
            ) : (
              <option value={''}>{t('Choose recipients...')}</option>
            )}
            {addressUsers
              .filter((user) => {
                return !receiverIds.includes(String(user.mailMessageAddress.mailMessageAddress_Id));
              })
              .map((user) => {
                return (
                  <option key={user.id} value={String(user.mailMessageAddress.mailMessageAddress_Id)}>
                    {user.displayName}
                  </option>
                );
              })}
          </SelectInput>

          <Row
            horizontal="flex-end"
            css={css`
              margin-top: 20px;
            `}
          >
            <Button disabled={submitting} onClick={submit} loading={submitting}>
              {t('Send message')}
            </Button>
          </Row>
        </Column>
      )}
    </>
  );
};

const MailMessageList = ({ patientId, mailMessages }: { patientId: string; mailMessages: MailMessage[] }) => {
  const t = useT();
  return (
    <Grid>
      {mailMessages.map((mailMessage) => {
        return (
          <Block key={mailMessage.id}>
            <Column>
              <Text dimmed>{mailMessage.senderMailMessageAddress.description}</Text>

              <Text>{mailMessage.title}</Text>
              <Text>{mailMessage.contents}</Text>

              <Row
                horizontal="flex-end"
                css={css`
                  margin-top: 8px;
                `}
              >
                <Link to={`/${patientId}/meldinger/${mailMessage.mailMessageThreadId}`}>
                  <Text color={theme.colors.blue}>{t('Go to conversation')}</Text>
                </Link>
              </Row>
            </Column>
          </Block>
        );
      })}
    </Grid>
  );
};

const ExternalMessagesModal = ({
  modalLabel,
  closeModal,
  patientId,
  attachment,
  mailMessages,
}: {
  modalLabel: string;
  closeModal: () => void;
  patientId: string;
  attachment: AttachmentInput;
  mailMessages: MailMessage[];
  className?: string;
}) => {
  const t = useT();
  const [createNewMessage, setCreateNewMessage] = React.useState(mailMessages.length === 0);

  return (
    <Modal title={modalLabel} closeModal={closeModal}>
      {mailMessages.length > 0 && (
        <Row
          css={css`
            margin-bottom: 20px;
          `}
        >
          <button
            onClick={() => setCreateNewMessage(!createNewMessage)}
            css={[
              buttonReset,
              css`
                font-size: 16px;
                color: ${theme.colors.lightBlue};
                text-decoration: underline;
              `,
            ]}
          >
            {createNewMessage ? t('See previous messages') : t('Create new message')}
          </button>
        </Row>
      )}

      {createNewMessage ? (
        <CreateNewThread attachment={attachment} />
      ) : (
        <MailMessageList patientId={patientId} mailMessages={mailMessages} />
      )}
    </Modal>
  );
};

export const ExternalMessageButton = ({
  modalLabel,
  patientId,
  attachment,
  mailMessages,
  className,
}: {
  modalLabel: string;
  patientId: string;
  attachment: AttachmentInput;
  mailMessages: MailMessage[];
  className?: string;
}) => {
  return (
    <ModalTrigger
      button={({ openModal }) => (
        <button
          onClick={openModal}
          css={[
            buttonReset,
            css`
              position: relative;
            `,
          ]}
          className={className}
        >
          <MessageIcon size={40} />

          {mailMessages.length > 0 && (
            <Row
              width={24}
              height={24}
              horizontal="center"
              vertical="center"
              css={css`
                position: absolute;
                top: -8px;
                right: -8px;
                border-radius: 16px;
                background-color: ${theme.colors.green};
              `}
            >
              <Text color="white" strong>
                {mailMessages.length}
              </Text>
            </Row>
          )}
        </button>
      )}
      modal={({ closeModal }) => (
        <ExternalMessagesModal
          modalLabel={modalLabel}
          patientId={patientId}
          attachment={attachment}
          mailMessages={mailMessages}
          closeModal={closeModal}
        />
      )}
    />
  );
};
