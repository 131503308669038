/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RouteComponentProps } from '@reach/router';
import { lighten } from 'polished';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Button from '../../components/Button2';
import { Column, Row } from '../../components/Flex';
import Loader from '../../components/loader/Loader';
import { Modal, ModalTrigger } from '../../components/Modal';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import { Selector } from '../../components/Selector';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import { useAddNotification } from '../../lib/notifications/NotificationContext';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import AllergyIntoleranceClient from '../../network/AllergyIntoleranceClient';
import { useQueryWithLogHistory } from '../../network/useQueryWithLogHistory';
import { buttonReset } from '../../utils/styles';
import theme from '../../utils/theme';
import { VersionedText } from '../../VersionedText';
import { HistorySelector } from '../medication/HistorySelector';

const AllergiesAndIntolerances = () => {
  const t = useT();
  const selectedPatientId = useSelectedPatientId()!;

  const [query, logHistory, refetch] = useQueryWithLogHistory(
    ['fetchAllergyIntolerances', selectedPatientId],
    `/AllergyIntolerance/Patient/${selectedPatientId}`
  );

  const allergyIntolerances = query.data?.allergyIntolerances ?? [];

  return (
    <div>
      <h1>{t('Allergies and intolerances')}</h1>
      <div style={{ height: 20 }} />

      {query.isLoading && <Loader />}

      {!query.isLoading && allergyIntolerances.length === 0 && (
        <Text size="large">{t("You haven't added any allergies or intolerances yet.")}</Text>
      )}

      {allergyIntolerances.map((allergyIntolerance) => (
        <section
          key={allergyIntolerance.id}
          css={css`
            background-color: #f5f7fb;
            box-shadow: ${theme.shadows.block};
            margin-bottom: 8px;
            border-radius: 8px;
            &:hover {
              background-color: ${theme.colors.grayBackground};
            }
          `}
        >
          <Row
            horizontal="space-between"
            css={css`
              @media (max-width: 599px) {
                flex-direction: column;
              }
            `}
          >
            <Column
              css={css`
                padding: 16px;
              `}
            >
              <VersionedText size="large" crudObject={allergyIntolerance.code}>
                {allergyIntolerance.code.translatedText}
              </VersionedText>
            </Column>

            {!logHistory.pointInTime && (
              <button
                onClick={() => {
                  const result = window.confirm('Slett?');
                  if (result) {
                    AllergyIntoleranceClient.deleteAllergyIntolerance(allergyIntolerance.id).then(() => refetch());
                  }
                }}
                css={[
                  buttonReset,
                  css`
                    background-color: ${lighten(0.05, theme.colors.blue)};
                    color: #ffffff;
                    padding: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    text-decoration: none;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;

                    @media (max-width: 599px) {
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 8px;
                      border-bottom-left-radius: 8px;
                    }

                    &:hover {
                      background-color: ${lighten(0.4, theme.colors.blue)};
                      color: #000000;
                    }
                  `,
                ]}
              >
                <Text invert>{t('Remove')}</Text>
              </button>
            )}
          </Row>
        </section>
      ))}

      {!logHistory.pointInTime && (
        <>
          <div style={{ height: 20 }} />
          <ModalTrigger
            button={({ openModal }) => <Button onClick={openModal}>{t('Add new allergy or intolerance')}</Button>}
            modal={({ closeModal }) => (
              <CreateAllergyIntoleranceModal closeModal={closeModal} onSuccess={() => refetch()} />
            )}
          />
        </>
      )}

      <div style={{ height: 20 }} />

      <HistorySelector logHistory={logHistory} />
    </div>
  );
};

const CreateAllergyIntoleranceModal = ({
  closeModal,
  onSuccess,
}: {
  closeModal: () => void;
  onSuccess: () => void;
}) => {
  const { i18n } = useTranslation();
  const t = useT();
  const { addError, addInfo } = useAddNotification();
  const selectedPatientId = useSelectedPatientId()!;
  const [value, setValue] = useState<string>('');

  const availableAllergyIntolerancesQuery = useQuery(['availableAllergyIntolerances', i18n.language], () =>
    AllergyIntoleranceClient.fetchAvailableAllergyIntolerances()
  );
  const availableAllergyIntolerance = availableAllergyIntolerancesQuery.data?.data?.coding ?? [];

  const selectedItem = availableAllergyIntolerance.find((item) => item.code === value);

  const onSubmit = async () => {
    if (!selectedItem) {
      return;
    }

    try {
      const response = await AllergyIntoleranceClient.createAllergyIntolerance({
        patientId: selectedPatientId,
        type: selectedItem!,
      });

      if (response.success) {
        onSuccess();
        closeModal();
        addInfo({ message: 'Added' });
      } else {
        addError({ message: (response.error as any)?.resultMessage ?? 'Unknown error' });
      }
    } catch (error) {
      addError({ message: 'Unknown error' });
    }
  };

  return (
    <Modal title={t('New allergy or intolerance')} closeModal={closeModal}>
      <Selector
        title={t('Allergy or intolerance')}
        value={value}
        onValue={(val) => setValue(val)}
        data={[
          { value: '', label: t('Choose from list...'), disabled: true },
          ...availableAllergyIntolerance.map((item) => ({ value: item.code, label: item.display })),
        ]}
      />
      <div style={{ height: 20 }} />
      <Button onClick={onSubmit}>{t('Add')}</Button>
    </Modal>
  );
};

export const AllergyIntolerancesPage: FC<RouteComponentProps> = () => {
  const t = useT();

  return (
    <AuthedPageLayout width="narrow" documentTitle={t('Capable - Allergies and intolerances')}>
      <AllergiesAndIntolerances />
    </AuthedPageLayout>
  );
};
