import { Language } from '../../i18n/languageStorage';

const skilarence_en = `
  <?xml version="1.0" encoding="UTF-8"?>

<!DOCTYPE html

  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "xhtml1-transitional.dtd">

<html><head><title>Skilarence Almirall 30 mg gastro‑resistant - Felleskatalogen Pasientutgave</title><meta name="description" content="&#xA;         What Skilarence is and what it is used for &#xA;         What Skilarence is &#xA;         Skilarence is a medicine that contains the active substance"></meta><meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta><meta name="author" content="Felleskatalogen"></meta><meta name="keywords" content="Skilarence 30 mg gastro-resistant tablets "></meta><meta http-equiv="X-UA-Compatible" content="IE=edge"></meta><meta name="date" content="This leaflet was last revised in 02/2022. "></meta><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_content.css" type="text/css"></link><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_fk.css" type="text/css"></link><meta name="varenr" content="500781"></meta><meta name="ean" content="7046265007814"></meta><meta name="lmrlopenr" content="3336054"></meta>

        

        

        

        <meta name="preparatnavn" content="Skilarence"></meta>

        <meta name="kortnummer" content="1111"></meta>

    <meta name="navnformstyrke" content="Skilarence 30 mg gastro-resistant tablets "></meta></head><body>
    
<!-- HENRIK HAR KOMMENTERT UT DETTE    

    <div class="anchorindexbar">
    <a class="anchorindex" href="#pil-indikasjon" title="Bruksområder">Bruksområder</a> | 

    <a class="anchorindex" href="#pil-forsiktighet" title="Forsiktighetsregler">Forsiktighetsregler</a> | 

    <a class="anchorindex" href="#pil-bruk" title="Bruksmåte">Bruksmåte</a> | 

    <a class="anchorindex" href="#pil-bivirkning" title="Bivirkninger">Bivirkninger</a> | 

    <a class="anchorindex" href="#pil-oppbevaring" title="Oppbevaring">Oppbevaring</a> | 

    <a class="anchorindex" href="#pil-innhold-info" title="Ytterligere informasjon">Ytterligere informasjon</a>

<br></br><br></br></div>

-->

<div class="pil">

    

    

    <h1 class="tittel">Package leaflet: Information for the patient</h1>

    

    <h2 class="pil-navnformstyrke">Skilarence 30 mg gastro-resistant tablets </h2>

    <h3 class="pil-substans">dimethyl fumarate</h3>

    <div class="pil-innledning">

        <div class="pil-regler seksjon">

            <span class="tittel">Read all of this leaflet carefully before you start taking this medicine because it contains important

information for you.</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">Keep this leaflet. You may need to read it again.</div>

                    </li><li>

                        <div class="avsnitt">If you have any further questions, ask your doctor or pharmacist.</div>

                    </li><li>

                        <div class="avsnitt">This medicine has been prescribed for you only. Do not pass it on to others. It may harm them,

even if their signs of illness are the same as yours.</div>

                    </li><li>

                        <div class="avsnitt">If you get any side effects, talk to your doctor or pharmacist. This includes any possible side effects

not listed in this leaflet. See section 4. </div>

                    </li></ul>

            </div>

        </div>

        <div class="toc generated seksjon"><span class="tittel">I dette pakningsvedlegget finner du informasjon om:</span><ol><li>What Skilarence is and what it is used for</li><li>What you need to know before you take Skilarence</li><li>How to take Skilarence</li><li>Possible side effects</li><li>How to store Skilarence </li><li>Contents of the pack and other information </li></ol></div>

    </div>

    <a class="anchorLink" id="pil-indikasjon"> </a><div class="pil-indikasjon kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">What Skilarence is and what it is used for</tittel></span>

        <span class="flytende-tittel">What Skilarence is</span>

        <div class="avsnitt">Skilarence is a medicine that contains the active substance dimethyl fumarate. Dimethyl fumarate works

on cells of the immune system (the body's natural defences). It changes the activity of the immune system

by reducing the production of certain substances involved in causing psoriasis. </div>

        <span class="flytende-tittel">What Skilarence is used for</span>

        <div class="avsnitt">Skilarence tablets are used to treat moderate to severe plaque psoriasis in adults. Psoriasis is a disease

causing thickened, inflamed, red areas on the skin, often covered by silvery scales. </div>

        <div class="avsnitt">Response to Skilarence can be generally seen as early as week 3 and improves over time. Experience with

related products containing dimethyl fumarate shows treatment benefit for at least up to 24 months. </div>

    </div>

    <a class="anchorLink" id="pil-forsiktighet"> </a><div class="pil-forsiktighet kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">What you need to know before you take Skilarence</tittel></span>

        <div class="pil-kontraindikasjon seksjon">

            <span class="tittel">Do not take Skilarence</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">if you are allergic to dimethyl fumarate or any of the other ingredients of this medicine (listed in

section 6) </div>

                    </li><li>

                        <div class="avsnitt">if you have severe problems with your stomach or intestines </div>

                    </li><li>

                        <div class="avsnitt">if you have severe liver or kidney problems</div>

                    </li><li>

                        <div class="avsnitt">if you are pregnant or breast-feeding</div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-advarsel seksjon">

            <span class="tittel">Warnings and precautions</span>

            <div class="avsnitt">Talk to your doctor or pharmacist before taking Skilarence.</div>

            <div class="avsnitt">

                <u>Monitoring</u>

                <br></br>

                Skilarence may cause problems with your blood, liver or kidneys. You will have blood and urine tests

before treatment and then regularly during your treatment. This is to make sure that you are not getting

these complications and can continue taking this medicine. Depending on the results of these blood and

urine tests, your doctor may decide to increase the dose of Skilarence as per the advised schedule (see

section 3), maintain your dose, reduce your dose or stop treatment altogether. .</div>

            <div class="avsnitt">

                <u>Infections</u>

                <br></br>

                White blood cells help your body to fight infections. Skilarence may reduce the number of your white

blood cells. Talk to your doctor if you think you may have an infection. Symptoms include fever, pain,

aching muscles, headache, loss of appetite and a general feeling of weakness. If you have a serious

infection, either before starting treatment with Skilarence or during treatment, your doctor may advise you

not to take Skilarence until the infection has resolved.</div>

            <div class="avsnitt">

                <u>Gastrointestinal disorders</u>

                <br></br>

                Tell your doctor if you have or have had problems with your stomach or intestines. Your doctor will

advise you on what care you need to take during Skilarence treatment.</div>

        </div>

        <div class="pil-barn-unge seksjon">

            <span class="tittel">Children and adolescents</span>

            <div class="avsnitt">Children and adolescents below the age of 18 years should not take this medicine because it has not been

studied in this age group. </div>

        </div>

        <div class="pil-interaksjon seksjon">

            <span class="tittel">Other medicines and Skilarence </span>

            <div class="avsnitt">Tell your doctor or pharmacist if you are taking, have recently taken or might take any other medicines.</div>

            <div class="avsnitt">In particular, tell your doctor if you are taking the following: </div>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">

                            <b>Dimethyl fumarate or other fumarates.

                            </b>

                            The active substance in Skilarence, dimethyl fumarate, is

also used in other medicines such as tablets, ointments and baths. You must avoid using other

products that contain fumarates to prevent taking too much. </div>

                    </li><li>

                        <div class="avsnitt">

                            <b>- Other medicines used to treat psoriasis, </b>

                            such as methotrexate, retinoids, psoralens, ciclosporin,

or other medicines that affect the immune system (such as immunosuppressants or cytostatics).

Taking these medicines with Skilarence could increase the risk of side effects on your immune

system. </div>

                    </li><li>

                        <div class="avsnitt">

                            <b>Other medicines that can affect your kidney function,</b>

                            such as methotrexate or ciclosporin (used

to treat psoriasis), aminoglycosides (used to treat infections), diuretics (which increase urine),

nonsteroidal anti-inflammatory drugs (used to treat pain) or lithium (used for bipolar disease and

depression). These medicines taken together with Skilarence could increase the risk of side effects

on your kidneys.</div>

                    </li></ul>

            </div>

            <div class="avsnitt">If you get severe or prolonged diarrhoea due to using Skilarence, other medicines may not work as well as

they should. In that case, discuss your diarrhoea with your doctor. If you are taking a contraceptive

medicine (the pill), this is especially important as the effect may be reduced and you may need to use

additional methods to prevent pregnancy. See the instructions in the patient leaflet of the contraceptive

you are taking. </div>

            <div class="avsnitt">If you need a vaccination, talk to your doctor. Certain types of vaccines (live vaccines) may cause 

infection if used during treatment with Skilarence. Your doctor can advise you what would be best.</div>

        </div>

        <div class="pil-mat-drikke seksjon">

            <span class="tittel">Skilarence with alcohol</span>

            <div class="avsnitt">Avoid strong alcoholic drinks (more than 50 ml of spirits containing more than 30% alcohol by volume)

during treatment with Skilarence, as alcohol can interact with this medicine. This could cause stomach

and intestinal problems. </div>

        </div>

        <div class="pil-graviditet-amming-fertilitet seksjon">

            <span class="tittel">Pregnancy and breast-feeding</span>

            <div class="avsnitt">Do not take Skilarence if you are pregnant or trying to become pregnant, as Skilarence may harm your

baby. Use effective methods of contraception to avoid becoming pregnant during treatment with

Skilarence (see also “Other medicines and Skilarence” above). In case of stomach and intestinal problems

that could reduce the effectiveness of oral contraceptives, you should consult your doctor who will advise

you on additional contraceptive methods.<br></br>

                Do not breast-feed during treatment with Skilarence.</div>

        </div>

        <div class="pil-bil-maskiner seksjon">

            <span class="tittel">Driving and using machines</span>

            <div class="avsnitt">Skilarence may have a minor influence on the ability to drive and use machines. You may feel dizzy or

tired after taking Skilarence. If you are affected, be careful when driving or using machines.</div>

        </div>

        <div class="pil-innholdsstoffer seksjon">

            

                <span class="tittel">Skilarence contains lactose</span>

                <div class="avsnitt">If you have been told by your doctor that you have an intolerance to some sugars, contact your doctor

before taking this medicinal product.</div>

            

            

                <span class="tittel">Skilarence contains sodium</span>

                <div class="avsnitt">This medicine contains less than 1 mmol sodium (23 mg) per tablet, that is to say essentially ‘sodiumfree’.</div>

            

        </div>

    </div>

    <a class="anchorLink" id="pil-bruk"> </a><div class="pil-bruk kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">How to take Skilarence</tittel></span>

        <div class="avsnitt">Always take this medicine exactly as your doctor or pharmacist has told you. Check with your doctor or

pharmacist if you are not sure. </div>

        <span class="flytende-tittel">Dose</span>

        <div class="avsnitt">Your doctor will start your treatment with a low dose of Skilarence by using 30 mg tablets. This helps

your body to get used to the medicine and to reduce the risk of side effects, such as stomach and intestinal

problems. The dose will be increased with 30 mg every week as shown in the table below. When reaching

a dose of 120 mg Skilarence per day, usually from week 4 onwards, you can switch to 120 mg tablets for

convenience. When switching tablets from 30 mg to 120 mg, please make sure you are using the right

tablet and dose.</div>



 <div class="avsnitt">

            <div class="table-wrapper"><table cellspacing="0" class="">

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <thead>

                        <tr>

                            <th class="" rowspan="2"><p>Behandlingsuke</p></th>

                            <th class="" rowspan="2"><p>Tablettstyrke</p></th>

                            <th class="" colspan="3"><p>Hvor mange tabletter som skal tas i løpet av dagen</p></th>

                            <th class="" rowspan="2"><p>Antall tabletter per dag</p></th>

                            <th class="" rowspan="2"><p>Total daglig dose</p></th>

                        </tr>

                        <tr>

                            <th class=""><p>Frokost</p></th>

                            <th class=""><p>Lunsj</p></th>

                            <th class=""><p>Kveldsmat</p></th>

                        </tr>

                    </thead>

                    <tbody>

                        <tr>

                            <td class=""><p>1</p></td>

                            <td class=""><p>30 mg</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>30 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>2</p></td>

                            <td class=""><p>30 mg</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>60 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>3</p></td>

                            <td class=""><p>30 mg</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>3</p></td>

                            <td class=""><p>90 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>4</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>120 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>5</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>240 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>6</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>3</p></td>

                            <td class=""><p>360 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>7</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>4</p></td>

                            <td class=""><p>480 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>8</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>5</p></td>

                            <td class=""><p>600 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>9+</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>6</p></td>

                            <td class=""><p>720 mg</p></td>

                        </tr>

                    </tbody>

                </table></div>

        </div>



        <div class="avsnitt">

            <a rel="lightbox" href="INTERNAL:/content/pakningsvedlegg/S/Skilarence...Almirall_639499/13021991.jpeg"><img src="INTERNAL:/content/pakningsvedlegg/S/Skilarence...Almirall_639499/13021991.jpeg" alt="Lacking text alternative for image"></img></a>

        </div>

        <div class="avsnitt">Your doctor will check how well your condition is improving after you start taking Skilarence and will

check for side effects. If you have severe side effects after an increase in dose, your doctor may

recommend that you temporarily go back to the last dose. If the side effects are not that troublesome, your

dose will be increased until your condition is well controlled. You may not need the maximum dose of

720 mg per day as supplied in the table above. After your condition has improved sufficiently, your

doctor will consider how to gradually reduce the daily dose of Skilarence to what you need to maintain

your improvement. </div>

        <span class="flytende-tittel">Method of administration</span>

        <div class="avsnitt">Swallow Skilarence tablets whole with liquid. Take your tablet(s) during or immediately after a meal. Do

not crush, break, dissolve or chew the tablet, as they have a special coating to help prevent irritation of

your stomach.</div>

        <div class="pil-overdosering seksjon">

            <span class="tittel">If you take more Skilarence than you should</span>

            <div class="avsnitt">If you think you have taken too many Skilarence tablets, contact your doctor or pharmacist. </div>

        </div>

        <div class="pil-glemt-dose seksjon" id="pil-glemt-dose">

            <span class="tittel">If you forget to take Skilarence</span>

            <div class="avsnitt">Do not take a double dose to make up for a forgotten dose. Take the next dose at the usual time and

continue taking the medicine exactly as described in this leaflet or exactly as agreed with your doctor.

Please ask your doctor or pharmacist if you are not sure.</div>

            <div class="avsnitt">If you have any further questions on the use of this medicine, ask your doctor or pharmacist.</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-bivirkning"> </a><div class="pil-bivirkning kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Possible side effects</tittel></span>

        <div class="avsnitt">Like all medicines, this medicine can cause side effects, although not everybody gets them. Some of these

side effects, such as reddening of the face or body (flushing), diarrhoea, stomach problems and nausea

usually improve as you continue treatment. </div>

        <div class="avsnitt">The most serious side effects that may occur with Skilarence are allergic or hypersensitivity reactions;

kidney failure or a kidney disease called Fanconi syndrome; or a severe brain infection called progressive

multifocal leukoencephalopathy (PML). It is not known how commonly they occur. For symptoms see

below.</div>

        <div class="avsnitt">

            <u>Allergic or hypersensitivity reactions </u>

            <br></br>

            Allergic or hypersensitivity reactions are rare but may be very serious. Reddening of the face or body

(flushing) is a very common side effect which may affect more than 1 in 10 people. However, if you become flushed and get any of the following signs:<ul><li>

                    <div class="avsnitt">wheezing, difficulty breathing or shortness of breath, </div>

                </li><li>

                    <div class="avsnitt">swelling of the face, lips, mouth or tongue</div>

                </li></ul>

            stop taking Skilarence and call a doctor straight away. </div>

        <div class="avsnitt">

            <u>Brain infection called PML </u>

            <br></br>

            Progressive multifocal leukoencephalopathy (PML) is a rare but serious brain infection that can lead to

severe disability or death. If you notice new or worsening weakness on one side of the body; clumsiness;

changes in vision, thinking or memory; confusion; or personality changes lasting several days, stop taking

Skilarence and talk to your doctor straight away.</div>

        <div class="avsnitt">

            <u>Fanconi syndrome</u>

            <br></br>

            Fanconi syndrome is a rare but serious kidney disorder which may occur with Skilarence. If you notice

you are passing more urine (or more frequently); are more thirsty and drinking more than normal; your

muscles seem weaker you break a bone; or just have aches and pains, talk to your doctor as soon as

possible so that this can be investigated further. </div>

        <div class="avsnitt">Talk to your doctor if you get any of the following side effects.</div>

        <div class="avsnitt">Very common (may affect more than 1 in 10 people) :

            <ul><li>

                    <div class="avsnitt">decrease in white blood cells called lymphocytes (lymphopenia) </div>

                </li><li>

                    <div class="avsnitt">decrease in all white blood cells (leukopenia) </div>

                </li><li>

                    <div class="avsnitt">reddening of the face or body (flushing) </div>

                </li><li>

                    <div class="avsnitt">diarrhoea</div>

                </li><li>

                    <div class="avsnitt">bloating, stomach pain or stomach cramps</div>

                </li><li>

                    <div class="avsnitt">feeling sick (nausea)</div>

                </li></ul>

        </div>

        <div class="avsnitt">Common (may affect up to 1 in 10 people):<ul><li>

                    <div class="avsnitt">increase in all white blood cells (leukocytosis) </div>

                </li><li>

                    <div class="avsnitt">increase in specific white blood cells called eosinophils</div>

                </li><li>

                    <div class="avsnitt">increase in certain enzymes in the blood (used for checking the health of your liver)</div>

                </li><li>

                    <div class="avsnitt">being sick </div>

                </li><li>

                    <div class="avsnitt">constipation</div>

                </li><li>

                    <div class="avsnitt">gas (flatulence), stomach discomfort, indigestion </div>

                </li><li>

                    <div class="avsnitt">decreased appetite</div>

                </li><li>

                    <div class="avsnitt">headache</div>

                </li><li>

                    <div class="avsnitt">feeling tired </div>

                </li><li>

                    <div class="avsnitt">weakness</div>

                </li><li>

                    <div class="avsnitt">feeling hot </div>

                </li><li>

                    <div class="avsnitt">abnormal sensations of the skin, such as itching, burning, stinging, tickling or tingling</div>

                </li><li>

                    <div class="avsnitt">pink or red blotches on the skin (erythema)</div>

                </li></ul>

        </div>

        <div class="avsnitt">Uncommon (may affect up to 1 in 100 people) <ul><li>

                    <div class="avsnitt">dizziness</div>

                </li><li>

                    <div class="avsnitt">excess protein in the urine (proteinuria)</div>

                </li><li>

                    <div class="avsnitt">increase in serum creatinine (a substance in the blood used for measuring how well your kidneys

are working) </div>

                </li></ul>

        </div>

        <div class="avsnitt">Rare (may affect up to 1 in 1,000 people) <ul><li>

                    <div class="avsnitt">allergic skin reaction </div>

                </li></ul>

        </div>

        <div class="avsnitt">Very rare (may affect up to 1 in 10,000 people)<ul><li>

                    <div class="avsnitt">acute lymphatic leukaemia (a type of blood cancer) </div>

                </li><li>

                    <div class="avsnitt">rdecrease in all types of blood cells (pancytopenia) </div>

                </li></ul>

        </div>

        <div class="avsnitt">Not known (frequency cannot be estimated from the available data) <ul><li>

                    <div class="avsnitt">shingles</div>

                </li></ul>

        </div>

        <div class="pil-melding seksjon">

            <span class="tittel">Reporting of side effects </span>

            <div class="avsnitt">If you get any side effects, talk to your doctor or pharmacist. This includes any possible side effects not

listed in this leaflet. You can also report side effects directly (see details below). By reporting side effects

you can help provide more information on the safety of this medicine. <a href="https://www.mhra.gov.uk/yellowcard" target="_blank" title="United Kingdom (Northern Ireland)&#xA;Yellow Card Scheme, Website: www.mhra.gov.uk/yellowcard or search for MHRA Yellow Card in the&#xA;Google Play or Apple App Store ">United Kingdom (Northern Ireland)

Yellow Card Scheme, Website: www.mhra.gov.uk/yellowcard or search for MHRA Yellow Card in the

Google Play or Apple App Store </a>

                Ireland

HPRA Pharmacovigilance, Website: www.hpra.ie </div>

        </div>

    </div>

    <a class="anchorLink" id="pil-oppbevaring"> </a><div class="pil-oppbevaring kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">How to store Skilarence </tittel></span>

        <div class="avsnitt">Keep this medicine out of the sight and reach of children. </div>

        <div class="avsnitt">Do not use this medicine after the expiry date which is stated on the carton and the blister after “EXP”.

The expiry date refers to the last day of that month. </div>

        <div class="avsnitt">This medicine does not require any special storage conditions. </div>

        <div class="avsnitt">Do not throw away any medicines via wastewater or household waste. Ask your pharmacist how to throw

away medicines you no longer use. These measures will help protect the environment. </div>

    </div>

    <a class="anchorLink" id="pil-innhold-info"> </a><div class="pil-innhold-info kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Contents of the pack and other information </tittel></span>

        <div class="pil-sammensetning seksjon">

            <span class="tittel">What Skilarence 30 mg contains</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">the active substance is dimethyl fumarate. One tablet contains 30 mg dimethyl fumarate</div>

                    </li><li>

                        <div class="avsnitt">he other ingredients are: lactose monohydrate, cellulose microcrystalline, croscarmellose sodium,

colloidal anhydrous silica, magnesium stearate, methacrylic acid-ethyl acrylate copolymer (1:1),

talc, triethyl citrate, titanium dioxide (E171) and simethicone. </div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-pakningsinnhold seksjon">

            <span class="tittel">What Skilarence 30 mg looks like and contents of the pack </span>

            <div class="avsnitt">Skilarence 30 mg is a white, round tablet with a diameter of approximately 6.8 mm. <br></br>

                Skilarence 30 mg is available in packs containing 42, 70 and 210 gastro-resistant tablets. Not all pack

sizes may be marketed. The tablets are packed in PVC/PVDC-aluminium blisters. </div>

        </div>

        <div class="pil-markedsføring-tilvirker seksjon">

            <span class="tittel">Marketing Authorisation Holder</span>

            <span class="flytende-tittel">Marketing Authorisation Holder</span>

            <div class="avsnitt">Almirall, S.A.<br></br>

                Ronda General Mitre, 151<br></br>

                E-08022

                Barcelona<br></br>

                Spania<br></br>

                Tlf. +34 93 291 30 00</div>

            <span class="flytende-tittel">Manufacturer</span>

            <div class="avsnitt">Industrias Farmacéuticas Almirall, S.A.<br></br>

                Ctra. De Martorell

                41-61<br></br>

                E-08740 Sant Andreu de la Barca, Barcelona<br></br>

                Spania</div>

            <div class="avsnitt">For any information about this medicine, please contact the local representative of the Marketing

Authorisation Holder: </div>

            <div class="pil-lokal-representant seksjon">

                <div class="avsnitt">

                    <b>Ireland/ United Kingdom (Northern Ireland) </b>

                    Almirall, S.A., Tel: +353 (0) 1431 9836 </div>

            </div>

        </div>

        <div class="pil-sist-endret seksjon tittel">This leaflet was last revised in 02/2022. </div>

        <div class="pil-andre-kilder seksjon">

            <span class="tittel">Other sources of information </span>

            <div class="avsnitt">Detailed information on this medicine is available on the European Medicines Agency web site: <a href="http://www.ema.europa.eu/" target="_blank" title="&#xA;                    &#xA;                        http://www.ema.europa.eu&#xA;                    &#xA;                ">

                    

                        http://www.ema.europa.eu

                    

                </a>

                <a href="http://www.ema.europa.eu/" target="_blank" title="&#xA;                    .&#xA;                ">

                    .

                </a>

            </div>

        </div>

    </div>

</div><div id="begrep"></div></body></html>
  `;

const skilarence_fr = `
  <?xml version="1.0" encoding="UTF-8"?>

<!DOCTYPE html

  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "xhtml1-transitional.dtd">

<html><head><title>Skilarence 30 mg, comprimés gastrorésistants - Felleskatalogen Pasientutgave</title><meta name="description" content="&#xA;         Qu’est-ce que Skilarence et dans quels cas est-il utilisé ? &#xA;         Qu’est-ce que Skilarence ? &#xA;         Skilarence est un médicament qui"></meta><meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta><meta name="author" content="Felleskatalogen"></meta><meta name="keywords" content="Skilarence 30 mg, comprimés gastrorésistants"></meta><meta http-equiv="X-UA-Compatible" content="IE=edge"></meta><meta name="date" content="Dette pakningsvedlegget ble sist oppdatert 17.05.2021"></meta><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_content.css" type="text/css"></link><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_fk.css" type="text/css"></link><meta name="varenr" content="500781"></meta><meta name="ean" content="7046265007814"></meta><meta name="lmrlopenr" content="3336054"></meta>

        

        

        

        <meta name="preparatnavn" content="Skilarence"></meta>

        <meta name="kortnummer" content="1111"></meta>

    <meta name="navnformstyrke" content="Skilarence 30 mg, comprimés gastrorésistants"></meta></head><body>
    
    <!-- HENRIK HAR KOMMENTERT UT DETTE    

    <div class="anchorindexbar">
    <a class="anchorindex" href="#pil-indikasjon" title="Bruksområder">Bruksområder</a> | 

    <a class="anchorindex" href="#pil-forsiktighet" title="Forsiktighetsregler">Forsiktighetsregler</a> | 

    <a class="anchorindex" href="#pil-bruk" title="Bruksmåte">Bruksmåte</a> | 

    <a class="anchorindex" href="#pil-bivirkning" title="Bivirkninger">Bivirkninger</a> | 

    <a class="anchorindex" href="#pil-oppbevaring" title="Oppbevaring">Oppbevaring</a> | 

    <a class="anchorindex" href="#pil-innhold-info" title="Ytterligere informasjon">Ytterligere informasjon</a>
    
    <br></br><br></br></div>
    -->


<div class="pil">

    

    

    <h1 class="tittel">Notice : Information du patient</h1>

    

    <h2 class="pil-navnformstyrke">Skilarence 30 mg, comprimés gastrorésistants</h2>

    <h3 class="pil-substans">fumarate de diméthyle</h3>

    <div class="pil-innledning">

        <div class="pil-regler seksjon">

            <span class="tittel">Veuillez lire attentivement cette notice avant de prendre ce médicament car elle contient des informations importantes pour vous.</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">Gardez cette notice. Vous pourriez avoir besoin de la relire.</div>

                    </li><li>

                        <div class="avsnitt">Si vous avez d’autres questions, interrogez votre médecin ou votre pharmacien.</div>

                    </li><li>

                        <div class="avsnitt">Ce médicament vous a été personnellement prescrit. Ne le donnez pas à d’autres personnes. Il pourrait leur être nocif, même si les signes de leur maladie sont identiques aux vôtres.</div>

                    </li><li>

                        <div class="avsnitt">Si vous ressentez un quelconque effet indésirable, parlez-en à votre médecin ou votre pharmacien. 

Ceci s’applique aussi à tout effet indésirable qui ne serait pas mentionné dans cette notice. Voir rubrique 4.</div>

                    </li></ul>

            </div>

        </div>

        <div class="toc generated seksjon"><span class="tittel">I dette pakningsvedlegget finner du informasjon om:</span><ol><li>Qu’est-ce que Skilarence et dans quels cas est-il utilisé ?</li><li>Quelles sont les informations à connaître avant de prendre Skilarence ?</li><li>Comment prendre Skilarence ?</li><li>Quels sont les effets indésirables éventuels ?</li><li>Comment conserver Skilarence ?</li><li>Contenu de l’emballage et autres informations</li></ol></div>

    </div>

    <a class="anchorLink" id="pil-indikasjon"> </a><div class="pil-indikasjon kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Qu’est-ce que Skilarence et dans quels cas est-il utilisé ?</tittel></span>

        <span class="flytende-tittel">Qu’est-ce que Skilarence ?</span>

        <div class="avsnitt">Skilarence est un médicament qui contient la substance active, le fumarate de diméthyle. 

Le fumarate de diméthyle agit sur les cellules du système immunitaire (les défenses naturelles de l’organisme). 

Il modifie l’activité du système immunitaire et réduit la production de substances impliquées dans le psoriasis.</div>

        <span class="flytende-tittel">Dans quel cas Skilarence est-il utilisé ?</span>

        <div class="avsnitt">Skilarence en comprimé, est utilisé pour traiter le psoriasis en plaques modéré à sévère chez les adultes. 

Le psoriasis est une maladie qui provoque un épaississement cutané, des plaques rouges liées à une 

inflammation de la peau souvent recouvertes de squames de couleur argentée.</div>

        <div class="avsnitt">Une réponse au Skilarence est généralement observée dès la semaine 3 et s’améliore avec le temps. 

L’expérience avec des produits semblables contenant du fumarate de diméthyle a démontré un effet bénéfique du traitement jusqu’à 24 mois.</div>

    </div>

    <a class="anchorLink" id="pil-forsiktighet"> </a><div class="pil-forsiktighet kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Quelles sont les informations à connaître avant de prendre Skilarence ?</tittel></span>

        <div class="pil-kontraindikasjon seksjon">

            <span class="tittel">Ne prenez jamais Skilarence</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">si vous êtes allergique au fumarate de diméthyle ou à l’un des autres composants contenus dans ce médicament (mentionnés dans la rubrique 6)</div>

                    </li><li>

                        <div class="avsnitt">si vous souffrez de troubles sévères de l’estomac ou de l’intestin</div>

                    </li><li>

                        <div class="avsnitt">si vous présentez des problèmes hépatiques ou rénaux sévères</div>

                    </li><li>

                        <div class="avsnitt">i vous êtes enceinte ou si vous allaitez</div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-advarsel seksjon">

            <span class="tittel">Avertissements et précautions</span>

            <div class="avsnitt">Adressez-vous à votre médecin ou pharmacien avant de prendre Skilarence.</div>

            <div class="avsnitt">

                <u>Surveillance</u>

                <br></br>

                

                Skilarence peut provoquer des anomalies au niveau de votre sang, de votre foie ou de vos reins. 

Vous devrez faire des analyses de sang et d’urine avant le traitement, puis régulièrement pendant 

le traitement afin de s’assurer que vous ne développiez pas de telles complications et que vous puissiez 

continuer à prendre ce médicament. Selon les résultats des analyses de sang et d’urine, votre médecin 

pourra réduire la dose de Skilarence ou arrêter le traitement.</div>

            <div class="avsnitt">

                <u>Infections</u>

                <br></br>

                

                Les globules blancs aident votre organisme à lutter contre les infections. Skilarence peut réduire le nombre de vos globules blancs. Informez votre médecin si vous pensez avoir une infection. Les symptômes incluent : fièvre, douleurs, douleurs musculaires, maux de tête, perte d’appétit et une sensation générale de fatigue. Si avez eu une infection grave, soit avant de commencer le traitement par Skilarence, soit en cours de traitement, votre médecin peut vous conseiller de ne pas prendre Skilarence tant que l’infection ne sera pas résolue.</div>

            <div class="avsnitt">

                <u>Affections gastro-intestinales</u>

                <br></br>

                Informez votre médecin si vous avez des problèmes d’estomac ou des intestins. Votre médecin vous conseillera sur les mesures à prendre pendant le traitement par Skilarence.</div>

        </div>

        <div class="pil-barn-unge seksjon">

            <span class="tittel">Enfants et adolescents</span>

            <div class="avsnitt">Les enfants et les adolescents de moins de 18 ans ne doivent pas prendre ce médicament car il n’a pas été étudié dans ce groupe d’âge.</div>

        </div>

        <div class="pil-interaksjon seksjon">

            <span class="tittel">Autres médicaments et Skilarence</span>

            <div class="avsnitt">Informez votre médecin ou pharmacien si vous prenez, avez récemment pris ou pourriez prendre tout autre médicament.</div>

            <div class="avsnitt">Informez votre médecin, en particulier si vous prenez l’un des médicaments suivants :</div>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">

                            <b>Fumarate de diméthyle ou autres fumarates :

                            </b>

                            a substance active de Skilarence, le fumarate de diméthyle, est également utilisée dans d’autres médicaments, tels que des comprimés, pommades et sels de bains. Évitez d’utiliser d’autres produits qui contiennent des fumarates pour ne pas en prendre trop.</div>

                    </li><li>

                        <div class="avsnitt">

                            <b>Autres médicaments utilisés pour traiter le psoriasis, </b>

                            els que le méthotrexate, les rétinoïdes, le psoralène, la ciclosporine ou autres immunosuppresseurs ou cytostatiques (médicaments qui agissent sur le système immunitaire). La prise de ces médicaments avec Skilarence pourrait augmenter le risque d’effets indésirables sur votre système immunitaire.</div>

                    </li><li>

                        <div class="avsnitt">

                            <b>Autres médicaments qui peuvent affecter votre fonction rénale, </b>

                            tels que le méthotrexate ou la ciclosporine (utilisés pour traiter le psoriasis), les aminosides (utilisés pour traiter les infections), les diurétiques (qui augmentent l’élimination de l’urine), les médicaments anti- inflammatoires non stéroïdiens (utilisés pour traiter la douleur) ou le lithium (utilisé pour la maladie bipolaire et la dépression). Ces médicaments pris conjointement avec Skilarence pourraient augmenter le risque d’effets indésirables au niveau de vos reins.

</div>

                    </li></ul>

            </div>

            <div class="avsnitt">Si vous présentez une diarrhée sévère ou prolongée avec Skilarence, il est possible que d’autres médicaments n’agissent pas aussi bien qu’ils ne le devraient. Si vous avez une forte diarrhée et si vous pensez que les autres médicaments que vous prenez n’agissent pas, informez-en votre médecin. Plus particulièrement, si vous prenez un contraceptif oral (la pilule), son effet pourra être réduit et vous devrez utiliser d’autres méthodes contraceptives dites de barrière pour éviter une grossesse. Voir les instructions sur la notice du contraceptif que vous prenez.</div>

            <div class="avsnitt">Si vous avez besoin d’être vacciné(e), informez votre médecin. Certains types de vaccins (vaccins vivants) peuvent provoquer une infection s’ils sont utilisés pendant le traitement par Skilarence. Votre médecin vous conseillera ce qui est le mieux à faire.</div>

        </div>

        <div class="pil-mat-drikke seksjon">

            <span class="tittel">Skilarence avec l’alcool</span>

            <div class="avsnitt">Évitez de consommer des boissons fortement alcoolisées (pas plus de 50 ml de spiritueux contenant plus de 30 % d’alcool par volume) pendant le traitement par Skilarence, car l’alcool peut interagir avec ce médicament. Cela pourrait provoquer des problèmes au niveau de l’estomac et de l’intestin.</div>

        </div>

        <div class="pil-graviditet-amming-fertilitet seksjon">

            <span class="tittel">Grossesse et allaitement</span>

            <div class="avsnitt">Ne prenez pas Skilarence si vous êtes enceinte ou essayez d’être enceinte, car Skilarence pourrait être nocif pour votre bébé. Utilisez des méthodes de contraception efficaces pour éviter une grossesse durant le traitement par Skilarence (voir également « Autres médicaments et Skilarence » ci-dessus). Ne pas allaiter pendant le traitement par Skilarence.<br></br>

                

                

                Unngå amming under behandling med Skilarence.</div>

        </div>

        <div class="pil-bil-maskiner seksjon">

            <span class="tittel">Conduite de véhicules et utilisation de machines</span>

            <div class="avsnitt">Skilarence peut avoir une influence mineure sur l’aptitude à conduire des véhicules et à utiliser des machines. Vous pourrez être pris(e) de vertiges ou vous sentir fatigué(e) après avoir pris Skilarence. Dans ce cas, soyez prudent en conduisant ou en utilisant des machines.</div>

        </div>

        <div class="pil-innholdsstoffer seksjon">

            

                <span class="tittel">Skilarence contient du lactose</span>

                <div class="avsnitt">Si votre médecin vous a informé(e) que vous êtes intolérant(e) à certains sucres, informez-le avant de prendre ce médicament.</div>

            

            

                <span class="tittel">Skilarence contient du sodium</span>

                <div class="avsnitt">Ce médicament contient moins de 1 mmol de sodium (23 mg) par comprimé et est essentiellement «sans sodium».</div>

            

        </div>

    </div>

    <a class="anchorLink" id="pil-bruk"> </a><div class="pil-bruk kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Comment prendre Skilarence ?</tittel></span>

        <div class="avsnitt">Veillez à toujours prendre ce médicament en suivant strictement les indications de votre médecin ou pharmacien. Vérifiez auprès de votre médecin ou pharmacien en cas de doute.</div>

        <span class="flytende-tittel">Posologie</span>

        <div class="avsnitt">Votre médecin débutera votre traitement par une faible dose (en utilisant des comprimés de Skilarence à 30 mg). Cela permet de réduire des problèmes d’estomac et autres effets indésirables. Votre dose sera augmentée toutes les semaines comme indiqué dans le tableau ci-dessous (en passant aux comprimés de Skilarence 120 mg à partir de la Semaine 4).</div>

       <div class="avsnitt">

            <div class="table-wrapper"><table cellspacing="0" class="">

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <!--columnwidth: 14.285714285714285714--><col width="%"></col>

                    <thead>

                        <tr>

                            <th class="" rowspan="2"><p>Behandlingsuke</p></th>

                            <th class="" rowspan="2"><p>Tablettstyrke</p></th>

                            <th class="" colspan="3"><p>Hvor mange tabletter som skal tas i løpet av dagen</p></th>

                            <th class="" rowspan="2"><p>Antall tabletter per dag</p></th>

                            <th class="" rowspan="2"><p>Total daglig dose</p></th>

                        </tr>

                        <tr>

                            <th class=""><p>Frokost</p></th>

                            <th class=""><p>Lunsj</p></th>

                            <th class=""><p>Kveldsmat</p></th>

                        </tr>

                    </thead>

                    <tbody>

                        <tr>

                            <td class=""><p>1</p></td>

                            <td class=""><p>30 mg</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>30 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>2</p></td>

                            <td class=""><p>30 mg</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>60 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>3</p></td>

                            <td class=""><p>30 mg</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>3</p></td>

                            <td class=""><p>90 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>4</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>120 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>5</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>-</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>240 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>6</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>3</p></td>

                            <td class=""><p>360 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>7</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>4</p></td>

                            <td class=""><p>480 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>8</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>1</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>5</p></td>

                            <td class=""><p>600 mg</p></td>

                        </tr>

                        <tr>

                            <td class=""><p>9+</p></td>

                            <td class=""><p>120 mg</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>2</p></td>

                            <td class=""><p>6</p></td>

                            <td class=""><p>720 mg</p></td>

                        </tr>

                    </tbody>

                </table></div>

        </div>







        <div class="avsnitt">

            <a rel="lightbox" href="INTERNAL:/content/pakningsvedlegg/S/Skilarence...Almirall_639499/13021991.jpeg"><img src="INTERNAL:/content/pakningsvedlegg/S/Skilarence...Almirall_639499/13021991.jpeg" alt="Option de texte manquante pour l'image"></img></a>

        </div>

        <div class="avsnitt">Votre médecin contrôlera votre état après le début du traitement par Skilarence et vérifiera si vous présentez des effets indésirables. 

Si vous présentez des effets indésirables sévères après une augmentation de la dose, votre médecin pourra vous demander de revenir 

temporairement à la dose précédente. Si les effets indésirables ne sont pas gênants, votre dose sera augmentée jusqu’à ce que votre 

état soit correctement contrôlé. Il est possible que vous n’ayez pas besoin de la dose maximale de 720 mg par jour. Lorsque votre état 

sera suffisamment amélioré, votre médecin envisagera la façon de réduire progressivement la dose quotidienne de Skilarence jusqu’à la 

dose dont vous avez besoin pour maintenir l’amélioration.</div>

        <span class="flytende-tittel">Mode d’administration</span>

        <div class="avsnitt">Avalez les comprimés de Skilarence entiers avec du liquide. Prenez vos comprimés pendant ou immédiatement après un repas. N’écrasez pas, ne cassez pas, ne dissolvez pas, et ne mâchez pas les comprimés de Skilarence car ils sont spécialement enrobés afin de réduire des effets irritants pour votre estomac.</div>

        <div class="pil-overdosering seksjon">

            <span class="tittel">Si vous avez pris plus de Skilarence que vous n’auriez dû</span>

            <div class="avsnitt">Si vous pensez avoir pris trop de comprimés de Skilarence, adressez-vous à votre médecin ou votre pharmacien.</div>

        </div>

        <div class="pil-glemt-dose seksjon" id="pil-glemt-dose">

            <span class="tittel">Si vous oubliez de prendre Skilarence</span>

            <div class="avsnitt">Ne prenez pas de dose double pour compenser la dose que vous avez oublié de prendre. 

Prenez la dose suivante à l’heure habituelle et continuez à prendre le médicament exactement 

de la façon décrite dans cette notice, ou exactement comme convenu avec votre médecin. 

Vérifiez auprès de votre médecin ou pharmacien en cas de doute.</div>

            <div class="avsnitt">Si vous avez d’autres questions sur l’utilisation de ce médicament, demandez plus d’informations à votre médecin ou à votre pharmacien.</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-bivirkning"> </a><div class="pil-bivirkning kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Quels sont les effets indésirables éventuels ?</tittel></span>

        <div class="avsnitt">Comme tous les médicaments, ce médicament peut provoquer des effets indésirables, mais ils ne surviennent pas systématiquement chez tout le monde. Certains de ces effets indésirables, tels que rougeurs sur le visage ou le corps (bouffées de chaleur), diarrhées, problèmes gastriques et nausées, s’améliorent généralement au cours du traitement.</div>

        <div class="avsnitt">Les effets indésirables les plus graves pouvant survenir avec Skilarence sont des réactions allergiques ou d’hypersensibilité ; l’insuffisance rénale ou une maladie rénale dite syndrome de Fanconi ; ou une infection grave du cerveau dite leucoencéphalopathie multifocale progressive (LEMP). Leur fréquence de survenue n’est pas connue. Pour en connaître les symptômes, voir ci-dessous.</div>

        <div class="avsnitt">

            <u>Réactions allergiques ou d’hypersensibilité</u>

            <br></br>

            Les réactions allergiques ou d’hypersensibilité sont rares mais peuvent être très graves. L’apparition de rougeurs au niveau du visage ou sur le corps (bouffées de chaleur) est un événement indésirable très fréquent qui peut toucher plus de 1 personne sur 10. Cependant, si des rougeurs apparaissent accompagnées de l’un des signes suivants :<ul><li>

                    <div class="avsnitt">respiration sifflante, difficultés à respirer ou essoufflements,</div>

                </li><li>

                    <div class="avsnitt">gonflement au niveau du visage, des lèvres, de la bouche ou de la langue

arrêtez de prendre Skilarence et contactez immédiatement un médecin.</div>

                </li></ul>

          arrêtez de prendre Skilarence et contactez immédiatement un médecin.

      </div>

        <div class="avsnitt">

            <u>Infection du cerveau dénommée LEMP</u>

            <br></br>

            La leucoencéphalopathie multifocale progressive (LEMP) est une infection du cerveau rare mais grave qui peut entraîner un handicap sévère ou le décès. 

Si vous remarquez une apparition ou une aggravation d’une faiblesse d’un côté du corps, un manque de coordination, des troubles de la vision, de la pensée 

ou de la mémoire, une certaine confusion, ou des changements de la personnalité durant plusieurs jours, arrêtez de prendre Skilarence et informez immédiatement 

votre médecin.</div>

        <div class="avsnitt">

            <u>Syndrome de Fanconi</u>

            <br></br>

            Le syndrome de Fanconi est une affection rénale rare mais grave qui peut se produire sous Skilarence. Si vous remarquez que vous urinez plus fréquemment, que vous avez plus soif et que vous buvez plus que d’habitude, que vos muscles sont plus faibles, que vous vous êtes fracturé un os, ou si vous ressentez simplement des douleurs, informez immédiatement votre médecin afin que ces symptômes puissent être examinés de manière plus approfondie.</div>

        <div class="avsnitt">Informez votre médecin si vous présentez l’un des effets secondaires ci-dessous :</div>

        <div class="avsnitt">Effets indésirables très fréquents (pouvant affecter plus d’une personne sur 10) :<ul><li>

                    <div class="avsnitt">diminution du taux des globules blancs dénommés lymphocytes (lymphopénie)</div>

                </li><li>

                    <div class="avsnitt">diminution des taux de tous les globules blancs (leucopénie)</div>

                </li><li>

                    <div class="avsnitt">rougeurs sur le visage ou le corps (bouffées de chaleur)</div>

                </li><li>

                    <div class="avsnitt">diarrhée</div>

                </li><li>

                    <div class="avsnitt">ballonnements, douleurs gastriques ou crampes d’estomac</div>

                </li><li>

                    <div class="avsnitt">nausées</div>

                </li></ul>

        </div>

        <div class="avsnitt">Effets indésirables fréquents (pouvant affecter jusqu’à 1 personne sur 10) :<ul><li>

                    <div class="avsnitt">augmentation des taux de tous les globules blancs (leucocytose)</div>

                </li><li>

                    <div class="avsnitt">augmentation du taux d’un des types de globules blancs appelés éosinophiles</div>

                </li><li>

                    <div class="avsnitt">augmentation des taux de certaines enzymes dans le sang (utilisées pour vérifier l’état de votre

foie)</div>

                </li><li>

                    <div class="avsnitt">sensation de malaise</div>

                </li><li>

                    <div class="avsnitt">constipation</div>

                </li><li>

                    <div class="avsnitt">gaz (flatulences), gêne au niveau de l’estomac, indigestion</div>

                </li><li>

                    <div class="avsnitt">diminution de l’appétit</div>

                </li><li>

                    <div class="avsnitt">maux de tête</div>

                </li><li>

                    <div class="avsnitt">sensation de fatigue</div>

                </li><li>

                    <div class="avsnitt">faiblesse</div>

                </li><li>

                    <div class="avsnitt">sensation de chaleur</div>

                </li><li>

                    <div class="avsnitt">sensations anormales de la peau, telles que des démangeaisons, brûlures, piqûres ou picotements</div>

                </li><li>

                    <div class="avsnitt">tâches cutanées roses ou rouges (érythème)</div>

                </li></ul>

        </div>

        <div class="avsnitt">Effets indésirables peu fréquents (pouvant affecter jusqu’à 1 personne sur 100) :<ul><li>

                    <div class="avsnitt">étourdissements</div>

                </li><li>

                    <div class="avsnitt">excès de protéines dans les urines (protéinurie))</div>

                </li><li>

                    <div class="avsnitt">augmentation du taux de créatinine sérique (une substance dans le sang utilisée pour évaluer le

bon fonctionnement de vos reins)</div>

                </li></ul>

        </div>

        <div class="avsnitt">Effets indésirables rares (pouvant affecter jusqu’à 1 personne sur 1 000) :<ul><li>

                    <div class="avsnitt">réaction cutanée allergique</div>

                </li></ul>

        </div>

        <div class="avsnitt">Effets indésirables très rares (pouvant affecter jusqu’à 1 personne sur 10 000) :<ul><li>

                    <div class="avsnitt">leucémie lymphoblastique aiguë (un type de cancer du sang)</div>

                </li><li>

                    <div class="avsnitt">diminution de tous les types de cellules sanguines (panctytopénie)</div>

                </li></ul>

        </div>

        <div class="avsnitt">Fréquence inconnue (la fréquence ne peut être estimée à partir des données disponibles) :<ul><li>

                    <div class="avsnitt">Zona</div>

                </li></ul>

        </div>

        <div class="pil-melding seksjon">

            <span class="tittel">Déclaration des effets secondaires</span>

            <div class="avsnitt">Si vous ressentez un quelconque effet indésirable, parlez-en à votre médecin ou votre pharmacien. Ceci s’applique aussi à tout effet indésirable qui ne serait pas mentionné dans cette notice. Vous pouvez également déclarer les effets indésirables directement via le système national de déclaration décrit en Annexe V. En signalant les effets indésirables, vous contribuez à fournir davantage d’informations sur la sécurité du médicament.<a href="https://www.signalement-sante.gouv.fr" target="_blank" title="www.signalement-sante.gouv.fr">www.signalement-sante.gouv.fr</a>

                </div>

        </div>

    </div>

    <a class="anchorLink" id="pil-oppbevaring"> </a><div class="pil-oppbevaring kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Comment conserver Skilarence ?</tittel></span>

        <div class="avsnitt">Tenir ce médicament hors de la vue et de la portée des enfants.</div>

        <div class="avsnitt">N’utilisez pas ce médicament après la date de péremption indiquée sur la boîte et la plaquette thermoformée après « EXP ». La date de péremption fait référence au dernier jour de ce mois.</div>

        <div class="avsnitt">Ce médicament ne nécessite pas de précautions particulières de conservation.</div>

        <div class="avsnitt">Ne jetez aucun médicament au tout-à-l’égout ou avec les ordures ménagères. Demandez à votre pharmacien d’éliminer les médicaments que vous n’utilisez plus. Ces mesures contribueront à protéger l’environnement.</div>

    </div>

    <a class="anchorLink" id="pil-innhold-info"> </a><div class="pil-innhold-info kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Contenu de l’emballage et autres informations</tittel></span>

        <div class="pil-sammensetning seksjon">

            <span class="tittel">Ce que contient Skilarence 30 mg</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">La substance active est le fumarate de diméthyle. Un comprimé contient 30 mg de fumarate de diméthyle.</div>

                    </li><li>

                        <div class="avsnitt">ALes autres composants sont : lactose monohydraté, cellulose microcristalline, croscarmellose sodique, silice colloïdale anhydre, stéarate de magnésium, copolymère d’acide méthacrylique et d’acrylate d’éthyle (1:1), talc, citrate de triéthyle, dioxyde de titane (E171) et siméthicone.</div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-pakningsinnhold seksjon">

            <span class="tittel">Comment se présente Skilarence 30 mg et contenu de l’emballage extérieur</span>

            <div class="avsnitt">Skilarence 30 mg se présente sous forme de comprimé rond, blanc, d’un diamètre de 6,8 mm environ. <br></br>

                Skilarence 30 mg est disponible en boîtes de 42 comprimés gastrorésistants. Les comprimés sont conditionnés sous plaquettes thermoformées en PVC/PVDC-aluminium.</div>

        </div>

        <div class="pil-markedsføring-tilvirker seksjon">

            <span class="tittel">Titulaire de l’Autorisation de mise sur le marché</span>

            <span class="flytende-tittel">Titulaire de l’Autorisation de mise sur le marché</span>

            <div class="avsnitt">Almirall, S.A.<br></br>

                

                

                Ronda General Mitre, 151<br></br>

                

                

                E-08022

                Barcelona<br></br>

                

                

                Spania<br></br>

                

                

                Tlf. +34 93 291 30 00</div>

            <span class="flytende-tittel">Fabricant</span>

            <div class="avsnitt">Industrias Farmacéuticas Almirall, S.A.<br></br>

                

                

                Ctra. De Martorell

                41-61<br></br>

                

                

                E-08740 Sant Andreu de la Barca, Barcelona<br></br>

                

                

                Spania</div>

            <div class="avsnitt">Des informations détaillées sur ce médicament sont disponibles sur le site internet de l’Agence européenne des médicaments http://www.ema.europa.eu.</div>

            <div class="pil-lokal-representant seksjon">

                <div class="avsnitt">

                    <b>Danmark/Norge</b>

                    

                    

                    /<b>Suomi/Finland/Sverige</b>

                    <br></br>

                    

                    

                    Almirall

                    ApS, Tlf/Puh/Tel: +45 70 25 75 75</div>

            </div>

        </div>

        <div class="pil-sist-endret seksjon tittel">Dette pakningsvedlegget ble sist oppdatert 17.05.2021</div>

        <div class="pil-andre-kilder seksjon">

            <span class="tittel">Andre informasjonskilder</span>

            <div class="avsnitt">Detaljert informasjon er tilgjengelig på nettstedet til Det

                europeiske legemiddelkontoret (The European Medicines Agency) <a href="http://www.ema.europa.eu/" target="_blank" title="&#xA;                    &#xA;                        http://www.ema.europa.eu&#xA;                    &#xA;                ">

                    

                        http://www.ema.europa.eu

                    

                </a>

                <a href="http://www.ema.europa.eu/" target="_blank" title="&#xA;                    .&#xA;                ">

                    .

                </a>

            </div>

        </div>

    </div>

</div><div id="begrep"></div></body></html>

  `;

const forxiga_en = `
  <?xml version="1.0" encoding="UTF-8"?>

<!DOCTYPE html

  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "xhtml1-transitional.dtd">

<html><head><title>Forxiga AstraZeneca - Felleskatalogen Pasientutgave</title><meta name="description" content="&#xA;         What Forxiga is and what it is used for &#xA;         &#xA;What Forxiga is &#xA;         Forxiga contains the active substance dapagliflozin. It belongs to"></meta><meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta><meta name="author" content="Felleskatalogen"></meta><meta name="keywords" content="Forxiga 10 mg  Forxiga 5 mg"></meta><meta http-equiv="X-UA-Compatible" content="IE=edge"></meta><meta name="date" content="This leaflet was last revised in 15.11.2021"></meta><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_content.css" type="text/css"></link><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_fk.css" type="text/css"></link><meta name="varenr" content="502904,462070,381510,126390"></meta><meta name="ean" content="7046265029045,7046264620700,7046263815107,7046261263900,5000456069458,5000456069281,5000456069250"></meta><meta name="lmrlopenr" content="2017957,2017957,2017956,2017956"></meta>

        

        

        

        

        

        

        

        

        

        

        

        

        

        

        

        <meta name="preparatnavn" content="Forxiga"></meta>

        <meta name="kortnummer" content="1111"></meta>

    <meta name="navnformstyrke" content="Forxiga 5 mg,Forxiga 10 mg "></meta></head><body><div class="anchorindexbar">

    

    

    

    

    

    

    

    

    

    <a class="anchorindex" href="#pil-indikasjon" title="Bruksområder">Bruksområder</a> | 

    <a class="anchorindex" href="#pil-forsiktighet" title="Forsiktighetsregler">Forsiktighetsregler</a> | 

    <a class="anchorindex" href="#pil-bruk" title="Bruksmåte">Bruksmåte</a> | 

    <a class="anchorindex" href="#pil-bivirkning" title="Bivirkninger">Bivirkninger</a> | 

    <a class="anchorindex" href="#pil-oppbevaring" title="Oppbevaring">Oppbevaring</a> | 

    <a class="anchorindex" href="#pil-innhold-info" title="Ytterligere informasjon">Ytterligere informasjon</a>

<br></br><br></br></div><div class="pil">

    

    

    <h1 class="tittel">Package leaflet: Information for the patient</h1>

    

    

    <h2 class="pil-navnformstyrke">Forxiga 5 mg film-coated tablets</h2>

    <h2 class="pil-navnformstyrke">Forxiga 10 mg film-coated tablets</h2>

    <h3 class="pil-substans">dapagliflozin</h3>

    <div class="pil-innledning">

        <div class="pil-regler seksjon">

            <span class="tittel">Read all of this leaflet carefully before you start taking this medicine because it contains important information for you.</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">Keep this leaflet. You may need to read it again.</div>

                    </li><li>

                        <div class="avsnitt">If you have any further questions, ask your doctor, pharmacist or nurse.</div>

                    </li><li>

                        <div class="avsnitt">This medicine has been prescribed for you only. Do not pass it on to others. It may harm them, even if their signs of illness are the same as yours.</div>

                    </li><li>

                        <div class="avsnitt">If you get any side effects, talk to your doctor or pharmacist. This includes any possible side effects not listed in this leaflet. See section 4.</div>

                    </li></ul>

            </div>

        </div>

        <div class="toc generated seksjon"><span class="tittel">I dette pakningsvedlegget finner du informasjon om:</span><ol><li>What Forxiga is and what it is used for</li><li>What you need to know before you take Forxiga</li><li>How to take Forxiga</li><li>Possible side effects</li><li>How to store Forxiga</li><li>Contents of the pack and other information</li></ol></div>

    </div>

    <a class="anchorLink" id="pil-indikasjon"> </a><div class="pil-indikasjon kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">What Forxiga is and what it is used for</tittel></span>

        <span class="flytende-tittel">

What Forxiga is</span>

        <div class="avsnitt">Forxiga contains the active substance dapagliflozin. It belongs to a group of medicines called “sodium glucose co-transporter-2 (SGLT2) inhibitors”. They work by blocking the SGLT2 protein in your kidney. By blocking this protein, blood sugar (glucose), salt (sodium) and water are removed from your body via the urine.</div>

        <span class="flytende-tittel">What Forxiga is used for</span>

        <div class="avsnitt">Forxiga is used to treat:</div>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>Type 2 diabetes</b>

                        <ul><li>

                                <div class="avsnitt">in adults and children aged 10 years and older.</div>

                            </li><li>

                                <div class="avsnitt">if your type 2 diabetes cannot be controlled with diet and exercise.</div>

                            </li><li>

                                <div class="avsnitt">Forxiga can be used on its own or together with other medicines to treat diabetes.</div>

                            </li><li>

                                <div class="avsnitt">It is important to continue to follow the advice on diet and exercise given to you by your

doctor, pharmacist or nurse.</div>

                            </li></ul>

                    </div>

                </li></ul>

        </div>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>Heart failure</b>

                        <ul><li>

                                <div class="avsnitt">in adults (aged 18 years and older) with symptoms due to a weak pump function of the

heart.</div>

                            </li></ul>

                    </div>

                </li></ul>

        </div>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>Chronic kidney disease</b>

                        <ul><li>

                                <div class="avsnitt">in adults with reduced kidney function.</div>

                            </li></ul>

                    </div>

                </li></ul>

        </div>

        <span class="flytende-tittel">What is type 2 diabetes and how does Forxiga help?</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">In type 2 diabetes your body does not make enough insulin or is not able to use the insulin it makes properly. This leads to a high level of sugar in your blood. This can lead to serious problems like heart or kidney disease, blindness, and poor circulation in your arms and legs.</div>

                </li><li>

                    <div class="avsnitt">Forxiga works by removing excess sugar from your body. It can also help prevent heart disease.</div>

                </li></ul>

        </div>

        <span class="flytende-tittel">What is heart failure and how does Forxiga help?</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">This type of heart failure occurs when the heart is weak and cannot pump enough blood to the lungs and the rest of the body. 

This can lead to serious medical problems and need for hospital care.</div>

                </li><li>

                    <div class="avsnitt">The most common symptoms of heart failure are feeling breathless, feeling tired or very tired all the time, and ankle swelling.</div>

                </li><li>

                    <div class="avsnitt">Forxiga helps protect your heart from getting weaker and improves your symptoms. It can lower the need to go to hospital and can help some patients to live longer.</div>

                </li></ul>

        </div>

        <span class="flytende-tittel">What is chronic kidney disease and how does Forxiga help?</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">When you have chronic kidney disease, your kidneys may gradually lose their function. This means they would not be able to clean and filter your blood the way they should. Loss of kidney function can lead to serious medical problems and need for hospital care.</div>

                </li><li>

                    <div class="avsnitt">Forxiga helps protect your kidneys from losing their function. That can help some patients to live longer.</div>

                </li></ul>

        </div>

    </div>

    <a class="anchorLink" id="pil-forsiktighet"> </a><div class="pil-forsiktighet kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">What you need to know before you take Forxiga</tittel></span>

        <div class="pil-kontraindikasjon seksjon">

            <span class="tittel">Do not take Forxiga</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">if you are allergic to dapagliflozin or any of the other ingredients of this medicine (listed in section 6).</div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-advarsel seksjon">

            <span class="tittel">Warnings and precautions</span>

            <span class="flytende-tittel">Contact a doctor or the nearest hospital straight away:</span>

            <div class="avsnitt">Diabetic ketoacidosis:<ul><li>

                        <div class="avsnitt">If you have diabetes and experience feeling sick or being sick, stomach pain, excessive thirst, fast and deep breathing, confusion, unusual sleepiness or tiredness, a sweet smell to your breath, a sweet or metallic taste in your mouth, or a different odour to your urine or sweat or rapid weight loss.</div>

                    </li><li>

                        <div class="avsnitt">The above symptoms could be a sign of “diabetic ketoacidosis” – a rare but serious, sometimes life-threatening problem you can get with diabetes because of increased levels of “ketone bodies” in your urine or blood, seen in tests.</div>

                    </li><li>

                        <div class="avsnitt">The risk of developing diabetic ketoacidosis may be increased with prolonged fasting, excessive alcohol consumption, dehydration, sudden reductions in insulin dose, or a higher need of insulin due to major surgery or serious illness.</div>

                    </li><li>

                        <div class="avsnitt">When you are treated with Forxiga, diabetic ketoacidosis can occur even if your blood sugar is normal.</div>

                    </li></ul>

                If you suspect you have diabetic ketoacidosis, contact a doctor or the nearest hospital straight away and do not take this medicine.</div>

            <div class="avsnitt">Necrotising fasciitis of the perineum:<ul><li>

                        <div class="avsnitt">Talk to your doctor immediately if you develop a combination of symptoms of pain, tenderness,

redness, or swelling of the genitals or the area between the genitals and the anus with fever or feeling generally unwell. These symptoms could be a sign of a rare but serious or even life- threatening infection, called necrotising fasciitis of the perineum or Fournier’s gangrene which destroys the tissue under the skin. Fournier’s gangrene has to be treated immediately.</div>

                    </li></ul>

            </div>

            <span class="flytende-tittel">

Talk to your doctor, pharmacist or nurse before taking Forxiga:</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">if you have “type 1 diabetes” – the type that usually starts when you are young, and your body does not produce any insulin.</div>

                    </li><li>

                        <div class="avsnitt">if you have diabetes and have a kidney problem – your doctor may ask you to take additional or a different medicine to control your blood sugar.</div>

                    </li><li>

                        <div class="avsnitt">if you have a liver problem – your doctor may start you on a lower dose.</div>

                    </li><li>

                        <div class="avsnitt">if you are on medicines to lower your blood pressure (anti-hypertensives) and have a history of low blood pressure (hypotension). More information is given below under ‘Other medicines and Forxiga’.</div>

                    </li><li>

                        <div class="avsnitt">if you have very high levels of sugar in your blood which may make you dehydrated (lose too much body fluid). Possible signs of dehydration are listed in section 4. Tell your doctor before you start taking Forxiga if you have any of these signs.</div>

                    </li><li>

                        <div class="avsnitt">if you have or develop nausea (feeling sick), vomiting or fever or if you are not able to eat or drink. These conditions can cause dehydration. Your doctor may ask you to stop taking Forxiga until you recover to prevent dehydration.</div>

                    </li><li>

                        <div class="avsnitt">if you often get infections of the urinary tract.</div>

                    </li></ul>

            </div>

            <div class="avsnitt">If any of the above applies to you (or you are not sure), talk to your doctor, pharmacist or nurse before taking Forxiga.</div>

            <span class="flytende-tittel">Diabetes and foot care</span>

            <div class="avsnitt">If you have diabetes, it is important to check your feet regularly and adhere to any other advice regarding foot care given by your health care professional.</div>

            <span class="flytende-tittel">Urine glucose</span>

            <div class="avsnitt">Because of how Forxiga works, your urine will test positive for sugar while you are on this medicine.</div>

        </div>

        <div class="pil-barn-unge seksjon">

            <span class="tittel">Children and adolescents</span>

            <div class="avsnitt">Forxiga can be used in children aged 10 years and older for the treatment of type 2 diabetes. No data are available in children below 10 years of age.

</div>

            <div class="avsnitt">Forxiga is not recommended for children and adolescents under 18 years of age for the treatment of heart failure or for the treatment of chronic kidney disease, because it has not been studied in these patients.</div>

        </div>

        <div class="pil-interaksjon seksjon">

            <span class="tittel">Other medicines and Forxiga</span>

            <div class="avsnitt">Tell your doctor, pharmacist or nurse if you are taking, have recently taken or might take any other medicines.<br></br>

                Especially tell your doctor<ul><li>

                        <div class="avsnitt">if you are taking a medicine used to remove water from the body (diuretic).</div>

                    </li><li>

                        <div class="avsnitt">if you are taking other medicines that lower the amount of sugar in your blood such as insulin or a “sulphonylurea” medicine. Your doctor may want to lower the dose of these other medicines, to prevent you from getting low blood sugar levels (hypoglycaemia).</div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-graviditet-amming-fertilitet seksjon">

            <span class="tittel">Pregnancy and breast-feeding</span>

            <div class="avsnitt">If you are pregnant or breast-feeding, think you may be pregnant or are planning to have a baby, ask your doctor or pharmacist for advice before taking this medicine. You should stop taking this medicine if you become pregnant, since it is not recommended during the second and third trimesters of pregnancy. Talk to your doctor about the best way to control your blood sugar while you are pregnant.</div>

            <div class="avsnitt">Talk to your doctor if you would like to or are breast-feeding before taking this medicine. Do not use Forxiga if you are breast-feeding. It is not known if this medicine passes into human breast milk.

</div>

        </div>

        <div class="pil-bil-maskiner seksjon">

            <span class="tittel">Driving and using machines</span>

            <div class="avsnitt">Forxiga has no or negligible influence on the ability to drive and use machines.</div>

            <div class="avsnitt">Taking this medicine with other medicines called sulphonylureas or with insulin can cause too low blood sugar levels (hypoglycaemia), which may cause symptoms such as shaking, sweating and change in vision, and may affect your ability to drive and use machines.</div>

            <div class="avsnitt">Do not drive or use any tools or machines, if you feel dizzy taking Forxiga.</div>

        </div>

        <div class="pil-innholdsstoffer seksjon">

            <span class="tittel">Forxiga contains lactose</span>

            <div class="avsnitt">Forxiga contains lactose (milk sugar). If you have been told by your doctor that you have an intolerance to some sugars, contact your doctor before taking this medicine.</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-bruk"> </a><div class="pil-bruk kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">How to take Forxiga</tittel></span>

        <div class="avsnitt">Always take this medicine exactly as your doctor has told you. Check with your doctor, pharmacist or nurse if you are not sure.</div>

        <span class="flytende-tittel">How much to take</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">The recommended dose is one 10 mg tablet each day.</div>

                </li><li>

                    <div class="avsnitt">Your doctor may start you on a 5 mg dose if you have a liver problem.</div>

                </li><li>

                    <div class="avsnitt">Your doctor will prescribe the strength that is right for you.</div>

                </li></ul>

        </div>

        <span class="flytende-tittel">Taking this medicine</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">Swallow the tablet whole with half a glass of water.</div>

                </li><li>

                    <div class="avsnitt">You can take your tablet with or without food.</div>

                </li><li>

                    <div class="avsnitt">You can take the tablet at any time of the day. However, try to take it at the same time each day.

This will help you to remember to take it.</div>

                </li></ul>

        </div>

        <div class="avsnitt">

Your doctor may prescribe Forxiga together with other medicine(s). Remember to take these other medicine(s) as your doctor has told you. This will help get the best results for your health.</div>

        <div class="avsnitt">Diet and exercise can help your body use its blood sugar better. If you have diabetes, it is important to stay on any diet and exercise program recommended by your doctor while taking Forxiga.</div>

        <div class="pil-overdosering seksjon">

            <span class="tittel">If you take more Forxiga than you should</span>

            <div class="avsnitt">If you take more Forxiga tablets than you should, talk to a doctor or go to a hospital immediately. Take the medicine pack with you.</div>

        </div>

        <div class="pil-glemt-dose seksjon" id="pil-glemt-dose">

            <span class="tittel">

If you forget to take Forxiga</span>

            <div class="avsnitt">What to do if you forget to take a tablet depends on how long it is until your next dose.<ul><li>

                        <div class="avsnitt">If it is 12 hours or more until your next dose, take a dose of Forxiga as soon as you remember.

Then take your next dose at the usual time.</div>

                    </li><li>

                        <div class="avsnitt">If it is less than 12 hours until your next dose, skip the missed dose. Then take your next dose at

the usual time.</div>

                    </li><li>

                        <div class="avsnitt">Do not take a double dose of Forxiga to make up for a forgotten dose.</div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-seponering seksjon" id="pil-seponering">

            <span class="tittel">If you stop taking Forxiga</span>

            <div class="avsnitt">Do not stop taking Forxiga without talking to your doctor first. If you have diabetes, your blood sugar may increase without this medicine.</div>

            <div class="avsnitt">

If you have any further questions on the use of this medicine, ask your doctor, pharmacist or nurse.t.</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-bivirkning"> </a><div class="pil-bivirkning kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Possible side effects</tittel></span>

        <div class="avsnitt">Like all medicines, this medicine can cause side effects, although not everybody gets them.</div>

        <span class="flytende-tittel">Contact a doctor or the nearest hospital straight away if you have any of the following side effects:</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>angioedema,</b>

                        seen very rarely (may affect up to 1 in 10,000 people).<br></br>

                        These are signs of angioedema:<ul><li>

                                <div class="avsnitt">swelling of the face, tongue or throat</div>

                            </li><li>

                                <div class="avsnitt">difficulties swallowing</div>

                            </li><li>

                                <div class="avsnitt">hives and breathing problems</div>

                            </li></ul>

                    </div>

                </li></ul>

        </div>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>diabetic ketoacidosis</b>

                        - this is rare in patients with type 2 diabetes (may affect up to 1 in 1,000 people).<br></br>

                        These are the signs of diabetic ketoacidosis (see also section 2 Warnings and precautions):<ul><li>

                                <div class="avsnitt">increased levels of “ketone bodies” in your urine or blood</div>

                            </li><li>

                                <div class="avsnitt">feeling sick or being sick</div>

                            </li><li>

                                <div class="avsnitt">stomach pain</div>

                            </li><li>

                                <div class="avsnitt">excessive thirst</div>

                            </li><li>

                                <div class="avsnitt">fast and deep breathing</div>

                            </li><li>

                                <div class="avsnitt">confusion</div>

                            </li><li>

                                <div class="avsnitt">unusual sleepiness or tiredness</div>

                            </li><li>

                                <div class="avsnitt">a sweet smell to your breath, a sweet or metallic taste in your mouth or a different odour to your urine or sweat</div>

                            </li><li>

                                <div class="avsnitt">rapid weight loss.</div>

                            </li></ul>

                    </div>

                </li></ul>

            This may occur regardless of blood sugar level. Your doctor may decide to temporarily or permanently stop your treatment with Forxiga.</div>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>necrotising fasciitis of the perineum

                        </b>

                        or Fournier’s gangrene, a serious soft tissue infection of the genitals or the area between the genitals and the anus, seen very rarely.</div>

                </li></ul>

        </div>

        <span class="flytende-tittel">Stop taking Forxiga and see a doctor as soon as possible if you notice any of the following serious side effects:</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>urinary tract infection,</b>

                        seen commonly (may affect up to 1 in 10 people).<br></br>

                        These are signs of a severe infection of the urinary tract:<ul><li>

                                <div class="avsnitt">fever and/or chills</div>

                            </li><li>

                                <div class="avsnitt">burning sensation when passing water (urinating)</div>

                            </li><li>

                                <div class="avsnitt"> pain in your back or side.</div>

                            </li></ul>

                    </div>

                </li></ul>

            Although uncommon, if you see blood in your urine, tell your doctor immediately..</div>

        <span class="flytende-tittel">Contact your doctor as soon as possible if you have any of the following side effects:</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>low blood sugar levels</b>

                        (hypoglycaemia), seen very commonly (may affect more than 1 in 10 people) in patients with diabetes taking this medicine with a sulphonylurea or insulin.<br></br>

                        These are the signs of low blood sugar:<ul><li>

                                <div class="avsnitt">shaking, sweating, feeling very anxious, fast heart beat</div>

                            </li><li>

                                <div class="avsnitt">eeling hungry, headache, change in vision</div>

                            </li><li>

                                <div class="avsnitt">a change in your mood or feeling confused.</div>

                            </li></ul>

                    </div>

                </li></ul>

            Your doctor will tell you how to treat low blood sugar levels and what to do if you get any of the signs above.</div>

        <span class="flytende-tittel">Other side effects when taking Forxiga:</span>

        <div class="avsnitt">Common<ul><li>

                    <div class="avsnitt">genital infection (thrush) of your penis or vagina (signs may include irritation, itching, unusual

discharge or odour)</div>

                </li><li>

                    <div class="avsnitt">back pain</div>

                </li><li>

                    <div class="avsnitt">passing more water (urine) than usual or needing to pass water more often</div>

                </li><li>

                    <div class="avsnitt">changes in the amount of cholesterol or fats in your blood (shown in tests)</div>

                </li><li>

                    <div class="avsnitt">increases in the amount of red blood cells in your blood (shown in tests)</div>

                </li><li>

                    <div class="avsnitt">decreases in creatinine renal clearance (shown in tests) in the beginning of treatment</div>

                </li><li>

                    <div class="avsnitt">dizziness</div>

                </li><li>

                    <div class="avsnitt">rash</div>

                </li></ul>

        </div>

        <div class="avsnitt">Uncommon (may affect up to 1 in 100 people)<ul><li>

                    <div class="avsnitt">loss of too much fluid from your body (dehydration, signs may include very dry or sticky

mouth, passing little or no urine or fast heartbeat)</div>

                </li><li>

                    <div class="avsnitt">thirst</div>

                </li><li>

                    <div class="avsnitt">constipation</div>

                </li><li>

                    <div class="avsnitt">awakening from sleep at night to pass urine</div>

                </li><li>

                    <div class="avsnitt">dry mouth</div>

                </li><li>

                    <div class="avsnitt">weight decreased</div>

                </li><li>

                    <div class="avsnitt">increases in creatinine (shown in laboratory blood tests) in the beginning of treatment</div>

                </li><li>

                    <div class="avsnitt">increases in urea (shown in laboratory blood tests)</div>

                </li></ul>

        </div>

        <div class="pil-melding seksjon">

            <span class="tittel">Reporting of side effects</span>

            <div class="avsnitt">If you get any side effects, talk to your doctor, pharmacist or nurse. This includes any possible side effects not listed in this leaflet. 

You can also report side effects directly via the national reporting system listed in Appendix V.<a href="https://www.mhra.gov.uk/yellowcard" target="_blank" title="www.mhra.gov.uk/yellowcard">www.mhra.gov.uk/yellowcard</a>

                By reporting side effects you can help provide more information on the safety of this medicine.

</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-oppbevaring"> </a><div class="pil-oppbevaring kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">How to store Forxiga</tittel></span>

        <div class="avsnitt">Keep this medicine out of the sight and reach of children.</div>

        <div class="avsnitt">Do not use this medicine after the expiry date, which is stated on the blister or carton after ‘EXP’. The expiry date refers to the last day of that month.</div>

        <div class="avsnitt">This medicine does not require any special storage conditions.</div>

        <div class="avsnitt">Do not throw away any medicines via wastewater or household waste. Ask your pharmacist how to throw away medicines you no longer use. These measures will help to protect the environment.</div>

    </div>

    <a class="anchorLink" id="pil-innhold-info"> </a><div class="pil-innhold-info kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Contents of the pack and other information</tittel></span>

        <div class="pil-sammensetning seksjon">

            <span class="tittel">What Forxiga contains</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">The active substance is dapagliflozin.<br></br>

                            Each Forxiga 5 mg film-coated tablet (tablet) contains dapagliflozin propanediol monohydrate equivalent to 5 mg dapagliflozin.<br></br>

                            Each Forxiga 10 mg film-coated tablet (tablet) contains dapagliflozin propanediol monohydrate equivalent to 10 mg dapagliflozin.</div>

                    </li><li>

                        <div class="avsnitt">The other ingredients are:<ul><li>

                                    <div class="avsnitt">tablet core: microcrystalline cellulose (E460i), lactose (see section 2 ‘Forxiga contains lactose’), crospovidone (E1202), silicon dioxide (E551), magnesium stearate (E470b).</div>

                                </li><li>

                                    <div class="avsnitt">film-coating: polyvinyl alcohol (E1203), titanium dioxide (E171), macrogol 3350 (E1521), talc (E553b), yellow iron oxide (E172).</div>

                                </li></ul>

                        </div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-pakningsinnhold seksjon">

            <span class="tittel">What Forxiga looks like and contents of the pack</span>

            <div class="avsnitt">Forxiga 5 mg film-coated tablets are yellow and round with diameter of 0.7 cm. They have “5” on one side and “1427” on the other side.<br></br>

                Forxiga 10 mg film-coated tablets are yellow and diamond-shaped approximately 1.1 x 0.8 cm diagonally. They have “10” on one side and “1428” on the other side.</div>

            <div class="avsnitt">Forxiga 5 mg tablets are available in aluminium blisters in pack sizes of 14, 28 or 98 film-coated tablets in non-perforated calendar blisters and 30x1 or 90x1 film-coated tablets in perforated unit dose blisters.<br></br>

                Forxiga 10 mg tablets are available in aluminium blisters in pack sizes of 14, 28 or 98 film-coated tablets in non-perforated calendar blisters and 10x1, 30x1 or 90x1 film-coated tablets in perforated unit dose blisters.</div>

            <div class="avsnitt">Not all pack sizes may be marketed in your country.</div>

        </div>

        <div class="pil-markedsføring-tilvirker seksjon">

            <span class="tittel">Marketing Authorisation Holder</span>

            <div class="avsnitt">AstraZeneca AB<br></br>

                SE-151 85 Södertälje<br></br>

                Sverige</div>

            <span class="flytende-tittel">Manufacturer</span>

            <div class="avsnitt">AstraZeneca AB<br></br>

                Gärtunavägen<br></br>

                SE-151 85

                Södertälje<br></br>

                Sverige</div>

            <div class="avsnitt">AstraZeneca GmbH<br></br>

                Tinsdaler Weg 183<br></br>

                22880

                Wedel<br></br>

                Tyskland</div>

            <div class="avsnitt">AstraZeneca UK Limited<br></br>

                Silk Road Business

                Park<br></br>

                Macclesfield<br></br>

                SK10 2NA<br></br>

                <span style="color: #212121;">Storbritannia</span>

            </div>

            <div class="avsnitt">

For any information about this medicine, please contact the local representative of the Marketing Authorisation Holder:</div>

            <div class="pil-lokal-representant seksjon">

                <div class="avsnitt">

                    <b>Norge</b>

                    <br></br>

                    AstraZeneca AS<br></br>

                    Tlf: +47 21

                    00 64 00</div>

            </div>

        </div>

        <div class="pil-sist-endret seksjon tittel">This leaflet was last revised in 15.11.2021</div>

        <div class="pil-andre-kilder seksjon">

            <span class="tittel">

Other sources of information</span>

            <div class="avsnitt">Detailed information on this medicine is available on the European Medicines Agency web site:<a href="http://www.ema.europa.eu/" target="_blank" title="&#xA;                    &#xA;                        http://www.ema.europa.eu,&#xA;                    &#xA;                ">

                    

                        http://www.ema.europa.eu,

                    

                </a>

                 og på nettstedet

                til <a href="http://www.felleskatalogen.no/" target="_blank" title="&#xA;                    &#xA;                        www.felleskatalogen.no&#xA;                    &#xA;                ">

                    

                        www.felleskatalogen.no

                    

                </a>

                <a href="http://www.felleskatalogen.no/" target="_blank" title="&#xA;                    .&#xA;                ">

                    .

                </a>

            </div>

        </div>

    </div>

</div><div id="begrep"></div></body></html>

`;

const forxiga_fr = `
<?xml version="1.0" encoding="UTF-8"?>

<!DOCTYPE html

  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "xhtml1-transitional.dtd">

<html><head><title>Forxiga AstraZeneca - Felleskatalogen Pasientutgave</title><meta name="description" content="&#xA;         Qu’est-ce que Forxiga et dans quel cas est-il utilisé &#xA;         Qu’est-ce que Forxiga &#xA;         Forxiga contient une substance active appelée"></meta><meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta><meta name="author" content="Felleskatalogen"></meta><meta name="keywords" content="Forxiga tab 10 mg Forxiga tab 5 mg"></meta><meta http-equiv="X-UA-Compatible" content="IE=edge"></meta><meta name="date" content="La dernière date à laquelle cette notice a été révisée est 15.11.2021"></meta><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_content.css" type="text/css"></link><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_fk.css" type="text/css"></link><meta name="varenr" content="502904,462070,381510,126390"></meta><meta name="ean" content="7046265029045,7046264620700,7046263815107,7046261263900,5000456069458,5000456069281,5000456069250"></meta><meta name="lmrlopenr" content="2017957,2017957,2017956,2017956"></meta>

        

        

        

        

        

        

        

        

        

        

        

        

        

        

        

        <meta name="preparatnavn" content="Forxiga"></meta>

        <meta name="kortnummer" content="1111"></meta>

    <meta name="navnformstyrke" content="Forxiga tab 5 mg,Forxiga tab 10 mg"></meta></head><body>
    

    <!-- HENRIK HAR KOMMENTERT UT DETTE    

    <div class="anchorindexbar">

    <a class="anchorindex" href="#pil-indikasjon" title="Bruksområder">Bruksområder</a> | 

    <a class="anchorindex" href="#pil-forsiktighet" title="Forsiktighetsregler">Forsiktighetsregler</a> | 

    <a class="anchorindex" href="#pil-bruk" title="Bruksmåte">Bruksmåte</a> | 

    <a class="anchorindex" href="#pil-bivirkning" title="Bivirkninger">Bivirkninger</a> | 

    <a class="anchorindex" href="#pil-oppbevaring" title="Oppbevaring">Oppbevaring</a> | 

    <a class="anchorindex" href="#pil-innhold-info" title="Ytterligere informasjon">Ytterligere informasjon</a>

<br></br><br></br></div>

-->



<div class="pil">

    

    

    <h1 class="tittel">Notice : Information du patient</h1>

    

    

    <h2 class="pil-navnformstyrke">Forxiga 5 mg, comprimés pelliculés</h2>

    <h2 class="pil-navnformstyrke">Forxiga 10 mg, comprimés pelliculés</h2>

    <h3 class="pil-substans">dapagliflozine</h3>

    <div class="pil-innledning">

        <div class="pil-regler seksjon">

            <span class="tittel">Veuillez lire attentivement cette notice avant de prendre ce médicament car elle contient des informations importantes pour vous.</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">Gardez cette notice. Vous pourriez avoir besoin de la relire.</div>

                    </li><li>

                        <div class="avsnitt">Si vous avez d’autres questions, interrogez votre médecin, votre pharmacien ou votre infirmier/ère.</div>

                    </li><li>

                        <div class="avsnitt">Ce médicament vous a été personnellement prescrit. Ne le donnez pas à d’autres personnes. Il

pourrait leur être nocif, même si les signes de leur maladie sont identiques aux vôtres.</div>

                    </li><li>

                        <div class="avsnitt">Si vous ressentez un quelconque effet indésirable, parlez-en à votre médecin ou votre pharmacien.

Ceci s’applique aussi à tout effet indésirable qui ne serait pas mentionné dans cette notice. Voir rubrique 4.</div>

                    </li></ul>

            </div>

        </div>

        <div class="toc generated seksjon"><span class="tittel">I dette pakningsvedlegget finner du informasjon om:</span><ol><li>Qu’est-ce que Forxiga et dans quel cas est-il utilisé</li><li>Quelles sont les informations à connaître avant de prendre Forxiga</li><li>Comment prendre Forxiga</li><li>Effets indésirables éventuels</li><li>Comment conserver Forxiga</li><li>Contenu de l’emballage et autres informations</li></ol></div>

    </div>

    <a class="anchorLink" id="pil-indikasjon"> </a><div class="pil-indikasjon kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Qu’est-ce que Forxiga et dans quel cas est-il utilisé</tittel></span>

        <span class="flytende-tittel">Qu’est-ce que Forxiga</span>

        <div class="avsnitt">Forxiga contient une substance active appelée dapagliflozine. Il appartient à un groupe de médicaments appelés« inhibiteurs du co-transporteur de sodium-glucose de type 2 (SGTL2) ». Ils agissent en bloquant la protéine SGLT2 dans les reins. En bloquant cette protéine, le sucre sanguin (glucose), le sel (sodium) et l’eau sont éliminés de l’organisme par les urines.</div>

        <span class="flytende-tittel">Dans quel cas Forxiga est-il utilisé</span>

        <div class="avsnitt">Forxiga est utilisé pour traiter :</div>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>Le diabète de type 2 :</b>

                        <ul><li>

                                <div class="avsnitt">chez les adultes et enfants âgés de 10 ans et plus.</div>

                            </li><li>

                                <div class="avsnitt">si un régime et de l’exercice ne suffisent pas à contrôler votre diabète de type 2.</div>

                            </li><li>

                                <div class="avsnitt">Forxiga peut être utilisé seul ou en complément d’autres médicaments pour traiter votre

diabète.</div>

                            </li><li>

                                <div class="avsnitt">Il est important de continuer à suivre les conseils que votre médecin, votre infirmier/ère ou

votre pharmacien vous a donnés en termes de régime alimentaire et d’exercice.</div>

                            </li></ul>

                    </div>

                </li></ul>

        </div>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>L’insuffisance cardiaque :</b>

                        <ul><li>

                                <div class="avsnitt">chez les adultes (âgés de 18 ans et plus) présentant des symptômes dus à un mauvais

fonctionnement de la pompe cardiaque.</div>

                            </li></ul>

                    </div>

                </li></ul>

        </div>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>La maladie rénale chronique :</b>

                        <ul><li>

                                <div class="avsnitt">chez les adultes dont la fonction rénale est réduite.</div>

                            </li></ul>

                    </div>

                </li></ul>

        </div>

        <span class="flytende-tittel">Qu’est-ce que le diabète de type 2 et en quoi Forxiga est-il utile ?</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">Dans le diabète de type 2, votre organisme ne fabrique pas assez d’insuline ou n’est pas en mesure d’utiliser correctement l’insuline qu’il produit. &#x2028;Ceci induit un niveau élevé de sucre dans votre sang. Cela peut entraîner des problèmes graves comme une maladie cardiaque ou rénale, une cécité et

une mauvaise circulation du sang dans les bras et les jambes.</div>

                </li><li>

                    <div class="avsnitt">Forxiga agit en éliminant l’excès de sucre de votre organisme. Il peut également aider à prévenir

une maladie cardiaque.</div>

                </li></ul>

        </div>

        <span class="flytende-tittel">Qu’est-ce que l’insuffisance cardiaque et en quoi Forxiga est-il utile ?</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">Ce type d’insuffisance cardiaque se produit lorsque le cœur est faible et ne parvient pas à pomper suffisamment de sang pour l’amener aux poumons et au reste du corps. Cela peut entraîner des problèmes médicaux graves et la nécessité de soins hospitaliers.</div>

                </li><li>

                    <div class="avsnitt">Les symptômes les plus fréquents d’insuffisance cardiaque sont une sensation d’essoufflement, une sensation de fatigue ou de fatigue extrême permanente et un gonflement des chevilles.</div>

                </li><li>

                    <div class="avsnitt">Forxiga aide à protéger votre cœur contre un affaiblissement supplémentaire et améliore vos symptômes. Il peut diminuer la nécessité d’une hospitalisation et peut aider certains patients à vivre plus longtemps.</div>

                </li></ul>

        </div>

        <span class="flytende-tittel">Qu’est-ce que la maladie rénale chronique et en quoi Forxiga est-il utile ?</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">Lorsque vous avez une maladie rénale chronique, vos reins perdent progressivement leur fonction. Cela signifie qu’ils ne vont plus pouvoir nettoyer et filtrer votre sang comme ils le devraient. La perte de fonction rénale peut entraîner des problèmes médicaux graves et la nécessité de soins hospitaliers.</div>

                </li><li>

                    <div class="avsnitt">Forxiga aide à protéger vos reins contre la perte de leur fonction. Cela peut aider certains patients à vivre plus longtemps.</div>

                </li></ul>

        </div>

    </div>

    <a class="anchorLink" id="pil-forsiktighet"> </a><div class="pil-forsiktighet kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Quelles sont les informations à connaître avant de prendre Forxiga</tittel></span>

        <div class="pil-kontraindikasjon seksjon">

            <span class="tittel">Ne prenez jamais Forxiga :</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">si vous êtes allergique à la dapagliflozine ou à l’un des autres composants contenus dans ce médicament (mentionnés dans la rubrique 6).</div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-advarsel seksjon">

            <span class="tittel">Avertissements et précautions</span>

            <span class="flytende-tittel">Contactez immédiatement un médecin ou l’hôpital le plus proche :</span>

            <div class="avsnitt">Acidocétose diabétique<ul><li>

                        <div class="avsnitt">Si vous avez un diabète et si vous développez des nausées ou des vomissements, une douleur au niveau de l’estomac, une soif intense, une respiration rapide et profonde, une confusion, une somnolence ou une fatigue inhabituelle, une haleine sucrée, un goût sucré ou métallique dans la bouche, une modification de l’odeur de vos urines ou de votre transpiration ou une perte de poids rapide.</div>

                    </li><li>

                        <div class="avsnitt">Les symptômes ci-dessus peuvent être un signe « d’acidocétose diabétique », un problème rare mais grave mettant parfois en jeu le pronostic vital, que vous pouvez présenter avec le diabète si le taux de « corps cétoniques » dans vos urines ou votre sang a augmenté dans les examens.</div>

                    </li><li>

                        <div class="avsnitt">Le risque de développer une acidocétose diabétique peut être augmenté lors de jeûnes prolongés, une consommation excessive d’alcool, une déshydratation, des réductions soudaines des doses d’insuline ou un besoin plus élevé en insuline en raison d’une intervention chirurgicale majeure ou d’une maladie grave.</div>

                    </li><li>

                        <div class="avsnitt">Pendant votre traitement par Forxiga, une acidocétose diabétique peut survenir même si votre glycémie est normale.</div>

                    </li></ul>

                En cas de suspicion d’une acidocétose diabétique, contactez immédiatement un médecin ou l’hôpital le plus proche et ne prenez pas ce médicament.</div>

            <div class="avsnitt">Fasciite nécrosante du périnée :<ul><li>

                        <div class="avsnitt">Adressez-vous immédiatement à votre médecin si vous développez une association de symptômes tels qu’une douleur, une sensibilité, une rougeur ou un gonflement au niveau de vos parties génitales ou de la zone qui s’étend de vos parties génitales à votre anus, accompagnés de fièvre ou d’une sensation générale de malaise. &#x2028;Ces symptômes peuvent indiquer la survenue d’une infection rare mais grave ou mettant même en jeu le pronostic vital des patients, appelée «fasciite nécrosante du périnée» ou «gangrène de Fournier», qui détruit le tissu sous-cutané. La gangrène de Fournier doit faire l’objet d’un traitement immédiat.</div>

                    </li></ul>

            </div>

            <span class="flytende-tittel">Adressez-vous à votre médecin, à votre pharmacien ou à votre infirmier/ère avant de prendre Forxiga :</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">si vous avez un « diabète de type 1 ». Ce type de diabète se déclare généralement quand vous êtes

jeune et que votre organisme ne produit pas d’insuline.</div>

                    </li><li>

                        <div class="avsnitt">si vous avez un diabète et que vous avez des troubles rénaux. Votre médecin peut vous demander de

prendre un traitement supplémentaire ou de changer de traitement pour contrôler votre glycémie.</div>

                    </li><li>

                        <div class="avsnitt">si vous avez des troubles hépatiques. Votre médecin peut commencer à une faible dose.</div>

                    </li><li>

                        <div class="avsnitt">si vous prenez des médicaments pour baisser la tension artérielle (anti-hypertenseurs) et avez un

antécédent de tension artérielle basse (hypotension). Vous trouverez plus d’informations plus bas

dans la rubrique Autres médicaments et Forxiga.</div>

                    </li><li>

                        <div class="avsnitt">si vous avez des taux de sucre très élevés dans votre sang qui pourraient vous déshydrater (perte

excessive de liquide corporel). Les signes éventuels de déshydratation sont énumérés à la rubrique 4.

Prévenez votre médecin avant de débuter Forxiga si vous avez un de ces signes.</div>

                    </li><li>

                        <div class="avsnitt">si vous avez ou présentez des nausées (envie de vomir), des vomissements, de la fièvre ou si vous n’êtes pas en état de boire ou manger. Ces situations peuvent causer une déshydratation. Votre médecin peut vous demander d’arrêter de prendre Forxiga jusqu’à ce que votre état permette d’éviter une déshydratation.</div>

                    </li><li>

                        <div class="avsnitt">si vous avez régulièrement des infections urinaires.</div>

                    </li></ul>

            </div>

            <div class="avsnitt">Si l’une des situations mentionnées ci-dessus s’applique à votre situation (ou en cas de doute), 

adressez-vous à votre médecin, votre infirmier/ère ou votre pharmacien avant de prendre Forxiga.</div>

            <span class="flytende-tittel">Diabète et soins des pieds</span>

            <div class="avsnitt">Si vous êtes diabétique, il est important de prendre soin régulièrement de vos pieds et de suivre tout autre conseil concernant les soins des pieds donné par votre professionnel de santé.</div>

            <span class="flytende-tittel">Glucose urinaire</span>

            <div class="avsnitt">En raison du mode d’action de Forxiga, le test de sucre dans vos urines sera positif en étant sous ce médicament.</div>

        </div>

        <div class="pil-barn-unge seksjon">

            <span class="tittel">Enfants et adolescents</span>

            <div class="avsnitt">Forxiga peut être utilisé chez les enfants âgés de 10 ans et plus pour le traitement du diabète de type 2. Aucune donnée n’est disponible chez les enfants de moins de 10 ans.</div>

            <div class="avsnitt">Forxiga n’est pas recommandé chez les enfants et adolescents de moins de 18 ans pour le traitement de l’insuffisance cardiaque ou pour le traitement de la maladie rénale chronique car il n’a pas fait l’objet d’études dans cette tranche d’âge.</div>

        </div>

        <div class="pil-interaksjon seksjon">

            <span class="tittel">Autres médicaments et Forxiga</span>

            <div class="avsnitt">Informez votre médecin, votre pharmacien ou votre infirmier/ère si vous prenez, avez récemment pris ou pourriez prendre tout autre médicament.<br></br>

                Avertissez votre médecin dans les cas suivants :<ul><li>

                        <div class="avsnitt">si vous prenez un médicament utilisé pour éliminer l’eau de votre organisme (diurétique).</div>

                    </li><li>

                        <div class="avsnitt">si vous prenez d’autres traitements qui font baisser la quantité de sucre dans votre sang comme l’insuline ou un médicament de type « sulfamide hypoglycémiant». Votre médecin peut envisager de réduire la dose de ces autres médicaments afin de vous éviter de tomber en hypoglycémie (faible taux de sucre dans votre sang).

</div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-graviditet-amming-fertilitet seksjon">

            <span class="tittel">Grossesse et allaitement</span>

            <div class="avsnitt">Si vous êtes enceinte ou que vous allaitez, si vous pensez que vous êtes peut-être enceinte ou planifiez une grossesse, demandez conseil à votre médecin ou votre pharmacien avant de prendre ce médicament.&#x2028;Vous devez arrêter de prendre ce médicament si vous êtes enceinte puisqu’il n’est pas recommandé durant les deuxième et troisième trimestres de la grossesse. Adressez-vous à votre médecin pour connaître le meilleur moyen de contrôler votre glycémie pendant votre grossesse.</div>

            <div class="avsnitt">Si vous souhaitez allaiter, prévenez votre médecin que vous prenez ce médicament. On ignore si ce médicament est diffusé dans le lait maternel.</div>

        </div>

        <div class="pil-bil-maskiner seksjon">

            <span class="tittel">Conduite de véhicules et utilisation de machines</span>

            <div class="avsnitt">Forxiga n’a pas ou a une influence négligeable sur l’aptitude à conduire et à utiliser des machines.</div>

            <div class="avsnitt">La prise de ce médicament avec d’autres médicaments appelés sulfamide ou avec l’insuline peut entraîner un taux de sucre bas (hypoglycémie) qui peut provoquer des symptômes comme des tremblements, des suées et des troubles de la vision et peut affecter votre aptitude à conduire et à utiliser des machines.</div>

            <div class="avsnitt">Ne conduisez pas ou n’utilisez pas de véhicule ou machine si vous avez des étourdissements en prenant Forxiga.a.</div>

        </div>

        <div class="pil-innholdsstoffer seksjon">

            <span class="tittel">Forxiga contient du lactose</span>

            <div class="avsnitt">Forxiga contient du lactose (sucre du lait). Si votre médecin vous a dit que vous étiez intolérant à certains sucres, adressez-vous à lui avant de prendre ce médicament.</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-bruk"> </a><div class="pil-bruk kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Comment prendre Forxiga</tittel></span>

        <div class="avsnitt">Veillez à toujours prendre ce médicament en suivant exactement les indications de votre médecin. Vérifiez auprès de votre médecin, votre pharmacien ou votre infirmier/ère en cas de doute.</div>

        <span class="flytende-tittel">Quelle dose prendre</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">La dose recommandée est de un comprimé de 10 mg par jour.</div>

                </li><li>

                    <div class="avsnitt">Votre médecin peut commencer par une dose de 5 mg si vous avez un problème hépatique.</div>

                </li><li>

                    <div class="avsnitt">Votre médecin vous prescrira le dosage qui vous convient.</div>

                </li></ul>

        </div>

        <span class="flytende-tittel">Prise du médicament</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">Avalez le comprimé entier avec un demi-verre d’eau.</div>

                </li><li>

                    <div class="avsnitt">Vous pouvez le prendre indifféremment au cours ou en dehors des repas.</div>

                </li><li>

                    <div class="avsnitt">Vous pouvez prendre le comprimé à n’importe quel moment de la journée. Il est toutefois conseillé

de le prendre à heure fixe chaque jour. Vous éviterez ainsi de l’oublier.</div>

                </li></ul>

        </div>

        <div class="avsnitt">Votre médecin peut vous prescrire Forxiga en complément d’autre(s) traitement(s). N’oubliez pas de prendre cet(ces) autre(s) traitement(s) prescrit(s) par votre médecin. Vous aurez ainsi de meilleures chances de voir votre état de santé s’améliorer.</div>

        <div class="avsnitt">Faire un régime et de l’exercice peut aider votre organisme à mieux utiliser le sucre du sang. Si vous êtes diabétique, il est important de suivre le régime et le programme d’exercices recommandés par votre médecin pendant votre traitement par Forxiga.</div>

        <div class="pil-overdosering seksjon">

            <span class="tittel">Si vous avez pris plus de Forxiga que vous n’auriez dû</span>

            <div class="avsnitt">Si vous avez pris plus de comprimés de Forxiga que vous n’auriez dû, consultez un médecin ou rendez-vous à l’hôpital immédiatement. Emmenez la boîte de médicaments avec vous.</div>

        </div>

        <div class="pil-glemt-dose seksjon" id="pil-glemt-dose">

            <span class="tittel">

Si vous oubliez de prendre Forxiga</span>

            <div class="avsnitt">La marche à suivre en cas d’oubli d’un comprimé dépend du délai d’attente avant la prochaine dose.<ul><li>

                        <div class="avsnitt">S’il reste 12 heures ou plus avant la prochaine dose, prenez une dose de Forxiga dès que vous

constatez l’oubli. Prenez la dose suivante à l’heure habituelle.</div>

                    </li><li>

                        <div class="avsnitt">S’il reste moins de 12 heures avant la prochaine dose, ne prenez pas la dose oubliée. Prenez la dose

suivante à l’heure habituelle.</div>

                    </li><li>

                        <div class="avsnitt">Ne prenez pas de dose double de Forxiga pour compenser la dose que vous avez oubliée de prendre.</div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-seponering seksjon" id="pil-seponering">

            <span class="tittel">Si vous arrêtez de prendre Forxiga</span>

            <div class="avsnitt">N’interrompez pas le traitement par Forxiga sans en avoir parlé au préalable à votre médecin. Si vous êtes diabétique, votre glycémie pourrait augmenter sans votre médicament.</div>

            <div class="avsnitt">Si vous avez d’autres questions sur l’utilisation de ce médicament, demandez plus d’informations à votre médecin, à votre pharmacien ou à votre infirmier/ère.</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-bivirkning"> </a><div class="pil-bivirkning kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Effets indésirables éventuels</tittel></span>

        <div class="avsnitt">Comme tous les médicaments, ce médicament peut provoquer des effets indésirables, mais ils ne surviennent pas systématiquement chez tout le monde.</div>

        <span class="flytende-tittel">Contactez immédiatement un médecin ou l’hôpital le plus proche si vous remarquez l’un des effets indésirables suivants :

</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>angio-œdème,</b>

                        vu très rarement (peut affecter jusqu’à 1 personne sur 10 000).<br></br>

                        Les signes cliniques de l’angio-œdème sont :<ul><li>

                                <div class="avsnitt">gonflement du visage, de la langue ou de la gorge</div>

                            </li><li>

                                <div class="avsnitt">difficultés à avaler</div>

                            </li><li>

                                <div class="avsnitt">urticaire et difficultés à respirer</div>

                            </li></ul>

                    </div>

                </li></ul>

        </div>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>acidocétose diabétique : </b>

                        elle est rare chez les patients atteints de diabète de type 2 (peut affecter

jusqu’à 1 personne sur 1000).<br></br>

                        Les signes d’acidocétose diabétique sont (voir également la rubrique 2 Avertissements et précautions) :<ul><li>

                                <div class="avsnitt">augmentation du taux de « corps cétoniques » dans vos urines ou votre sang</div>

                            </li><li>

                                <div class="avsnitt">sensation de nausée ou vomissement</div>

                            </li><li>

                                <div class="avsnitt">douleur au niveau de l’estomac</div>

                            </li><li>

                                <div class="avsnitt">soif intense</div>

                            </li><li>

                                <div class="avsnitt">respiration rapide et profonde</div>

                            </li><li>

                                <div class="avsnitt">confusion</div>

                            </li><li>

                                <div class="avsnitt">somnolence ou fatigue inhabituelle</div>

                            </li><li>

                                <div class="avsnitt">une odeur sucrée de votre haleine, un goût sucré ou un goût métallique dans votre bouche ou une

odeur anormale de votre urine ou de votre transpiration</div>

                            </li><li>

                                <div class="avsnitt">perte rapide de poids.</div>

                            </li></ul>

                    </div>

                </li></ul>

            Ceci peut survenir indépendamment du taux de sucre dans votre sang. Le médecin peut décider d’arrêter temporairement ou définitivement votre traitement par Forxiga.</div>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>fasciite nécrosante du périnée

                        </b>

                        ou gangrène de Fournier, une grave infection des tissus mous des parties génitales ou de la zone qui s’étend des parties génitales à l’anus, observée très rarement.

</div>

                </li></ul>

        </div>

        <span class="flytende-tittel">Arrêtez de prendre Forxiga et consultez un médecin dès que possible si vous remarquez l’un des effets indésirables graves suivants :</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>infections urinaires,</b>

                        vues fréquemment (peuvent affecter jusqu’à 1 personne sur 10).<br></br>

                        Voici les signes d’une infection urinaire grave :<ul><li>

                                <div class="avsnitt">fièvre et/ou frissons,</div>

                            </li><li>

                                <div class="avsnitt">sensation de brûlure à la miction (lorsque vous urinez),</div>

                            </li><li>

                                <div class="avsnitt">douleurs au dos ou aux côtés.</div>

                            </li></ul>

                    </div>

                </li></ul>

            Bien qu’il s’agisse d’un phénomène peu fréquent, si vous observez du sang dans vos urines, avertissez immédiatement votre médecin.</div>

        <span class="flytende-tittel">otre médecin dès que possible si vous remarquez l’un des effets indésirables suivants :</span>

        <div class="avsnitt">

            <ul><li>

                    <div class="avsnitt">

                        <b>faible taux de sucre dans le sang</b>

                         (hypoglycémie) constaté très fréquemment (peut affecter plus d’1 personne sur 10) chez les patients diabétiques, si ce médicament est pris en complément d’un sulfamide hypoglycémiant ou d’insuline<br></br>

                        Voici les signes d’un faible taux de sucre dans le sang :<ul><li>

                                <div class="avsnitt">tremblements, transpiration, très grande anxiété, rythme cardiaque rapide,</div>

                            </li><li>

                                <div class="avsnitt">sensation de faim, maux de tête, modification de la vision,</div>

                            </li><li>

                                <div class="avsnitt">changement d’humeur ou sentiment de confusion.</div>

                            </li></ul>

                    </div>

                </li></ul>

            Votre médecin vous indiquera comment traiter l’hypoglycémie et comment réagir si vous ressentez l’un des signes mentionnés ci-dessus.</div>

        <span class="flytende-tittel">Autres effets indésirables dus à la prise de Forxiga :</span>

        <div class="avsnitt">Fréquents<ul><li>

                    <div class="avsnitt">infection génitale (candidose) du pénis ou du vagin (les signes peuvent inclure irritation,

démangeaisons, sécrétion ou odeur inhabituelles),</div>

                </li><li>

                    <div class="avsnitt">douleurs dorsales,</div>

                </li><li>

                    <div class="avsnitt">miction plus importante que d’habitude (plus d’urine) ou besoin plus fréquent d’uriner,</div>

                </li><li>

                    <div class="avsnitt">modification du taux de cholestérol ou de graisses dans votre sang (visible aux analyses),</div>

                </li><li>

                    <div class="avsnitt">augmentation du nombre de globules rouges dans votre sang (visible aux analyses),</div>

                </li><li>

                    <div class="avsnitt">diminution de la clairance rénale de la créatinine (visible aux analyses) au début du traitement,</div>

                </li><li>

                    <div class="avsnitt">sensations vertigineuses,</div>

                </li><li>

                    <div class="avsnitt">éruption cutanée (rash).</div>

                </li></ul>

        </div>

        <div class="avsnitt">

Peu fréquents (peut affecter jusqu’à 1 personne sur 100)<ul><li>

                    <div class="avsnitt">pertes hydriques excessives (les signes de déshydratation peuvent inclure bouche très sèche ou

collante, difficulté ou impossibilité d’uriner ou rythme cardiaque rapide),</div>

                </li><li>

                    <div class="avsnitt">soif,</div>

                </li><li>

                    <div class="avsnitt">constipation,</div>

                </li><li>

                    <div class="avsnitt">réveils nocturnes pour uriner,</div>

                </li><li>

                    <div class="avsnitt">sécheresse de la bouche,</div>

                </li><li>

                    <div class="avsnitt">perte de poids,</div>

                </li><li>

                    <div class="avsnitt">augmentation de la créatinine (visible au laboratoire par analyse du sang) au début du traitement</div>

                </li><li>

                    <div class="avsnitt">augmentation de l’urée (visible au laboratoire par analyse du sang).</div>

                </li></ul>

        </div>

        <div class="pil-melding seksjon">

            <span class="tittel">Déclaration des effets secondaires</span>

            <div class="avsnitt">Si vous ressentez un quelconque effet indésirable, parlez-en à votre médecin, votre pharmacien ou à votre infirmier/ère. Ceci s’applique aussi à tout effet indésirable qui ne serait pas mentionné dans cette notice. Vous pouvez également déclarer les effets indésirables directement via le système national de déclaration décrit en Annexe V. <a href="http://www.ema.europa.eu" target="_blank" title="www.ema.europa.eu.">www.ema.europa.eu.</a>

                En signalant les effets indésirables, vous contribuez à fournir davantage d’informations sur la sécurité du médicament.</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-oppbevaring"> </a><div class="pil-oppbevaring kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Comment conserver Forxiga</tittel></span>

        <div class="avsnitt">Tenir ce médicament hors de la vue et de la portée des enfants.</div>

        <div class="avsnitt">BN’utilisez pas ce médicament après la date de péremption indiquée sur la plaquette ou l’emballage après la mention EXP. La date de péremption fait référence au dernier jour de ce mois.</div>

        <div class="avsnitt">Ce médicament ne nécessite pas de précautions particulières de conservation.</div>

        <div class="avsnitt">Ne jetez aucun médicament au tout-à-l’égout ou avec les ordures ménagères. Demandez à votre pharmacien d’éliminer les médicaments que vous n’utilisez plus. Ces mesures contribueront à protéger l’environnement.</div>

    </div>

    <a class="anchorLink" id="pil-innhold-info"> </a><div class="pil-innhold-info kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">Contenu de l’emballage et autres informations</tittel></span>

        <div class="pil-sammensetning seksjon">

            <span class="tittel">Ce que contient Forxiga</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">La substance active est la dapagliflozine<br></br>

                            Chaque comprimé pelliculé de Forxiga 5 mg contient du propylène glycol monohydraté de dapagliflozine équivalent à 5 mg de dapagliflozine.<br></br>

                            Chaque comprimé pelliculé de Forxiga 10 mg contient du propylène glycol monohydraté de dapagliflozine équivalent à 10 mg de dapagliflozine.</div>

                    </li><li>

                        <div class="avsnitt">Les autres composants sont :<ul><li>

                                    <div class="avsnitt">noyau du comprimé : cellulose microcristalline (E460i), lactose (voir rubrique 2 intitulée « Forxiga contient du lactose »), crospovidone (E1202), dioxyde de silicium (E551), stéarate de magnésium (E470b),</div>

                                </li><li>

                                    <div class="avsnitt">pelliculage : alcool polyvinylique (E1203), dioxyde de titane (E171), macrogol 3350 (E1521), talc (E553b), oxyde de fer jaune (E172).</div>

                                </li></ul>

                        </div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-pakningsinnhold seksjon">

            <span class="tittel">Qu’est-ce que Forxiga et contenu de l’emballage extérieur</span>

            <div class="avsnitt">Les comprimés pelliculés de Forxiga 5 mg sont jaunes et ronds avec un diamètre de 0,7 cm. Ils portent l’inscription « 5 » gravée sur une face et « 1427 » sur l’autre.<br></br>

                Les comprimés pelliculés de Forxiga 10 mg sont jaunes et en forme de losange approximativement 1,1 x 0,8 cm de diagonale. Ils portent l’inscription « 10 » gravée sur une face et « 1428 » sur l’autre.</div>

            <div class="avsnitt">Les comprimés de Forxiga 5 mg sont disponibles en plaquettes aluminium calendaires non perforées de 14, 28 ou 98 comprimés pelliculés et en plaquettes aluminium unitaires perforées de 30 x 1 ou

90 x 1 comprimés pelliculés.<br></br>

                Les comprimés de Forxiga 10 mg sont disponibles en plaquettes aluminium calendaires non perforées de 14, 28 ou 98 comprimés pelliculés et en plaquettes aluminium unitaires perforées de 10 x 1, 30 x 1 ou 90 x 1 comprimés pelliculés.</div>

            <div class="avsnitt">Toutes les présentations peuvent ne pas être commercialisées dans votre pays.</div>

        </div>

        <div class="pil-markedsføring-tilvirker seksjon">

            <span class="tittel">Titulaire de l’Autorisation de mise sur le marché</span>

            <div class="avsnitt">AstraZeneca AB<br></br>

                SE-151 85 Södertälje<br></br>

                Sverige</div>

            <span class="flytende-tittel">Fabricant</span>

            <div class="avsnitt">AstraZeneca AB<br></br>

                Gärtunavägen<br></br>

                SE-151 85

                Södertälje<br></br>

                Sverige</div>

            <div class="avsnitt">AstraZeneca GmbH<br></br>

                Tinsdaler Weg 183<br></br>

                22880

                Wedel<br></br>

                Tyskland</div>

            <div class="avsnitt">AstraZeneca UK Limited<br></br>

                Silk Road Business

                Park<br></br>

                Macclesfield<br></br>

                SK10 2NA<br></br>

                <span style="color: #212121;">Storbritannia</span>

            </div>

            <div class="avsnitt">Pour toute information complémentaire concernant ce médicament, veuillez prendre contact avec le représentant local du titulaire de l’autorisation de mise sur le marché :</div>

            <div class="pil-lokal-representant seksjon">

                <div class="avsnitt">

                    <b>Norge</b>

                    <br></br>

                    AstraZeneca AS<br></br>

                    Tlf: +47 21

                    00 64 00</div>

            </div>

        </div>

        <div class="pil-sist-endret seksjon tittel">La dernière date à laquelle cette notice a été révisée est 15.11.2021</div>

        <div class="pil-andre-kilder seksjon">

            <span class="tittel">Autres sources d’informations</span>

            <div class="avsnitt">Des informations détaillées sur ce médicament sont disponibles sur le site internet de l’Agence européenne des médicaments<a href="http://www.ema.europa.eu/" target="_blank" title="&#xA;                    &#xA;                        http://www.ema.europa.eu,&#xA;                    &#xA;                ">

                    

                        http://www.ema.europa.eu,

                    

                </a>

                 og på nettstedet

                til <a href="http://www.felleskatalogen.no/" target="_blank" title="&#xA;                    &#xA;                        www.felleskatalogen.no&#xA;                    &#xA;                ">

                    

                        www.felleskatalogen.no

                    

                </a>

                <a href="http://www.felleskatalogen.no/" target="_blank" title="&#xA;                    .&#xA;                ">

                    .

                </a>

            </div>

        </div>

    </div>

</div><div id="begrep"></div></body></html>
`;

const ifirmacombi_en = `
<?xml version="1.0" encoding="UTF-8"?>

<!DOCTYPE html

  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "xhtml1-transitional.dtd">

<html><head><title>Ifirmacombi KRKA - Felleskatalogen Pasientutgave</title><meta name="description" content="&#xA;         1. What Ifirmacombi is and what it is used for &#xA;         Ifirmacombi er en kombinasjon av to virkestoffer, irbesartan og&#xA;           "></meta><meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta><meta name="author" content="Felleskatalogen"></meta><meta name="keywords" content="Ifirmacombi tab 150 mg/12,5 mg Ifirmacombi tab 300 mg/12,5 mg Ifirmacombi tab 300 mg/25 mg"></meta><meta http-equiv="X-UA-Compatible" content="IE=edge"></meta><meta name="date" content="This leaflet was last revised in 08.02.2022"></meta><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_content.css" type="text/css"></link><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_fk.css" type="text/css"></link><meta name="varenr" content="381211,101684,029502"></meta><meta name="ean" content="7046263812113,7046261016841,7046260295025"></meta><meta name="lmrlopenr" content="2014602,2014601,2014600"></meta>

        

        

        

        

        

        

        

        

        

        <meta name="preparatnavn" content="Ifirmacombi"></meta>

        <meta name="kortnummer" content="1111"></meta>

    <meta name="navnformstyrke" content="Ifirmacombi tab 300 mg/25 mg,Ifirmacombi tab 300 mg/12,5 mg,Ifirmacombi tab 150 mg/12,5 mg"></meta></head><body>
    
    
<!-- HENRIK HAR KOMMENTERT UT DETTE    
    
    
    
    <div class="anchorindexbar">

    <a class="anchorindex" href="#pil-indikasjon" title="Bruksområder">Bruksområder</a> | 

    <a class="anchorindex" href="#pil-forsiktighet" title="Forsiktighetsregler">Forsiktighetsregler</a> | 

    <a class="anchorindex" href="#pil-bruk" title="Bruksmåte">Bruksmåte</a> | 

    <a class="anchorindex" href="#pil-bivirkning" title="Bivirkninger">Bivirkninger</a> | 

    <a class="anchorindex" href="#pil-oppbevaring" title="Oppbevaring">Oppbevaring</a> | 

    <a class="anchorindex" href="#pil-innhold-info" title="Ytterligere informasjon">Ytterligere informasjon</a>


<br></br><br></br></div>
-->

<div class="pil">

    

    

    <h1 class="tittel">Package leaflet: Information for the patient</h1>

    

    

    

    <h2 class="pil-navnformstyrke">Ifirmacombi 150 mg/12.5 mg film-coated tablets</h2>

    <h2 class="pil-navnformstyrke">Ifirmacombi 300 mg/12.5 mg film-coated tablets</h2>

    <h2 class="pil-navnformstyrke">Ifirmacombi 300 mg/25 mg film-coated tablets</h2>

    <h3 class="pil-substans">irbesartan/hydroklortiazide</h3>

    <div class="pil-innledning">

        <div class="pil-regler seksjon">

            <span class="tittel">Read all of this leaflet carefully before you start taking this medicine because it contains important information for you.</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">Keep this leaflet. You may need to read it again.</div>

                    </li><li>

                        <div class="avsnitt">If you have any further questions, ask your doctor or pharmacist.</div>

                    </li><li>

                        <div class="avsnitt">This medicine has been prescribed for you only. Do not pass it on to others. It may harm them, even if their signs of illness are the same as yours.</div>

                    </li><li>

                        <div class="avsnitt">If you get any side effects, talk to your doctor or pharmacist. This includes any possible side effects not listed in this leaflet. See section 4.</div>

                    </li></ul>

            </div>

        </div>

        <div class="toc generated seksjon"><span class="tittel">I dette pakningsvedlegget finner du informasjon om:</span><ol><li>What Ifirmacombi is and what it is used for</li><li>What you need to know before you take Ifirmacombi</li><li>How to take Ifirmacombi</li><li>Possible side effects</li><li>How to store Ifirmacombi</li><li>Contents of the pack and other information</li></ol></div>

    </div>

    <a class="anchorLink" id="pil-indikasjon"> </a><div class="pil-indikasjon kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">1. What Ifirmacombi is and what it is used for</tittel></span>

        <div class="avsnitt">Ifirmacombi er en kombinasjon av to virkestoffer, irbesartan og

            hydroklortiazid.<br></br>

            

            Ifirmacombi is a combination of two active substances, irbesartan and hydrochlorothiazide. Irbesartan belongs to a group of medicines known as angiotensin-II receptor antagonists. Angiotensin-II is a substance produced in the body that binds to receptors in blood vessels causing them to tighten. This results in an increase in blood pressure. Irbesartan prevents the binding of angiotensin-II to these receptors, causing the blood vessels to relax and the blood pressure to lower.<br></br>

            

            Hydrochlorothiazide is one of a group of medicines (called thiazide diuretics) that causes increased urine output and so causes a lowering of blood pressure.<br></br>

            

            The two active ingredients in Ifirmacombi work together to lower blood pressure further than if either was given alone.</div>

        <div class="avsnitt">

            <b>Ifirmacombi is used to treat high blood pressure,

            </b>

            

            (when treatment with irbesartan or hydrochlorothiazide alone did not provide adequate control of your blood pressure.</div>

    </div>

    <a class="anchorLink" id="pil-forsiktighet"> </a><div class="pil-forsiktighet kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">2. What you need to know before you take Ifirmacombi</tittel></span>

        <div class="pil-kontraindikasjon seksjon">

            <span class="tittel">Do not take Ifirmacombi</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">if you are<b>allergic</b>

                            

                            to irbesartan or any of the other ingredients of this medicine (listed in

section 6)</div>

                    </li><li>

                        <div class="avsnitt">if you are <b>allergic </b>

                            

                            to hydrochlorothiazide or any other sulfonamide-derived medicines</div>

                    </li><li>

                        <div class="avsnitt">if you are <b>more than 3 months pregnant.

                            </b>

                            

                            (It is also better to avoid Ifirmacombi in early pregnancy – see pregnancy section)</div>

                    </li><li>

                        <div class="avsnitt">if you have<b>severe liver</b>

                            

                            or                               <b>kidney problems</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">if you have<b>difficulty in producing urine</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">if your doctor determines that you have<b>persistently high calcium or low potassium levels in

your blood</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">if you have diabetes or impaired kidney function and you are treated with a blood pressure lowering medicine containing aliskiren</div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-advarsel seksjon">

            <span class="tittel">Warnings and precautionsr</span>

            <div class="avsnitt">

                <b>Talk to your doctor</b>

                

                before taking Ifirmacombi and if any of the following apply to you:<ul><li>

                        <div class="avsnitt">if you get<b>excessive vomiting or diarrhoea</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">if you suffer from<b>kidney problems</b>

                            

                            or have a    <b>kidney transplant</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">if you suffer from

                            <b>heart problems</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">if you suffer from

                            <b> liver problems</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">if you suffer from<b>diabetes

                            </b>

                        </div>

                    </li><li>

                        <div class="avsnitt">if you develop<b>low blood sugar levels</b>

                            

                            (symptoms may include sweating, weakness, hunger, dizziness, trembling, headache, flushing or paleness, numbness, having a fast, pounding heart beat), particularly if you are being treated for diabetes</div>

                    </li><li>

                        <div class="avsnitt">if you suffer from<b>lupus erythematosus </b>

                            

                            (also known as lupus or SLE)</div>

                    </li><li>

                        <div class="avsnitt">if you suffer from<b>primary aldosteronism </b>

                            

                            (a condition related to high production of the

hormone aldosterone, which causes sodium retention and, in turn, an increase in blood

pressure).</div>

                    </li><li>

                        <div class="avsnitt">if you have had skin cancer or if you develop an unexpected skin lesion during the treatment.

Treatment with hydrochlorothiazide, particularly long term use with high doses, may increase the risk of some types of skin and lip cancer (non-melanoma skin cancer). Protect your skin from sun exposure and UV rays while taking Ifirmacombi.</div>

                    </li><li>

                        <div class="avsnitt">if you experienced breathing or lung problems (including inflammation or fluid in the lungs) following hydrochlorothiazide intake in the past. If you develop any severe shortness of breath or difficulty breathing after taking Ifirmacombi, seek medical attention immediately.</div>

                    </li><li>

                        <div class="avsnitt">if you are taking any of the following medicines used to treat high blood pressure:<ul><li>

                                    <div class="avsnitt">diabetes-related kidney problems</div>

                                </li><li>

                                    <div class="avsnitt">Aliskiren</div>

                                </li></ul>

                        </div>

                    </li></ul>

            </div>

            <div class="avsnitt">Your doctor may check your kidney function, blood pressure, and the amount of electrolytes (e.g. potassium) in your blood at regular intervals.</div>

            <div class="avsnitt">See also information under the heading “Do not take Ifirmacombi”.</div>

            <div class="avsnitt">You must tell your doctor if you think you are (<u>or might become) pregnant. </u>

                

                Ifirmacombi is not recommended in early pregnancy, and must not be taken if you are more than 3 months pregnant, as it may cause serious harm to your baby if used at that stage (see pregnancy section).</div>

            <span class="flytende-tittel">

You should also tell your doctor:</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">if you are on a<b>low-salt diet</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">if you have signs such as <b>abnormal thirst, dry mouth, general weakness, drowsiness, muscle pain or cramps, nausea, vomiting,</b>

                            

                            or an<b>abnormally fast heart beat </b>

                            

                            which may indicate an excessive effect of hydrochlorothiazide (contained in Ifirmacombi)</div>

                    </li><li>

                        <div class="avsnitt">if you experience an increased <b>sensitivity of the skin to the sun</b>

                            

                            with symptoms of sunburn (such as redness, itching, swelling, blistering) occurring more quickly than normal</div>

                    </li><li>

                        <div class="avsnitt">if you are <b>going to have an operation</b>

                            

                            (surgery) or<b>be given anaesthetics</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">if you haver <b>changes in your vision or pain in one or both of your eyes</b>

                            

                            while taking Ifirmacombi. This could be symptoms of fluid accumulation in the vascular layer of the eye (choroidal effusion) or a sign that you are developing glaucoma, increased pressure in your eye(s), and can happen within hours to weeks of taking Ifirmacombi. This can lead to permanent vision loss, if not treated. If you earlier have had a penicillin or sulfonamide allergy, you can be at higher risk of developing this. You should discontinue Ifirmacombi treatment and seek medical attention.</div>

                    </li></ul>

            </div>

            <div class="avsnitt">The hydrochlorothiazide contained in this medicine could produce a positive result in an anti-doping test</div>

        </div>

        <div class="pil-barn-unge seksjon">

            <span class="tittel">Children and adolescents</span>

            <div class="avsnitt">firmacombi should not be given to children and adolescents (under 18 years).</div>

        </div>

        <div class="pil-interaksjon seksjon">

            <span class="tittel">Other medicines and Ifirmacombi</span>

            <div class="avsnitt">Tell your doctor or pharmacist if you are taking, have recently taken or might take any other medicines.</div>

            <div class="avsnitt">Diuretic agents such as the hydrochlorothiazide contained in Ifirmacombi may have an effect on other medicines. Preparations containing lithium should not be taken with Ifirmacombi without close supervision by your doctor.</div>

            <div class="avsnitt">

Your doctor may need to change your dose and/or to take other precautions:<ul><li>

                        <div class="avsnitt">If you are taking an ACE-inhibitor or aliskiren (see also information under the headings “Do not take Ifirmacombi” and “Warnings and precautions”)</div>

                    </li></ul>

            </div>

            <span class="flytende-tittel">You may need to have blood checks if you take:</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">potassium supplements</div>

                    </li><li>

                        <div class="avsnitt">salt substitutes containing potassium</div>

                    </li><li>

                        <div class="avsnitt">potassium sparing medicines or other diuretics (water tablets)</div>

                    </li><li>

                        <div class="avsnitt">some laxatives</div>

                    </li><li>

                        <div class="avsnitt">medicines for the treatment of gout</div>

                    </li><li>

                        <div class="avsnitt">therapeutic vitamin D supplements</div>

                    </li><li>

                        <div class="avsnitt">medicines to control heart rhythm</div>

                    </li><li>

                        <div class="avsnitt">medicines for diabetes (oral agents as repaglinide or insulins)</div>

                    </li><li>

                        <div class="avsnitt">carbamazepine (a medicine for the treatment of epilepsy).</div>

                    </li></ul>

            </div>

            <div class="avsnitt">It is also important to tell your doctor if you are taking other medicines to reduce your blood pressure, steroids, and medicines to treat cancer, pain killers, arthritis medicines or colestyramine and colestipol resins for lowering blood cholesterol.</div>

        </div>

        <div class="pil-mat-drikke seksjon">

            <span class="tittel">Ifirmacombi with food, drink and alcohol</span>

            <div class="avsnitt">Ifirmacombi can be taken with or without food.</div>

            <div class="avsnitt">Due to the hydrochlorothiazide contained in Ifirmacombi, if you drink alcohol while on treatment with this medicine, you may have an increased feeling of dizziness on standing up, specially when getting up from a sitting position.</div>

        </div>

        <div class="pil-graviditet-amming-fertilitet seksjon">

            <span class="tittel">Pregnancy and breast-feeding</span>

            <span class="flytende-tittel">Pregnancy</span>

            <div class="avsnitt">You must tell your doctor if you think you are (<u>or might become</u>

                <u>

                    gravid</u>

                

                ) pregnant. Your doctor will normally advise you to stop taking Ifirmacombi before you become pregnant or as soon as you know you are pregnant and will advise you to take another medicine instead of Ifirmacombi. Ifirmacombi is not recommended during pregnancy, and must not be taken when more than 3 months pregnant, as it may cause serious harm to your baby if used after the third month of pregnancy.</div>

            <div class="avsnitt">

                <b>

Breast-feeding</b>

                

                :<br></br>

                

                Tell your doctor if you are breast-feeding or about to start breast-feeding. Ifirmacombi is not recommended for mothers who are breast-feeding, and your doctor may choose another treatment for you if you wish to breast-feed, especially if your baby is newborn, or was born prematurely.</div>

        </div>

        <div class="pil-bil-maskiner seksjon">

            <span class="tittel">Driving and using machines</span>

            <div class="avsnitt">Ifirmacombi is unlikely to affect your ability to drive or use machines. However, occasionally dizziness or weariness may occur during treatment of high blood pressure. If you experience these, talk to your doctor before attempting to drive or use machines.</div>

        </div>

        <div class="pil-innholdsstoffer seksjon">

            <span class="tittel">Ifirmacombi contains sodium</span>

            <div class="avsnitt">This medicine contains less than 1 mmol sodium (23 mg) per dose, that is to say essentially "sodium- free".</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-bruk"> </a><div class="pil-bruk kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">3. How to take Ifirmacombi</tittel></span>

        <div class="avsnitt">Always take this medicine exactly as your doctor has told you. Check with your doctor or pharmacist if you are not sure.</div>

        <span class="flytende-tittel">

Dosage of Ifirmacombi 150 mg/12.5 mg</span>

        <div class="avsnitt">The recommended dose of Ifirmacombi 150 mg/12.5 mg is one tablet a day. Ifirmacombi

150 mg/12.5 mg will usually be prescribed by your doctor when your previous treatment did not reduce your blood pressure enough. Your doctor will instruct you how to switch from the previous treatment to Ifirmacombi.<br></br>

            

            If this dose does not help to reduce your blood pressure as required, the doctor may prescribe Ifirmacombi 300 mg/12.5 mg to you.</div>

        <span class="flytende-tittel">

Dosage of Ifirmacombi 300 mg/12.5 mg</span>

        <div class="avsnitt">The recomended dose of Ifirmacombi 300 mg /12.5 mg is one tablet a day.<br></br>

            

            Ifirmacombi 300 mg/12.5 mg will usually be prescribed by your doctor when your previous treatment did not reduce your blood pressure enough. Your doctor will instruct you how to switch from the previous treatment to Ifirmacombi.<br></br>

            

            If this dose does not help to reduce your blood pressure as required, the doctor may prescribe Ifirmacombi 300 mg/25 mg to you</div>

        <span class="flytende-tittel">

Dosage of Ifirmacombi 300 mg/25 mg</span>

        <div class="avsnitt">The recommended dose of Ifirmacombi 300 mg/25 mg is one tablet a day. This dose should not be increased. Ifirmacombi 300 mg/25 mg will usually be prescribed by your doctor when your previous treatment did not reduce your blood pressure enough. Your doctor will instruct you how to switch from the previous treatment to Ifirmacombi.<br></br>

            

            If this medicine does not help to reduce your blood pressure as required, the doctor will prescribe additional treatment to you.</div>

        <span class="flytende-tittel">Method of administration</span>

        <div class="avsnitt">Ifirmacombi is for<b>oral use</b>

            

            . Swallow the tablets with a sufficient amount of fluid (e.g. one glass of water). You can take Ifirmacombi with or without food. Try to take your daily dose at about the same time each day. It is important that you continue to take Ifirmacombi until your doctor tells you otherwise.</div>

        <div class="avsnitt">The maximal blood pressure lowering effect should be reached 6-8 weeks after beginning treatment.<br></br>

        </div>

        <div class="pil-overdosering seksjon">

            <span class="tittel">If you take more Ifirmacombi than you should</span>

            <div class="avsnitt">If you accidentally take too many tablets, contact your doctor immediately.<br></br>

            </div>

        </div>

        <span class="flytende-tittel">Children should not take Ifirmacombi</span>

        <div class="avsnitt">Ifirmacombi should not be given to children under 18 years of age. If a child swallows some tablets, contact your doctor immediately.<br></br>

        </div>

        <div class="pil-glemt-dose seksjon" id="pil-glemt-dose">

            <span class="tittel">

If you forget to take Ifirmacombi</span>

            <div class="avsnitt">If you accidentally miss a daily dose, just take the next dose as normal. Do not take a double dose to make up for a forgotten dose.</div>

            <div class="avsnitt">If you have any further questions on the use of this medicine, ask your doctor or pharmacist.</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-bivirkning"> </a><div class="pil-bivirkning kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">4. Possible side effects</tittel></span>

        <div class="avsnitt">Like all medicines, this medicine can cause side effects, although not everybody gets them.</div>

        <div class="avsnitt">Some of these effects may be serious and may require medical attention.</div>

        <div class="avsnitt">Rare cases of allergic skin reactions (rash, urticaria), as well as localised swelling of the face, lips and/or tongue have been reported in patients taking irbesartan.<br></br>

            <b>If you get any of the above symptoms or get short of breath,</b>

            

            stop taking Ifirmacombi and contact your doctor immediately.</div>

        <div class="avsnitt">The frequency of the side effects listed below is defined using the following convention:<br></br>

            

            Common: may affect up to 1 in 10 people<br></br>

            

            Uncommon: may affect up to 1 in 100 people<br></br>

            

            Very rare: may affect up to 1 in 10,000 people</div>

        <div class="avsnitt">Side effects reported in clinical studies for patients treated with Ifirmacombi were:</div>

        <div class="avsnitt">

            <b>Common side effects</b>

            

            (may affect up to 1 in 10 people):<ul><li>

                    <div class="avsnitt">nausea/vomiting</div>

                </li><li>

                    <div class="avsnitt">abnormal urination</div>

                </li><li>

                    <div class="avsnitt">fatigue</div>

                </li><li>

                    <div class="avsnitt">dizziness (including when getting up from a lying or sitting position)</div>

                </li><li>

                    <div class="avsnitt">blood tests may show raised levels of an enzyme that measures the muscle and heart function

(creatine kinase) or raised levels of substances that measure kidney function (blood urea nitrogen, creatinine).</div>

                </li></ul>

            

            If any of these side effects causes you problems,<b>talk to your doctor.</b>

        </div>

        <div class="avsnitt">

            <b>Uncommon side effects</b>

            

            (may affect up to 1 in 100 people):<ul><li>

                    <div class="avsnitt">diarrhoea</div>

                </li><li>

                    <div class="avsnitt">low blood pressure</div>

                </li><li>

                    <div class="avsnitt">fainting</div>

                </li><li>

                    <div class="avsnitt">heart rate increased</div>

                </li><li>

                    <div class="avsnitt">flushing</div>

                </li><li>

                    <div class="avsnitt">swelling</div>

                </li><li>

                    <div class="avsnitt">sexual dysfunction (problems with sexual performance)</div>

                </li><li>

                    <div class="avsnitt">blood tests may show lowered levels of potassium and sodium in your blood.</div>

                </li></ul>

            

            If any of these side effects causes you problems,<b>talk to your doctor.</b>

        </div>

        <div class="avsnitt">

            <b>Side effects reported since the launch of the combination of Irbesartan and Hydrochlorothiazide </b>

            

            Some undesirable effects have been reported since marketing of Ifirmacombi. Undesirable effects where the frequency is not known are:<ul><li>

                    <div class="avsnitt">headache</div>

                </li><li>

                    <div class="avsnitt">ringing in the ears,</div>

                </li><li>

                    <div class="avsnitt">cough</div>

                </li><li>

                    <div class="avsnitt">taste disturbance</div>

                </li><li>

                    <div class="avsnitt">indigestion</div>

                </li><li>

                    <div class="avsnitt">pain in joints and muscles</div>

                </li><li>

                    <div class="avsnitt">liver function abnormal and impaired kidney function,</div>

                </li><li>

                    <div class="avsnitt">increased level of potassium in your blood and</div>

                </li><li>

                    <div class="avsnitt">allergic reactions such as rash, hives, swelling of the face, lips, mouth, tongue or throat.</div>

                </li><li>

                    <div class="avsnitt">uncommon cases of jaundice (yellowing of the skin and/or whites of the eyes) have also been reported.</div>

                </li></ul>

        </div>

        <div class="avsnitt">As for any combination of two active substances, side effects associated with each individual component cannot be excluded.</div>

        <span class="flytende-tittel">Side effects associated with irbesartan alone</span>

        <div class="avsnitt">In addition to the side effects listed above, chest pain, severe allergic reactions (anaphylactic shock), decreased number of red blood cells (anaemia – symptoms may include tiredness, headaches, being short of breath when exercising, dizziness and looking pale) and decrease in the number of platelets (a blood cell essential for the clotting of the blood) and low blood sugar levels have also been reported.</div>

        <span class="flytende-tittel">Side effects associated with hydrochlorothiazide alone</span>

        <div class="avsnitt">Skin and lip cancer (non-melanoma skin cancer); loss of appetite; stomach irritation; stomach cramps; constipation; jaundice (yellowing of the skin and/or whites of the eyes); inflammation of the pancreas characterised by severe upper stomach pain, often with nausea and vomiting; sleep disorders; depression; blurred vision; decrease in vision or pain in your eyes due to high pressure (possible signs of fluid accumulation in the vascular layer of the eye (choroidal effusion) or acute angle-closure glaucoma); lack of white blood cells, which can result in frequent infections, fever; decrease in the number of platelets (a blood cell essential for the clotting of the blood), decreased number of red blood cells (anaemia) characterised by tiredness, headaches, being short of breath when exercising, dizziness and looking pale; kidney disease; lung problems including pneumonia or build-up of fluid in the lungs; increased sensitivity of the skin to the sun; inflammation of blood vessels; a skin disease characterized by the peeling of the skin all over the body; cutaneous lupus erythematosus, which is identified by a rash that may appear on the face, neck, and scalp; allergic reactions; weakness and muscle spasm; altered heart rate; reduced blood pressure after a change in body position; swelling of the salivary glands; high sugar levels in the blood; sugar in the urine; increases in some kinds of blood fat; high uric acid levels in the blood, which may cause gout.<br></br>

            <b>Very rare side effects</b>

            

             (may affect up to 1 in 10,000 people):<br></br>

            

            Acute respiratory distress (signs include severe shortness of breath, fever, weakness, and confusion).</div>

        <div class="avsnitt">It is known that side effects associated with hydrochlorothiazide may increase with higher doses of hydrochlorothiazide.</div>

        <div class="avsnitt">

            

        </div>

        <div class="pil-melding seksjon">

            <span class="tittel">Reporting of side effects</span>

            <div class="avsnitt">If you get any side effects, talk to your doctor or pharmacist. This includes any possible side effects not listed in this leaflet. You can also report side effects directly via the national reporting system listed in Appendix V. <a href="https://www.mhra.gov.uk/yellowcard" target="_blank" title="www.mhra.gov.uk/yellowcard">www.mhra.gov.uk/yellowcard</a>

                By reporting side effects you can help provide more information on the safety of this medicine.</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-oppbevaring"> </a><div class="pil-oppbevaring kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">5. How to store Ifirmacombi</tittel></span>

        <div class="avsnitt">Keep this medicine out of the sight and reach of children.</div>

        <div class="avsnitt">Do not use this medicine after the expiry date which is stated on the packaging after EXP. The expiry date refers to the last day of that month.</div>

        <div class="avsnitt">This medicine does not require any special storage conditions.</div>

        <div class="avsnitt">Do not throw away any medicines via wastewater or household waste. Ask your pharmacist how to throw away medicines you no longer use. These measures will help protect the environment.</div>

    </div>

    <a class="anchorLink" id="pil-innhold-info"> </a><div class="pil-innhold-info kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">6. Contents of the pack and other information</tittel></span>

        <div class="pil-sammensetning seksjon">

            <span class="tittel">What Ifirmacombi contains</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">The active substances are irbesartan and hydrochlorothiazide.</div>

                    </li></ul>

            </div>

            <div class="avsnitt">Each Ifirmacombi 150 mg/12.5 mg film-coated tablet contains 150 mg irbesartan (as irbesartan hydrochloride) and 12.5 mg hydrochlorothiazide.<br></br>

                Each Ifirmacombi 300 mg/12.5 mg film-coated tablet contains 300 mg irbesartan (as irbesartan hydrochloride) and 12.5 mg hydrochlorothiazide.<br></br>

                Each Ifirmacombi 300 mg/25 mg film-coated tablet contains 300 mg irbesartan (as irbesartan hydrochloride) and 25 mg hydrochlorothiazide.</div>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">The other ingredients are:</div>

                    </li></ul>

                Ifirmacombi 150 mg/12.5 mg:<br></br>

                mannitol, hydroxypropylcellulose, low-substituted hydroxypropylcellulose, sodium starch glycolate, talc, 

macrogol 6000, hydrogenated castor oil in the tablet core and polyvinyl alcohol, 

titanium dioxide (E171), macrogol, talc, yellow iron oxide (E172) and red iron oxide (E172) in

the film-coating. See section 2 "Ifirmacombi contains sodium".</div>

            <div class="avsnitt">Ifirmacombi 300 mg/12.5 mg:<br></br>

                mannitol, hydroxypropylcellulose, low-substituted hydroxypropylcellulose, sodium starch glycolate, talc, macrogol 6000, hydrogenated castor oil in the tablet core and polyvinyl alcohol, titanium dioxide (E171), macrogol, talc in the film-coating. See section 2 "Ifirmacombi contains sodium".</div>

            <div class="avsnitt">

Ifirmacombi 300 mg/25 mg:<br></br>

                mannitol, hydroxypropylcellulose, low-substituted hydroxypropylcellulose, sodium starch glycolate, talc, macrogol 6000, hydrogenated castor oil in the tablet core and polyvinyl alcohol, titanium dioxide (E171), macrogol, talc, yellow iron oxide (E172) and red iron oxide (E172) in the film-coating. See section 2 "Ifirmacombi contains sodium".</div>

        </div>

        <div class="pil-pakningsinnhold seksjon">

            <span class="tittel">What Ifirmacombi looks like and contents of the pack</span>

            <div class="avsnitt">Ifirmacombi 150 mg/12.5 mg<br></br>

                Pale pink, biconvex, oval, film-coated tablets (tablets).</div>

            <div class="avsnitt">Ifirmacombi 300 mg/12.5 mg<br></br>

                White, biconvex, capsule shaped, film-coated tablets (tablets).</div>

            <div class="avsnitt">Ifirmacombi 300 mg/25 mg<br></br>

                Pale pink, biconvex, capsule shaped, film-coated tablets (tablets).</div>

            <div class="avsnitt">Boxes of 14, 28, 30, 56, 56 x 1, 84, 90 and 98 film-coated tablets in blisters are available.<br></br>

                Not all pack sizes may be marketed.</div>

        </div>

        <div class="pil-markedsføring-tilvirker seksjon">

            <span class="tittel">Marketing Authorisation Holder</span>

            <div class="avsnitt">KRKA, d.d., Novo mesto, Šmarješka cesta 6, 8501 Novo mesto,

                Slovenia</div>

            <span class="flytende-tittel">Manufacturer</span>

            <div class="avsnitt">KRKA, d.d., Novo mesto, Šmarješka cesta 6, 8501 Novo mesto,

                Slovenia<br></br>

                

                TAD Pharma GmbH, Heinz-Lohmann-Straße 5, 27472

                Cuxhaven,Tyskland</div>

            <div class="avsnitt">

For any information about this medicine, please contact the local representative of the Marketing Authorisation Holder:</div>

            <div class="pil-lokal-representant seksjon">

                <div class="avsnitt">KRKA Sverige AB<br></br>

                    

                    Tlf: + 46 (0)8 643 67 66 (SE)</div>

            </div>

        </div>

        <div class="pil-sist-endret seksjon tittel">This leaflet was last revised in 08.02.2022</div>

        <div class="pil-andre-kilder seksjon">

            <div class="avsnitt">Detailed information on this medicine is available on the European Medicines Agency web site:

                    <a href="http://www.ema.europa.eu/" target="_blank" title="&#xA;                    &#xA;                        http://www.ema.europa.eu&#xA;                    &#xA;                ">

                    

                        http://www.ema.europa.eu

                    

                </a>

            </div>

        </div>

    </div>
</div><div id="begrep"></div></body></html>

`;

const ifirmacombi_fr = `
<?xml version="1.0" encoding="UTF-8"?>

<!DOCTYPE html

  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "xhtml1-transitional.dtd">

<html><head><title>Ifirmacombi KRKA - Felleskatalogen Pasientutgave</title><meta name="description" content="&#xA;         1. Qu’est-ce que Ifirmacombi et dans quels cas est-il utilisé &#xA;         Ifirmacombi er en kombinasjon av to virkestoffer, irbesartan og&#xA;       "></meta><meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta><meta name="author" content="Felleskatalogen"></meta><meta name="keywords" content="Ifirmacombi tab 150 mg/12,5 mg Ifirmacombi tab 300 mg/12,5 mg Ifirmacombi tab 300 mg/25 mg"></meta><meta http-equiv="X-UA-Compatible" content="IE=edge"></meta><meta name="date" content="La dernière date à laquelle cette notice a été révisée est 08.02.2022"></meta><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_content.css" type="text/css"></link><link rel="stylesheet" href="INTERNAL:/css/felleskatalogen_fk.css" type="text/css"></link><meta name="varenr" content="381211,101684,029502"></meta><meta name="ean" content="7046263812113,7046261016841,7046260295025"></meta><meta name="lmrlopenr" content="2014602,2014601,2014600"></meta>

        

        

        

        

        

        

        

        

        

        <meta name="preparatnavn" content="Ifirmacombi"></meta>

        <meta name="kortnummer" content="1111"></meta>

    <meta name="navnformstyrke" content="Ifirmacombi tab 300 mg/25 mg,Ifirmacombi tab 300 mg/12,5 mg,Ifirmacombi tab 150 mg/12,5 mg"></meta></head><body>
    
    
    
    
    <!-- HENRIK HAR KOMMENTERT UT DETTE    
    
    <div class="anchorindexbar">


    <a class="anchorindex" href="#pil-indikasjon" title="Bruksområder">Bruksområder</a> | 

    <a class="anchorindex" href="#pil-forsiktighet" title="Forsiktighetsregler">Forsiktighetsregler</a> | 

    <a class="anchorindex" href="#pil-bruk" title="Bruksmåte">Bruksmåte</a> | 

    <a class="anchorindex" href="#pil-bivirkning" title="Bivirkninger">Bivirkninger</a> | 

    <a class="anchorindex" href="#pil-oppbevaring" title="Oppbevaring">Oppbevaring</a> | 

    <a class="anchorindex" href="#pil-innhold-info" title="Ytterligere informasjon">Ytterligere informasjon</a>

<br></br><br></br></div>

-->

<div class="pil">

    

    

    <h1 class="tittel">Notice : Information du patient</h1>

    

    

    

    <h2 class="pil-navnformstyrke">Ifirmacombi 150 mg/12,5 mg comprimés pelliculés</h2>

    <h2 class="pil-navnformstyrke">Ifirmacombi 300 mg/12,5 mg comprimés pelliculés</h2>

    <h2 class="pil-navnformstyrke">Ifirmacombi 300 mg/25 mg comprimés pelliculés</h2>

    <h3 class="pil-substans">irbésartan/hydrochlorothiazide</h3>

    <div class="pil-innledning">

        <div class="pil-regler seksjon">

            <span class="tittel">Veuillez lire attentivement cette notice avant de prendre ce médicament car elle contient des informations importantes pour vous.</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">Gardez cette notice. Vous pourriez avoir besoin de la relire.</div>

                    </li><li>

                        <div class="avsnitt">Si vous avez d’autres questions, interrogez votre médecin ou votre pharmacien.</div>

                    </li><li>

                        <div class="avsnitt">Ce médicament vous a été personnellement prescrit. Ne le donnez pas à d’autres personnes. Il pourrait leur être nocif, même si les signes de leur maladie sont identiques aux vôtres.</div>

                    </li><li>

                        <div class="avsnitt">Si vous ressentez un quelconque effet indésirable, parlez-en à votre médecin ou votre pharmacien. Ceci s’applique aussi à tout effet indésirable qui ne serait pas mentionné dans cette notice. Voir rubrique 4.</div>

                    </li></ul>

            </div>

        </div>

        <div class="toc generated seksjon"><span class="tittel">I dette pakningsvedlegget finner du informasjon om:</span><ol><li>Qu’est-ce que Ifirmacombi et dans quels cas est-il utilisé</li><li>Quelles sont les informations à connaître avant de prendre Ifirmacombi</li><li>Comment prendre Ifirmacombi</li><li>Quels sont les effets indésirables éventuels</li><li>Comment conserver Ifirmacombi</li><li>Contenu de l’emballage et autres informations</li></ol></div>

    </div>

    <a class="anchorLink" id="pil-indikasjon"> </a><div class="pil-indikasjon kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">1. Qu’est-ce que Ifirmacombi et dans quels cas est-il utilisé</tittel></span>

        <div class="avsnitt">Ifirmacombi er en kombinasjon av to virkestoffer, irbesartan og

            hydroklortiazid.<br></br>

            Ifirmacombi est une association de deux substances actives, l'irbésartan et l'hydrochlorothiazide. L'irbésartan appartient à un groupe de médicaments connus sous le nom d'antagonistes des récepteurs de l'angiotensine-II. L'angiotensine-II est une substance formée par l'organisme qui se lie aux récepteurs des vaisseaux sanguins, ce qui entraîne leur constriction. Il en résulte une élévation de la pression artérielle. L'irbésartan empêche la liaison de l'angiotensine-II à ces récepteurs et provoque ainsi un effet relaxant au niveau des vaisseaux sanguins et une baisse de la pression artérielle.<br></br>

            L'hydrochlorothiazide fait partie d’un groupe de médicaments (appelés diurétiques thiazidiques) qui favorisent l'élimination d'urine, diminuant de cette manière la pression artérielle. <br></br>

             Les deux principes actifs d’Ifirmacombi permettent ensemble de diminuer la pression artérielle de manière plus importante que s'ils avaient été pris seuls.</div>

        <div class="avsnitt">

            <b>

Ifirmacombi est utilisé pour traiter l'hypertension artérielle,

            </b>

            lorsqu'un traitement par l'irbésartan seul ou l'hydrochlorothiazide seul ne contrôle pas de façon adéquate votre pression artérielle.

</div>

    </div>

    <a class="anchorLink" id="pil-forsiktighet"> </a><div class="pil-forsiktighet kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">2. Quelles sont les informations à connaître avant de prendre Ifirmacombi</tittel></span>

        <div class="pil-kontraindikasjon seksjon">

            <span class="tittel">Ne prenez jamais Ifirmacombi</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">si vous êtes<b>allergique</b>

                            à l'irbésartan, ou à l'un des autres composants contenus dans ce

médicament (mentionnés dans la rubrique 6)</div>

                    </li><li>

                        <div class="avsnitt">si vous êtes<b>allergique </b>

                            à l’hydrochlorothiazide ou à tout autre médicament dérivé des

sulfonamides</div>

                    </li><li>

                        <div class="avsnitt">si vous êtes <b>enceinte de plus de 3 mois

                            </b>

                            (il est également préférable d’éviter de prendre

Ifirmacombi en début de grossesse – voir la rubrique « Grossesse »)</div>

                    </li><li>

                        <div class="avsnitt">si vous avez des<b>problèmes hépatiques </b>

                            ou 

                                <b>rénaux graves</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">si vous avez des<b>difficultés pour uriner</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">si votre médecin constate<b>la persistance de taux élevés de calcium ou de taux faibles de

potassium dans votre sang</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">si vous avez du diabète ou une insuffisance rénale et que vous êtes traité(e) par un médicament

contenant de l’aliskiren pour diminuer votre pression artérielle.</div>

                    </li></ul>

            </div>

        </div>

        <div class="pil-advarsel seksjon">

            <span class="tittel">Avertissements et précautions</span>

            <div class="avsnitt">

                <b>Adressez-vous à votre médecin</b>

                avant de prendre Ifirmacombi et si une des situations suivantes se présente:<ul><li>

                        <div class="avsnitt">si vous souffrez de <b>vomissements ou de diarrhées importantes</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">si vous souffrez de <b>problèmes rénaux,</b>

                            ou si vous avez

                                <b>une greffe de rein</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">si vous souffrez de 

                            <b>problèmes cardiaques</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">si vous souffrez de <b>problèmes hépatiques</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">si vous souffrez d'un<b>diabète</b>

                            </div>

                    </li><li>

                        <div class="avsnitt">si vous développez une<b>hypoglycémie (faible taux de sucre dans le sang)</b>

                            (les symptômes peuvent inclure transpiration, faiblesse, sensation de faim, vertiges, tremblements, maux de tête, rougeur ou pâleur, engourdissement, battements du cœur rapides et forts), en particulier si vous êtes traité(e) pour le diabète</div>

                    </li><li>

                        <div class="avsnitt">si vous souffrez d'un<b>lupus érythémateux </b>

                            (connu aussi sous le nom de lupus ou LED)</div>

                    </li><li>

                        <div class="avsnitt">si vous souffrez d’<b>d’hyperaldostéronisme primaire</b>

                            (une condition liée à une forte production de

l’hormone aldostérone, qui provoque une rétention du sodium et par conséquence une

augmentation de la pression artérielle)</div>

                    </li><li>

                        <div class="avsnitt">si vous avez eu un cancer de la peau ou si vous développez une lésion cutanée inattendue 

pendant le traitement. Le traitement par l'hydrochlorothiazide, en particulier l'utilisation à long terme à fortes doses, peut augmenter le risque de certains types de cancer de la peau et des lèvres (cancer de la peau non mélanome). Protégez votre peau des rayonnements solaires et UV lorsque vous utilisez Ifirmacombi.</div>

                    </li><li>

                        <div class="avsnitt">si vous avez eu des problèmes respiratoires ou pulmonaires (notamment une inflammation ou un liquide dans les poumons) à la suite d’une prise d’hydrochlorothiazide dans le passé. Si vous développez un essoufflement sévère ou des difficultés à respirer après avoir pris Ifirmacombi, consultez immédiatement un médecin.</div>

                    </li><li>

                        <div class="avsnitt">si vous prenez l’un des médicaments suivants pour traiter une hypertension:<ul><li>

                                    <div class="avsnitt">un inhibiteur de l’enzyme de Conversion (IEC) (par exemple énalapril, lisinopril, ramipril), en particulier si vous avez des problèmes rénaux dus à un diabète</div>

                                </li><li>

                                    <div class="avsnitt">aliskiren.</div>

                                </li></ul>

                        </div>

                    </li></ul>

            </div>

            <div class="avsnitt">Votre médecin pourra être amené à surveiller régulièrement le fonctionnement de vos reins, votre pression artérielle et le taux des électrolytes (par exemple du potassium) dans votre sang.</div>

            <div class="avsnitt">Voir aussi les informations dans la rubrique « Ne prenez jamais Ifirmacombi ».</div>

            <div class="avsnitt">Vous devez informer votre médecin si vous pensez être 

                    (<u>(ou susceptible de devenir) enceinte</u>

                Ifirmacombi est déconseillé en début de grossesse, et ne doit pas être pris si vous êtes à plus de 3 mois de grossesse, car il peut entraîner de graves problèmes de santé chez l’enfant à naître s’il est utilisé au cours de cette période (voir la rubrique « Grossesse »).</div>

            <span class="flytende-tittel">Vous devrez également prévenir votre médecin</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">si vous suivez un<b>régime hyposodé (peu riche en sel)</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">si vous avez des signes, tels que<b>soif anormale, bouche sèche, faiblesse générale, somnolence,

douleurs musculaires ou crampes, nausées, vomissements </b>

                            ou<b>battements du cœur anormalement rapides</b>

                            qui pourraient indiquer un effet excessif de l'hydrochlorothiazide (contenu dans Ifirmacombi)</div>

                    </li><li>

                        <div class="avsnitt">si vous ressentez une<b>sensibilité accrue de votre peau au soleil</b>

                            avec apparition de coup de soleil plus rapidement que la normale (symptômes tels que rougeur, démangeaison, gonflement, cloque)</div>

                    </li><li>

                        <div class="avsnitt">si vous devez<b>subir une opération

                            </b>

                            (intervention chirurgicale) ou<b>une anesthésie</b>

                        </div>

                    </li><li>

                        <div class="avsnitt">si vous constatez une<b>altération de votre vision ou une douleur dans un œil ou les deux yeux</b>

                            lors du traitement par Ifirmacombi. Ces dernières pourraient être des symptômes d’une accumulation de fluide dans la couche vasculaire de l’oeil (épanchement choroïdien) ou un signe que vous développez un glaucome, c'est à dire une pression accrue dans votre œil ou vos&#x2028;yeux, et pourraient se produire dans un délai de quelques heures à quelques semaines après la prise de Ifirmacombi. Cela peut conduire à une perte de vision permanente, si elle n'est pas traitée. Si vous avez déjà eu une allergie à la pénicilline ou aux sulfamides, vous pouvez &#x2028;être plus à risque de développer ces symptômes. Vous devez arrêter votre traitement par Ifirmacombi et consulter votre médecin.</div>

                    </li></ul>

            </div>

            <div class="avsnitt">L’hydrochlorothiazide contenu dans ce médicament peut induire une réaction positive des tests pratiqués lors du contrôle antidopage.</div>

        </div>

        <div class="pil-barn-unge seksjon">

            <span class="tittel">Enfants et adolescents</span>

            <div class="avsnitt">Ifirmacombi ne doit pas être donné aux enfants et aux adolescents (de moins de 18 ans).</div>

        </div>

        <div class="pil-interaksjon seksjon">

            <span class="tittel">Autres médicaments et Ifirmacombi</span>

            <div class="avsnitt">Informez votre médecin ou pharmacien si vous prenez, avez récemment pris ou pourriez prendre tout autre médicament.</div>

            <div class="avsnitt">Les médicaments diurétiques tels que l'hydrochlorothiazide contenu dans Ifirmacombi peuvent avoir un effet sur d'autres médicaments. Les médicaments contenant du lithium ne doivent pas être pris avec Ifirmacombi sans la surveillance de votre médecin.</div>

            <div class="avsnitt">Votre médecin pourrait avoir besoin de modifier la dose de vos médicaments et/ou prendre d’autres précautions:<ul><li>

                        <div class="avsnitt">Si vous prenez un inhibiteur de l’enzyme de conversion ou de l’aliskiren (voir aussi les

informations dans les rubriques « Ne prenez jamais Ifirmacombi » et « Faites attention avec Ifirmacombi »).</div>

                    </li></ul>

            </div>

            <span class="flytende-tittel">Vous pouvez être amené(e) à effectuer des contrôles sanguins si vous prenez :</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">une supplémentation en potassium</div>

                    </li><li>

                        <div class="avsnitt">des sels de régime à base de potassium</div>

                    </li><li>

                        <div class="avsnitt">des médicaments d'épargne potassique, d’autres diurétiques</div>

                    </li><li>

                        <div class="avsnitt">certains laxatifs</div>

                    </li><li>

                        <div class="avsnitt">des médicaments pour le traitement de la crise de goutte</div>

                    </li><li>

                        <div class="avsnitt">de la vitamine D en supplément thérapeutique</div>

                    </li><li>

                        <div class="avsnitt">des médicaments pour contrôler votre rythme cardiaque</div>

                    </li><li>

                        <div class="avsnitt">des médicaments pour traiter le diabète (médicaments oraux tels que le répaglinide ou insuline)</div>

                    </li><li>

                        <div class="avsnitt">de la carbamazépine (un médicament pour le traitement de l'épilepsie)</div>

                    </li></ul>

            </div>

            <div class="avsnitt">Il est également important de dire à votre médecin si vous prenez d’autres antihypertenseurs, des stéroïdes, des anticancéreux, des médicaments contre la douleur, des médicaments antiarthritiques ou des résines de colestyramine et de colestipol pour réduire le cholestérol dans le sang.</div>

        </div>

        <div class="pil-mat-drikke seksjon">

            <span class="tittel">Ifirmacombi avec des aliments et boissons</span>

            <div class="avsnitt">Ifirmacombi peut être pris au cours ou en dehors des repas.</div>

            <div class="avsnitt">En raison de la présence d’hydrochlorothiazide dans Ifirmacombi, si vous buvez de l’alcool alors que vous êtes sous traitement avec ce médicament, vous pouvez ressentir une sensation accrue de vertige lorsque vous vous levez, en particulier quand vous vous levez d’une position assise.</div>

        </div>

        <div class="pil-graviditet-amming-fertilitet seksjon">

            <span class="tittel">Grossesse, allaitement et fertilité</span>

            <span class="flytende-tittel">Grossesse</span>

            <div class="avsnitt">Vous devez informer votre médecin si vous êtes<u>(ou susceptible de devenir)</u>

                <u>enceinte</u>

                Votre médecin vous recommandera normalement d’arrêter de prendre Ifirmacombi avant que vous ne soyez enceinte ou dès que vous apprenez que vous êtes enceinte et vous conseillera de prendre un autre médicament à la place d’Ifirmacombi. Ifirmacombi est déconseillé au cours de la grossesse et ne doit pas être utilisé si vous êtes enceinte de plus de 3 mois car il peut entraîner de graves problèmes de santé chez l'enfant à naître s’il est pris à partir du troisième mois de la grossesse.</div>

            <div class="avsnitt">

                <b>Allaitement</b>

                :<br></br>

                Informez votre médecin si vous allaitez ou êtes sur le point d’allaiter. Ifirmacombi est déconseillé chez les femmes qui allaitent, votre médecin choisira un autre traitement si vous souhaitez allaiter, en particulier si votre enfant est un nouveau-né ou un prématuré.</div>

        </div>

        <div class="pil-bil-maskiner seksjon">

            <span class="tittel">

Conduite de véhicules et utilisation de machines</span>

            <div class="avsnitt">Il est peu probable qu’Ifirmacombi affecte votre capacité à conduire des véhicules ou à utiliser des machines. Cependant, des vertiges et de la fatigue peuvent survenir occasionnellement lors du traitement de l'hypertension artérielle. Si tel est votre cas, vous devez le signaler à votre médecin.</div>

        </div>

        <div class="pil-innholdsstoffer seksjon">

            <span class="tittel">Ifirmacombi contient du sodium</span>

            <div class="avsnitt">Ce médicament contient moins de 1 mmol (23 mg) de sodium par dose, c.-à-d. qu’il est essentiellement « sans sodium ».</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-bruk"> </a><div class="pil-bruk kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">3. Comment prendre Ifirmacombi</tittel></span>

        <div class="avsnitt">Veillez à toujours prendre ce médicament en suivant exactement les indications de votre médecin ou pharmacien. Vérifiez auprès de votre médecin ou pharmacien en cas de doute.</div>

        <span class="flytende-tittel">Posologie pour Ifirmacombi 150 mg/12,5 mg</span>

        <div class="avsnitt">La posologie recommandée d’Ifirmacombi 300 mg/12,5 mg est de un comprimé par jour. Ifirmacombi 300 mg/12,5 mg sera habituellement prescrit par votre médecin si votre précédent traitement n'a pas permis une réduction suffisante de votre pression artérielle. Votre médecin vous indiquera comment passer de votre précédent traitement à Ifirmacombi. <br></br>

            Si cette posologie ne permet pas de réduire votre pression artérielle de manière suffisante, votre médecin sera en mesure de vous prescrire Ifirmacombi 300 mg/25 mg.</div>

        <span class="flytende-tittel">Posologie pour Ifirmacombi 300 mg/12,5 mg</span>

        <div class="avsnitt">La posologie recommandée d’Ifirmacombi 300 mg/12,5 mg est de un comprimé par jour.<br></br>

            Ifirmacombi 300 mg/12,5 mg sera habituellement prescrit par votre médecin si votre précédent traitement n'a pas permis une réduction suffisante de votre pression artérielle. Votre médecin vous indiquera comment passer de votre précédent traitement à Ifirmacombi. <br></br>

            Si ce traitement ne permet pas de réduire votre pression artérielle de manière suffisante, votre médecin vous prescrira un traitement complémentaire.</div>

        <span class="flytende-tittel">Posologie pour Ifirmacombi 300 mg/25 mg</span>

        <div class="avsnitt">La posologie recommandée d’Ifirmacombi 300 mg/25 mg est de un comprimé par jour. Cette posologie ne doit pas être dépassée. Ifirmacombi 300 mg/25 mg sera habituellement prescrit par votre médecin si votre précédent traitement n'a pas permis une réduction suffisante de votre pression artérielle. Votre médecin vous indiquera comment passer de votre précédent traitement à Ifirmacombi.<br></br>

            Si ce traitement ne permet pas de réduire votre pression artérielle de manière suffisante, votre médecin vous prescrira un traitement complémentaire.</div>

        <span class="flytende-tittel">

Mode d'administration</span>

        <div class="avsnitt">Ifirmacombi se prend par<b>voie orale</b>

            . Les comprimés doivent être avalés avec une quantité suffisante de liquide (par exemple, un verre d'eau). Vous pouvez prendre Ifirmacombi au cours ou en dehors des repas. Vous devez essayer de prendre votre dose quotidienne approximativement à la même heure chaque jour. Il est important que vous continuiez à prendre Ifirmacombi sauf si votre médecin vous demande le contraire.et.</div>

        <div class="avsnitt">L'effet maximal de la baisse de pression artérielle est obtenu en 6 à 8 semaines après le début du traitement.<br></br>

        </div>

        <div class="pil-overdosering seksjon">

            <span class="tittel">Si vous avez pris plus d’Ifirmacombi que vous n’auriez dû</span>

            <div class="avsnitt">Si vous prenez accidentellement un trop grand nombre de comprimés, prévenez immédiatement votre médecin.<br></br>

            </div>

        </div>

        <span class="flytende-tittel">Les enfants ne doivent pas prendre Ifirmacombi:</span>

        <div class="avsnitt">Ifirmacombi ne doit pas être administré aux enfants de moins de 18 ans. Si un enfant avale des comprimés, prévenez immédiatement votre médecin.<br></br>

        </div>

        <div class="pil-glemt-dose seksjon" id="pil-glemt-dose">

            <span class="tittel">

Si vous oubliez de prendre Ifirmacombi</span>

            <div class="avsnitt">Si par inadvertance vous oubliez un jour de prendre votre médicament, prenez la dose suivante comme d'habitude. Ne prenez pas de dose double pour compenser la dose que vous avez oublié de prendre.</div>

            <div class="avsnitt">Si vous avez d'autres questions sur l'utilisation de ce médicament, demandez plus d'informations à votre médecin ou à votre pharmacien.</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-bivirkning"> </a><div class="pil-bivirkning kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">4. Quels sont les effets indésirables éventuels</tittel></span>

        <div class="avsnitt">Comme tous les médicaments, ce médicament peut provoquer des effets indésirables, mais ils ne surviennent pas systématiquement chez tout le monde.</div>

        <div class="avsnitt">Certains effets peuvent être sérieux et nécessiter une surveillance médicale.</div>

        <div class="avsnitt">De rares cas d’allergie cutanée (éruption, urticaire), ainsi que des gonflements localisés de la face, des lèvres et/ou de la langue ont été rapportés chez des patients prenant de l’irbésartan.<br></br>

            <b>Si vous développez l’un de ces effets ou si vous êtes essoufflé(e), </b>

            arrêtez de prendre Ifirmacombi et prévenez immédiatement votre médecin.</div>

        <div class="avsnitt">La fréquence des effets indésirables listés ci-dessous est définie selon la convention suivante :<br></br>

            Fréquent : peu affecter jusqu’à 1 personne sur 10<br></br>

            Peu fréquent : peu affecter jusqu’à 1 personne sur 100<br></br>

            Très rare : peu affecter jusqu’à 1 personne sur 10 000</div>

        <div class="avsnitt">Les effets indésirables rapportés lors des études cliniques chez les patients traités avec Ifirmacombi ont été:</div>

        <div class="avsnitt">

            <b>Effets indésirables fréquents</b>

            (peut affecter jusqu’à 1 personne sur 10)<ul><li>

                    <div class="avsnitt">nausées/vomissements</div>

                </li><li>

                    <div class="avsnitt">besoin anormal d'uriner</div>

                </li><li>

                    <div class="avsnitt">fatigue</div>

                </li><li>

                    <div class="avsnitt">vertiges (y compris en se levant d'une position couchée ou assise)</div>

                </li><li>

                    <div class="avsnitt">tests sanguins pouvant montrer une augmentation des taux de l’enzyme qui traduit l’état de la

fonction musculaire et cardiaque (créatine kinase) ou une augmentation du taux de substances

qui traduisent de l’activité de la fonction du rein (taux d’azote uréique, créatinine).</div>

                </li></ul>

            

<b>Si vous êtes gêné(e) par l'un de ces effets indésirables, parlez-en à votre médecin.</b>

        </div>

        <div class="avsnitt">

            <b>Effets indésirables peu fréquents </b>

            (peut affecter jusqu’à 1 personne sur 100)<ul><li>

                    <div class="avsnitt">diarrhée</div>

                </li><li>

                    <div class="avsnitt">pression artérielle basse</div>

                </li><li>

                    <div class="avsnitt">faiblesse</div>

                </li><li>

                    <div class="avsnitt">accélération des battements cardiaques</div>

                </li><li>

                    <div class="avsnitt">bouffées de chaleur</div>

                </li><li>

                    <div class="avsnitt">œdème</div>

                </li><li>

                    <div class="avsnitt">dysfonctionnement sexuel (problèmes de performance sexuelle)</div>

                </li><li>

                    <div class="avsnitt">tests sanguins pouvant révéler une diminution des taux de potassium et de sodium dans votre

sang.</div>

                </li></ul>

            <b>Si vous êtes gêné(e) par l'un de ces effets indésirables, parlez-en à votre médecin.

</b>

        </div>

        <div class="avsnitt">

            <b>Effets indésirables rapportés depuis la commercialisation de l’association de l’Irbésartan et de l’Hydrochlorothiazide</b>

            Des effets indésirables ont été rapportés depuis la commercialisation d’Ifirmacombi. Les effets

indésirables dont la fréquence n'est pas connue sont:<ul><li>

                    <div class="avsnitt">maux de tête,</div>

                </li><li>

                    <div class="avsnitt">bourdonnements d'oreilles, </div>

                </li><li>

                    <div class="avsnitt">toux,</div>

                </li><li>

                    <div class="avsnitt">altération du goût,</div>

                </li><li>

                    <div class="avsnitt">indigestion,</div>

                </li><li>

                    <div class="avsnitt">douleurs articulaires et musculaires,</div>

                </li><li>

                    <div class="avsnitt">anomalie de la fonction hépatique et altération de la fonction rénale,</div>

                </li><li>

                    <div class="avsnitt">augmentation du taux de potassium dans votre sang et</div>

                </li><li>

                    <div class="avsnitt">réactions allergiques cutanées telles qu’éruption, urticaire, gonflement de la face, des lèvres, de

la bouche, de la langue ou de la gorge.</div>

                </li><li>

                    <div class="avsnitt">des cas peu fréquents de jaunisse (caractérisée par un jaunissement de la peau et/ou du blanc des

yeux) ont été rapportés.</div>

                </li></ul>

        </div>

        <div class="avsnitt">Comme avec toute association de deux principes actifs, les effets indésirables associés à chacun d’eux ne peuvent être exclus.

</div>

        <span class="flytende-tittel">Effets indésirables associés à l’irbésartan seul</span>

        <div class="avsnitt">En plus des effets indésirables listés ci-dessus, des douleurs à la poitrine, des réactions allergiques sévères (choc anaphylactique), diminution du nombre de globules rouges (anémie - les symptômes peuvent inclure une fatigue, des maux de têtes, un essoufflement pendant l’effort, des vertiges, une pâleur), une diminution du nombre de plaquettes (un composant sanguin essentiel pour permettre la coagulation du sang) et un faible taux de sucre dans le sang (hypoglycémie) ont également été rapportées.</div>

        <span class="flytende-tittel">

Effets indésirables associés à l’hydrochlorothiazide seul</span>

        <div class="avsnitt">Cancer de la peau et des lèvres (cancer de la peau non mélanome); perte d’appétit; aigreur et crampes d’estomac; constipation; jaunisse (caractérisée par un jaunissement de la peau et/ou du blanc des yeux); inflammation du pancréas caractérisée par une douleur importante haute de l’estomac souvent associée à des nausées ou des vomissements; troubles du sommeil; dépression; vision trouble; diminution de la vision ou douleur dans les yeux due à une pression élevée (signes possibles d’une accumulation de fluide dans la couche vasculaire de l’oeil (épanchement choroïdien) ou d’un glaucome aigu à angle fermé); déficit en globules blancs, qui peut résulter en des infections fréquentes, à de la fièvre; diminution du nombre de plaquettes (un composant sanguin essentiel pour permettre la coagulation du sang); diminution du nombre de globules rouges (anémie) caractérisée par une fatigue, des maux de têtes, un essoufflement pendant l’effort, des vertiges, une pâleur; maladie des reins; problèmes aux poumons incluant la pneumonie ou une accumulation de liquide dans les poumons; augmentation de la sensibilité de la peau au soleil; inflammation des vaisseaux sanguins; maladie de la peau caractérisée par une desquamation de la peau sur tout le corps; lupus érythémateux cutané, caractérisé par une éruption pouvant apparaitre sur la face, le cou et le cuir chevelu; réactions allergiques; faiblesse et spasticité des muscles; altération du pouls; diminution de la pression artérielle après changement de position du corps; gonflement des glandes salivaires; taux élevé de sucre dans le sang; présence de sucre dans les urines; augmentation de certains lipides sanguins, taux élevé d’acide urique sanguin qui peut provoquer de la goutte.<br></br>

            <b>Effets indésirables très rares </b>

            (peu affecter jusqu’à 1 personne sur 10 000) :<br></br>

            Détresse respiratoire aiguë (les signes comprennent un essoufflement sévère, de la fièvre, une faiblesse et une confusion).</div>

        <div class="avsnitt">Il est connu que les effets indésirables, liés à l'hydrochlorothiazide, peuvent augmenter avec des doses plus élevées d’hydrochlorothiazide.</div>

        <div class="avsnitt">

            </div>

        <div class="pil-melding seksjon">

            <span class="tittel">Déclaration des effets secondaires</span>

            <div class="avsnitt">Si vous ressentez un quelconque effet indésirable, parlez-en à votre médecin ou votre pharmacien. Ceci s’applique aussi à tout effet indésirable qui ne serait pas mentionné dans cette notice. Vous pouvez également déclarer les effets indésirables directement via le système national de déclaration

40 décrit en Annexe V.<a href="https://www.legemiddelverket.no/pasientmelding" target="_blank" title="www.legemiddelverket.no/pasientmelding">www.legemiddelverket.no/pasientmelding</a>

                En signalant les effets indésirables, vous contribuez à fournir davantage d’informations sur la sécurité du médicament.</div>

        </div>

    </div>

    <a class="anchorLink" id="pil-oppbevaring"> </a><div class="pil-oppbevaring kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">5. Comment conserver Ifirmacombi</tittel></span>

        <div class="avsnitt">Tenir ce médicament hors de la vue et de la portée des enfants.</div>

        <div class="avsnitt">N’utilisez pas ce médicament après la date de péremption indiquée sur la boîte après EXP.La date de péremption fait référence au dernier jour de ce mois.</div>

        <div class="avsnitt">Ce médicament ne nécessite pas de précautions particulières de conservation.</div>

        <div class="avsnitt">Ne jetez aucun médicament au tout-à-l’égout ou avec les ordures ménagères. Demandez à votre pharmacien d’éliminer les médicaments que vous n’utilisez plus. Ces mesures contribueront à protéger l’environnement.</div>

    </div>

    <a class="anchorLink" id="pil-innhold-info"> </a><div class="pil-innhold-info kapittel">

        <span class="kapittel-tittel"><tittel innrykk="nei">6. Contenu de l’emballage et autres informations</tittel></span>

        <div class="pil-sammensetning seksjon">

            <span class="tittel">

Ce que contient Ifirmacombi</span>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">Les substances actives sont l'irbésartan et l'hydrochlorothiazide.</div>

                    </li></ul>

            </div>

            <div class="avsnitt">Chaque comprimé pelliculé d’Ifirmacombi 150 mg/12,5 mg contient 150 mg d'irbésartan (sous

forme d’irbésartan hydrochloride) et 12,5 mg d'hydrochlorothiazide.<br></br>

                Chaque comprimé pelliculé d’Ifirmacombi 300 mg/12,5 mg contient 300 mg d'irbésartan (sous forme d’irbésartan hydrochloride) et 12,5 mg d'hydrochlorothiazide.<br></br>

                Chaque comprimé pelliculé d’Ifirmacombi 300 mg/25 mg contient 300 mg d'irbésartan (sous forme d’irbésartan hydrochloride) et 25 mg d'hydrochlorothiazide.</div>

            <div class="avsnitt">

                <ul><li>

                        <div class="avsnitt">Les autres composants sont:</div>

                    </li></ul>

                Ifirmacombi 150 mg/12,5 mg:<br></br>

                mannitol, hydroxypropylcellulose, hydroxypropylcellulose à faible de taux de substitution,

carboxyméthylamidon sodique, talc, macrogol 6000, huile de ricin hydrogénée dans le noyau du comprimé et alcool polyvinylique, dioxyde de titane (E171), macrogol et talc dans le film pelliculé. Voir rubrique 2 « Ifirmacombi contient du sodium ».</div>

            <div class="avsnitt">Ifirmacombi 300 mg/12,5 mg:<br></br>

                mannitol, hydroxypropylcellulose, hydroxypropylcellulose à faible de taux de substitution,

carboxyméthylamidon sodique, talc, macrogol 6000, huile de ricin hydrogénée dans le noyau du comprimé et alcool polyvinylique, dioxyde de titane (E171), macrogol et talc dans le film pelliculé. Voir rubrique 2 « Ifirmacombi contient du sodium ».</div>

            <div class="avsnitt">Ifirmacombi 300 mg/25 mg:<br></br>

                mannitol, hydroxypropylcellulose, hydroxypropylcellulose à faible de taux de substitution,

carboxyméthylamidon sodique, talc, macrogol 6000, huile de ricin hydrogénée dans le noyau du comprimé et alcool polyvinylique, dioxyde de titane (E171), macrogol, talc, oxyde de fer jaune (E172) et oxyde de fer rouge (E172) dans le film pelliculé. Voir rubrique 2 « Ifirmacombi contient du sodium ».</div>

        </div>

        <div class="pil-pakningsinnhold seksjon">

            <span class="tittel">Comment se présente Ifirmacombi et contenu de l'emballage extérieur</span>

            <div class="avsnitt">Ifirmacombi 150 mg/12,5 mg<br></br>

                Comprimés pelliculés rose pâle, biconvexes, de forme ovale (comprimés).</div>

            <div class="avsnitt">Ifirmacombi 300 mg/12,5 mg<br></br>

                Comprimés pelliculés blanc, biconvexes, en forme de gélule (comprimés).</div>

            <div class="avsnitt">Ifirmacombi 300 mg/25 mg<br></br>

                Comprimés pelliculés rose pâle, biconvexes, en forme de gélule (comprimés).</div>

            <div class="avsnitt">Ifirmacombi comprimés pelliculés sont fournis en conditionnements de 14, 28, 30, 56, 56 x 1, 84, 90 et 98 comprimés.<br></br>

                Toutes les présentations peuvent ne pas être commercialisées.</div>

        </div>

        <div class="pil-markedsføring-tilvirker seksjon">

            <span class="tittel">Titulaire de l’Autorisation de mise sur le marché</span>

            <div class="avsnitt">KRKA, d.d., Novo mesto, Šmarješka cesta 6, 8501 Novo mesto,

                Slovenia</div>

            <span class="flytende-tittel">Fabricant</span>

            <div class="avsnitt">KRKA, d.d., Novo mesto, Šmarješka cesta 6, 8501 Novo mesto,

                Slovenia<br></br>

                TAD Pharma GmbH, Heinz-Lohmann-Straße 5, 27472

                Cuxhaven,Tyskland</div>

            <div class="avsnitt">

Pour toute information complémentaire concernant ce médicament, veuillez prendre contact avec le représentant local du titulaire de l’autorisation de mise sur le marché:</div>

            <div class="pil-lokal-representant seksjon">

                <div class="avsnitt">KRKA Sverige AB<br></br>

                    Tlf: + 46 (0)8 643 67 66 (SE)</div>

            </div>

        </div>

        <div class="pil-sist-endret seksjon tittel">La dernière date à laquelle cette notice a été révisée est 08.02.2022</div>

        <div class="pil-andre-kilder seksjon">

            <div class="avsnitt">

Des informations détaillées sur ce médicament sont disponibles sur le site internet de l’Agence européenne du médicament

                    <a href="http://www.ema.europa.eu/" target="_blank" title="&#xA;                    &#xA;                        http://www.ema.europa.eu&#xA;                    &#xA;                ">

                    

                        http://www.ema.europa.eu

                    

                </a>

            </div>

        </div>

    </div>

</div><div id="begrep"></div></body></html>
`;

export const packageLeafletTranslationData = [
  {
    name: 'Skilarence',
    varenrs: ['500781', '454978', '112869'],
    en: skilarence_en,
    fr: skilarence_fr,
  },
  {
    name: 'Forxiga',
    varenrs: ['126390', '381510', '502904', '462070'],
    en: forxiga_en,
    fr: forxiga_fr,
  },
  {
    name: 'Ifirmacombi',
    varenrs: ['381211', '101684', '029502'],
    en: ifirmacombi_en,
    fr: ifirmacombi_fr,
  },
];

export function getPackageLeafletTranslationData(varenr: string, lng: Language): string | undefined {
  const obj = packageLeafletTranslationData.find((item) => item.varenrs.includes(varenr));
  if (obj) {
    return (obj as any)[lng];
  }
  return undefined;
}
