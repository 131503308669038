import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { warnMissingTranslations } from './i18n/useT';
// import * as serviceWorker from './serviceWorker';

warnMissingTranslations();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://d6a3fbf7f0d44bba868a9c6ad5a57a51@o403707.ingest.sentry.io/5266650' });
}

ReactDOM.render(<App />, document.getElementById('root'));

// disable service worker for now
// serviceWorker.unregister();
