import { getSelectedLanguage } from './../../i18n/languageStorage';

const medicationInfo = [
  {
    name: 'Skilarence',
    varenrs: ['500781', '454978', '112869'],
    en: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/d60656bd-a359-4c96-82bd-ca1398aacbb3/en/910d1748-674b-430c-b4ab-3665b266a31f',
    no: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/d60656bd-a359-4c96-82bd-ca1398aacbb3/no/995d3971-b899-43bf-857d-98c26634796d',
    // use english as fallback for fr
    fr: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/d60656bd-a359-4c96-82bd-ca1398aacbb3/en/910d1748-674b-430c-b4ab-3665b266a31f',
  },
  {
    name: 'Gonal-F',
    varenrs: ['010102', '389637', '141438', '122857', '391010'],
    en: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/433f228c-0a23-402a-848f-eaeb277dbb7b/en/323b4525-f94b-496b-b29b-cbf96712c086',
    no: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/433f228c-0a23-402a-848f-eaeb277dbb7b/no/9c59056b-e493-4781-8f32-0d06dd078fdc',
    // use english as fallback for fr
    fr: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/433f228c-0a23-402a-848f-eaeb277dbb7b/en/323b4525-f94b-496b-b29b-cbf96712c086',
  },
  {
    name: 'Elocta',
    varenrs: ['093840', '379419', '422545', '186026', '166943', '178511', '382657', '175246'],
    en: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/46daba09-71ea-4ed1-928e-e88eeab69810/en/c641aa85-da8a-4e22-804d-1feddb5e7f3d',
    no: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/46daba09-71ea-4ed1-928e-e88eeab69810/no/4422ba50-933d-473e-b75e-11dcfe5bf1f2',
    // use english as fallback for fr
    fr: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/46daba09-71ea-4ed1-928e-e88eeab69810/en/c641aa85-da8a-4e22-804d-1feddb5e7f3d',
  },
  {
    name: 'Cystagon',
    varenrs: ['419838', '419937'],
    en: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/5793a889-b407-4a69-9c0b-ac35d1d199eb/en/4d5b2247-318c-4aba-bc41-a25bf37f8842',
    no: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/5793a889-b407-4a69-9c0b-ac35d1d199eb/no/6763b333-ee96-4e5c-9296-ea9587d2f76a',
    // use english as fallback for fr
    fr: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/5793a889-b407-4a69-9c0b-ac35d1d199eb/en/4d5b2247-318c-4aba-bc41-a25bf37f8842',
  },
  {
    name: 'Ferriprox',
    varenrs: ['123020', '003687', '453071'],
    en: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/432cee48-f712-44e6-ba4a-9d4a508a573e/en/89c6ddf5-364a-4c2b-91fa-8775114e28f8',
    no: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/432cee48-f712-44e6-ba4a-9d4a508a573e/no/7a353426-6a91-44b6-a891-a919dab18e4c',
    // use english as fallback for fr
    fr: 'https://ema-dap-epi-tst-fhir-web.azurewebsites.net/View/432cee48-f712-44e6-ba4a-9d4a508a573e/en/89c6ddf5-364a-4c2b-91fa-8775114e28f8',
  },
];

export const getMedicationInfoLink = (varenr: string) => {
  const item = medicationInfo.find((item) => item.varenrs.includes(varenr));

  if (item) {
    return item[getSelectedLanguage()];
  } else {
    return `https://www.felleskatalogen.no/medisin/sok?type=pkvtekst&sokord=${varenr}`;
  }
};
